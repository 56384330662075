import { useContext, createContext, useState, useEffect } from 'react'
import { useFetch } from '../../hooks'
import { backend_url } from '../../settings'
import SnackbarUtils from '../../utils/SnackbarUtils'

const UserPrefsContext = createContext(null)

export const ProvideUserPrefs = ({children}) => {

    const initialState = JSON.parse(localStorage.getItem("user_prefs"))
    const currentUser = JSON.parse(localStorage.getItem('current_user'))
    const tokenBpxRequest = localStorage.getItem('token_bpx')
    const request = useFetch()

    const [prefsData, setPrefsData] = useState(initialState||{theme:'light'})
    

    const saveLandingPagePref = (data) => {
        setPrefsData(prevState => (
            {
                ...prevState,
                landing_page: {...data}
            }
        ))
        localStorage.setItem("user_prefs", JSON.stringify({
            ...prefsData,
            landing_page: {...data}
        }))
    }

    const saveUserPrefs = (data) => {
        localStorage.setItem("user_prefs", JSON.stringify(data))
        localStorage.setItem("current_language", data.lang)
        setPrefsData(data)
    }

    const saveUserPrefsWithoutRefresh = (data) => {
        localStorage.setItem("user_prefs", JSON.stringify(data))
        localStorage.setItem("current_language", data.lang)
    }

    const updateUserPrefsOnClose = () => {
        let data = {...prefsData}
        data['user_id'] = currentUser.username
        request.fetchData(
            `${backend_url.get_user_prefs}/${currentUser.username}`, 
            'PUT', 
            JSON.stringify(data), 
            false,
            tokenBpxRequest,
            false
        )
        .then(response => {
            if (response) {        
                SnackbarUtils.success(JSON.stringify(response.message))
            }    
            if (!response) {
                SnackbarUtils.error("Error. Preferenes not updated.")
            }
        })
    }

    const silentUpdateUserPrefs = () => {
        let data = JSON.parse(localStorage.getItem("user_prefs"))
        if (data) {
            setPrefsData(data)
            data['user_id'] = currentUser.username
            request.fetchData(
                `${backend_url.get_user_prefs}/${currentUser.username}`, 
                'PUT', 
                JSON.stringify(data), 
                false,
                tokenBpxRequest,
                false
            )
            .then(response => {
            })
        }
    }

    return (
        <UserPrefsContext.Provider value={{prefsData, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs, saveUserPrefs, saveLandingPagePref, updateUserPrefsOnClose}}>
            {children}
        </UserPrefsContext.Provider>
    )
}


export const useUserPrefs = () => useContext(UserPrefsContext)
