import { useNavigate } from "react-router-dom"
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from './okta_config';


export const OktaWrapper = ({children}) => {

    const oktaAuth = new OktaAuth(config.oidc);
    const navigate = useNavigate();

    const restoreOriginalUri = (_oktaAuth,  originalUri) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    };


    return (
        <Security
        oktaAuth={oktaAuth}
        restoreOriginalUri={restoreOriginalUri}
        >
            {children}
        </Security>
    )
}