import {
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridPrintExportMenuItem
} from '@mui/x-data-grid-pro'
import ExcelExport from './exports/ExcelExport'


const CustomExport = ({pdfExport, fileName, showFiltersInPDF=false, ...props}) => {

  const styleFilters = showFiltersInPDF ?
    '.toolbar-hide-pdf { display: none }' :
    '.MuiDataGrid-toolbar { display: none }'

  return(
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem 
        sx={{ paddingLeft: "0.5rem", fontSize: "0.8rem" }}
        options={{
          utf8WithBom: true,
          fileName: fileName ? fileName : ""
        }}
      />
      
      {pdfExport && 
        <GridPrintExportMenuItem
          sx={{ paddingLeft: "0.5rem", fontSize: "0.8rem"}}
          options={{
            hideFooter: true,
            pageStyle: `
              @media print {
                @page {
                  size: landscape;
                }
                ${styleFilters}
                .MuiDataGrid-root {
                  color: rgba(0, 0, 0, 0.87) !important;
                }
  
                .toolbarTitle, 
                .subtotal-accordion .MuiAccordionSummary-root,
                .subtotal-filter-section .subtotal-filter-label,
                .subtotal-filter-section .subtotal-filter-field .MuiAutocomplete-tag .MuiChip-label,
                .subtotal-filter-section .subtotal-filter-field .MuiInputBase-root .MuiInputBase-input {
                  color: rgba(0, 0, 0, 0.87) !important;
                  
                }
                .subtotal-filter-section .subtotal-filter-field .MuiAutocomplete-tag {
                  background-color: rgba(0, 0, 0, 0.08);
                }

                .MuiDataGrid-cell, .MuiDataGrid-columnHeader {
                  width: 100px !important;
                  min-width: 100px  !important;
                  max-width: 100px  !important;
                  color: rgba(0, 0, 0, 0.87) !important;
                }
                .MuiDataGrid-root .MuiDataGrid-cellContent {
                  word-break: break-word;
                  white-space: break-spaces;
                  overflow-wrap: break-word;
                  text-align: left;
                }
                .MuiDataGrid-main{
                  font-size: 14px;
                }
              }`,
            fileName: 'Audit List'
          }}
        />
      }
      {props.setting && <ExcelExport {...props} />}
    </GridToolbarExportContainer>
  )
}

export default CustomExport
