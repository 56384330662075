import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate} from 'react-router-dom'
import { Toolbar, Grid, CssBaseline } from '@mui/material'
import BpxLogo from '../../assets/images/bpx-transparent.png'
import MarsLogo from '../../assets/images/MarsLogo.png'
import CardMedia from '@mui/material/CardMedia';

import { MenuGroup, HelpComponent  } from '../index'
import { useAuth } from '../../pages'

import MenuSandwich from './MenuSandwich'
import { usePermissions, useFetch } from '../../hooks'
import { backend_url } from '../../settings'
import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs"



function Header(props) {

  const { t } = useTranslation()
  const { permission } = usePermissions()
  const currentUser = JSON.parse(localStorage.getItem('current_user'))
  const BU = localStorage.getItem('bu_bpx')
  const b_units = localStorage.getItem('b_units')?JSON.parse(localStorage.getItem('b_units')):[]
  const [logo, setLogo] = useState('')
  
  let auth = useAuth()
  let navigate = useNavigate()
  const request = useFetch()
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const { prefsData } = useUserPrefs()
  const location = window.location.hostname;
  let environmentName = ''
  const ToolbarStyle = { 
    mt: 0, 
    pt: 0,
    minHeight: '50px', 
    background: 'rgb(241,241,241)', 
    '@media all': { minHeight: 30 },
    position: 'relative',
  }
  
  const environmentNameStyle = {
    position: 'absolute', 
    top: -2, 
    left: 42,
    textTransform: 'uppercase',
    fontSize: '0.875rem'
  }

  if(location.includes('test')){
    environmentName = 'test environment';
    ToolbarStyle.borderTop = '1px solid rgb(246, 158, 128)';
    ToolbarStyle.borderBottom = '2px solid rgb(246, 158, 128)';
    environmentNameStyle.color = 'rgb(246, 158, 128)';
    ToolbarStyle['@media (max-width: 1020px)'] = {
      paddingLeft: '200px'
    }
  }

  if(location.includes('dev')){
    environmentName = 'dev. environment';
    ToolbarStyle.borderTop = '1px solid #7cbeff';
    ToolbarStyle.borderBottom = '2px solid #7cbeff';
    environmentNameStyle.color = '#1976d2';
    ToolbarStyle['@media (max-width: 1000px)'] = {
      paddingLeft: '190px'
    }
  }

  if(location.includes('sandbox')){
    environmentName = 'sandbox environment';
    ToolbarStyle.borderTop = '1px solid rgb(32 158 85)';
    ToolbarStyle.borderBottom = '2px solid rgb(32 158 85)';
    environmentNameStyle.color = 'rgb(32 158 85)';
    ToolbarStyle['@media (max-width: 1065px)'] = {
      paddingLeft: '230px'
    }
  }

  if(location.includes('bdd')){
    environmentName = 'bdd environment';
    ToolbarStyle.borderTop = '1px solid rgb(126 36 164)';
    ToolbarStyle.borderBottom = '2px solid rgb(126 36 164)';
    environmentNameStyle.color = 'rgb(126 36 164)';
    ToolbarStyle['@media (max-width: 1065px)'] = {
      paddingLeft: '180px'
    }
  }

  useEffect(()=>{
    request.fetchData(
      `${backend_url.config_business_units_logo}/${BU}?MODE=${prefsData.theme}`, 
      'GET', 
      null,
      false,
      tokenBpxRequest,
      false,
      false,
      true
    ).then(data=>{
      if(data&&data.type&&data.type.includes('image')){
        const urlCreator = window.URL || window.webkitURL;
        setLogo(urlCreator.createObjectURL(data))
      }
    })
  },[prefsData.theme])

  if (!currentUser) {
    return auth.signout(() => navigate("/login"))
  }

  const businessUnitDesc = b_units.find(o => o.BUSINESS_UNIT === BU)||{DESCRIPTION:''};



  return (
    <>
    
      <CssBaseline />

      <div className='bpx-header-container'>

        <div className='bpx-header-top'>
            
            <div className='bpx-header-left'>
                <div className='bpx-header-left-accrual'>
                  <p>{t("Copyright.accrual")}</p>
                  <p>{t("Copyright.engine")}</p>
                </div>
                <div className='bpx-header-left-userdata'>
                  <p>{businessUnitDesc.DESCRIPTION}</p>
                  <p>{currentUser.name} {currentUser.surname}</p>
                </div>
            </div>

            <div className='bpx-header-center'>
              <a href="/">
                <span className='bpx-logo-leftside'>
                </span>
                {
                  logo.length?
                  (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <CardMedia
                        sx={{
                          maxHeight: "35px",
                          width: 'auto',
                          marginLeft: '28px',
                        }}
                        component="img"
                        image={logo} />
                    </div>
                  )
                  :null
                }
              </a>
            </div>

            <div className='bpx-header-right'>
              <div className='bpx-header-right-icon'>
                <HelpComponent />
              </div>
                <div className='bpx-header-right-icon'>
                  <MenuSandwich openUserPrefs={props.openUserPrefs}/>
                </div>
            </div>

        </div>

        <Toolbar disableGutters sx={ToolbarStyle}>
        <p style={environmentNameStyle}>{environmentName}</p>
          <Grid container justifyContent="center" >
            <MenuGroup menuText={permission()} />
          </Grid>
        </Toolbar>

      </div>

    </>
  )
  
}

export default Header;
