import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material'
import { useFetch } from '../../hooks'
import { backend_url } from '../../settings'
import { getParam } from "../../utils/utils"
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import {MaterialsDetail} from '../../pages'
import Box from '@mui/material/Box';


export default function ModalFindProduct({ open, onSubmit, onClose }) {
  const { t } = useTranslation('translation');
  const [materialList, setMaterialList] = useState([]);
  const [businessUnit, setBusinessUnit] = useState([])
  const [loading, setLoading] = useState({ materials: false, bu: false })
  const [page, setPage] = useState(0)
  const [data, setData] = useState({ material: '', bu: '', deleted: false })
  const [content, setContent] = useState('default')
  const [width, setWidth] = useState('500px')

  const tokenBpxRequest = localStorage.getItem('token_bpx');
  const bu = localStorage.getItem('bu_bpx')
  const request = useFetch();

  const fetchMaterialList = (value) => {
    setLoading(pr => ({ ...pr, materials: true }))
    let param = {
      //BUSINESS_UNIT: bu,
      limit: 100,
      skip: page,
      MATERIAL_NR: value
    };
    const queryString = getParam(param).toString();

    request.fetchData(
      backend_url.mat_list + '?' + queryString,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    ).then(data => {
      setMaterialList(data.map(it => it.MATERIAL_NR))
      setLoading(pr => ({ ...pr, materials: false }))
    })
  }


  const fetchBU = (value) => {
    setLoading(pr => ({ ...pr, bu: true }))
     request.fetchData(
      `${backend_url.mat_detail}/${value}/detail`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    ).then(data => {
      if(data){
        setBusinessUnit(data.material_bus)
      }
      
      setLoading(pr => ({ ...pr, bu: false }))
    })
    .catch(()=>{
      setLoading(pr => ({ ...pr, bu: false }))
      setBusinessUnit([])
    })
  }


  const handleChange = (name, value) => {
    if (name === 'material') {
      if (value) {
        setData(prev => ({ ...prev, material: value, bu: '' }))
        setBusinessUnit([])
        fetchBU(value)
      } else {
        setData(prev => ({ ...prev, material: '', bu: '' }))
        setBusinessUnit([])
      }
    } else {
      setData(prev => ({ ...prev, [name]: value }))
    }
  }
  const onHandleSubmit = (e) => {
    e.preventDefault();

    setContent('material')
  }

  const handleClicknotAuth = ()=>{
    setWidth('500px');
    setData({bu: '', material: '', deleted: false});
    setBusinessUnit([]);
    setMaterialList([])
    setContent('default');
  }

  const handleClickClose = ()=>{
    onClose();
    handleClicknotAuth()
  }

  const handlePopup = (status)=>{
    if(status===200){
      setWidth('90%')
    }
    if(status===401){
      setWidth('500px')
      setContent('noAccess')
    }else if(status===404){
      setWidth('500px')
      setContent('noData')
    }
  }

  const displayBlockForm = () => {
    switch (content) {
      case 'default':
        return (
          <>
            <DialogTitle sx={{ paddingBottom: 0}}>
            {t("Buttons.Find_Product")}
              <hr />
              <div className="close-for-modal-window">
                <IconButton onClick={handleClickClose} className="wrap-cancel-modal">
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <form onSubmit={onHandleSubmit}>
              <DialogContent className='find-data' sx={{paddingTop: 0}}>
              <Box className='bpx-modal-add-data-box'>
                <div className='bpx-modal-add-data'>
                  <FormControl size="small">
                    <Autocomplete
                      value={data.material}
                      disablePortal
                      id="combo-box-demo"
                      className='bpx-userid-select'
                      loading={loading.materials}
                      loadingText='Loading…'
                      options={materialList}
                      renderInput={(params) => {
                        const isRequired = data.material.length === 0;
                        return (
                          <TextField
                            {...params}
                            label="Material Number"
                            className='bpx-modal-add-data-autocomplete'
                            size="small"
                            inputProps={{
                              ...params.inputProps,
                              required: isRequired
                            }}
                            required={true}
                          />
                        )
                      }}
                      getOptionLabel={(option) => option}
                      onInputChange={(event, newValue, condition) => {
                        setPage(0);
                        if (newValue.length < 3) {
                          setMaterialList([])
                        } else {
                          fetchMaterialList(newValue)
                        }
                      }}
                      onChange={(event, value) => {
                        handleChange('material', value)
                      }}
                    />
                  </FormControl>
                </div>
                <div className='bpx-modal-add-data'>
                  <FormControl size="small">
                    <InputLabel id="demo-simple-select-label">Business Unit</InputLabel>
                    <Select
                      label={'Business Unit'}
                      labelId="demo-simple-select-label"
                      value={data.bu}
                      required={true}
                      onChange={(e) => handleChange('bu', e.target.value)}
                      className='bpx-modal-add-data-field'
                      disabled={!businessUnit.length}
                    >
                      {
                        businessUnit.map((item, i) => {
                          if (typeof item === 'object') {
                            return <MenuItem key={i} value={item.value}>{item.option}</MenuItem>
                          }
                          return <MenuItem key={i} value={item}>{item}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className='bpx-modal-add-data'>
                  <FormControlLabel
                    control={<Checkbox
                      checked={data.deleted}
                      onChange={(e) => handleChange('deleted', e.target.checked)}
                    />}
                    label={'Include products in status "Deleted in AE"'} />
                </div>
                </Box>
              </DialogContent>

              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!data.material.length || !data.bu.length}
                >
                  Display
                </Button>
              </DialogActions>
            </form>
          </>
        )
      
      case 'noAccess':
        return (
          <>
            <DialogTitle sx={{ paddingBottom: 0 }}>
            {t("Buttons.Find_Product")}
              <hr />
              <div className="close-for-modal-window">
                <IconButton onClick={handleClickClose} className="wrap-cancel-modal">
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <p>You are not autorizated to display data</p>
              <p>Material Number: {data.material}</p>
              <p>Business Unit: {data.bu}</p>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleClicknotAuth}
              >
                Ok
              </Button>
            </DialogActions>
          </>
        )
        case 'material':
          return(<>
          <DialogTitle sx={{ paddingBottom: 0 }}> 
          Material: {data.material}
          <hr />
              <div className="close-for-modal-window">
                <IconButton onClick={handleClickClose} className="wrap-cancel-modal">
                  <CloseIcon />
                </IconButton>
              </div>
          </DialogTitle>
          <DialogContent><MaterialsDetail id={data.material} materialPopup={true} isHideDeleted={!data.deleted} prevBusinessUnit={data.bu} handlePopup={handlePopup}/></DialogContent>
          <DialogActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleClickClose}
              >
                Ok
              </Button>
            </DialogActions>
          </>
          )
        case 'noData':
          return (
            <>
              <DialogTitle sx={{ paddingBottom: 0 }}>
              {t("Buttons.Find_Product")}
                <hr />
                <div className="close-for-modal-window">
                  <IconButton onClick={handleClickClose} className="wrap-cancel-modal">
                    <CloseIcon />
                  </IconButton>
                </div>
              </DialogTitle>
              <DialogContent>
                <p>No data for Material {data.material}</p>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  onClick={handleClicknotAuth}
                >
                  Ok
                </Button>
              </DialogActions>
            </>
          )
    }
  }

  // const displayLoading = (
  //   loading
  //     ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
  //     : displayBlockForm()
  // )

  return (
    <Dialog
      className='bpx-modal-find-product'
      open={open}
      onClose={handleClickClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { width: width, minWidth: width } }}
    >
      {displayBlockForm()}
    </Dialog>
  )
}