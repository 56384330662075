import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl';
import { Box } from '@mui/system';
import {  numberFormatter } from "../../../utils/utils"
import { useUserPrefs } from "../../UserPreferences/ProvideUserPrefs"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


export default function ModalSummary({ open, onClose, data, filters, onFilterSummary }) {

  const [rowsANM, setRowsANM] = useState([])
  const [rowsSummary, setRowsSummary] = useState([])
  const { t } = useTranslation(['translation', 'mars_dictionaries'])
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  const [loading, setLoading] = useState(false)
  const [columnVisibility, setColumnVisibility] = useState({ 'activity_nature_mechanic': {}, 'summary': {} });

  const [showPeriods, setShowPeriods] = useState({ 'activity_nature_mechanic': false, 'summary': false });
  const [lastPeriods, setLastPeriods] = useState({ 'activity_nature_mechanic': 0, 'summary': 0 });

  const periods = ['P1', 'P2', 'P3', 'P4', 'P5', 'P6', 'P7', 'P8', 'P9', 'P10', 'P11', 'P12', 'P13']

  const showPeriodsTemplate = {
    field: 'showPeriods',
    headerName: '+',
    headerAlign: 'center',
    minWidth: 30,
    maxWidth: 30,
    sortable: false,
    hideable: false,
    align: 'center',
    cellClassName: (params)=> params.row.type, 
    headerClassName: 'bpx-trade-header-show-periods',
    disableColumnMenu: true,
    renderHeader: (params) => {
      return params.colDef.headerName === '+' ? <AddCircleOutlineIcon sx={{ fontSize: '1rem' }} /> : <RemoveCircleOutlineIcon sx={{ fontSize: '1rem' }} />
    }
  };

  useEffect(() => {
    let prevColumnVisibility = { 'activity_nature_mechanic': {}, 'summary': {} }

    prevColumnVisibility['activity_nature_mechanic']['showPeriods'] = false
    prevColumnVisibility['activity_nature_mechanic']['id'] = false
    periods.forEach(it => {
      prevColumnVisibility['activity_nature_mechanic'][it] = false
    })
    prevColumnVisibility['summary']['showPeriods'] = false
    prevColumnVisibility['summary']['id'] = false
    periods.forEach(it => {
      prevColumnVisibility['summary'][it] = false
    })

    setColumnVisibility(prevColumnVisibility)

  }, [])

  const filtersSummary = [
    {
      field: 'EXP_SUBTOTALS_BY',
      headerName: t("TradeExpeditiureMonitor.SUMMARY.EXP_SUBTOTALS_BY"),
      valueOptions: [
        {
          value: 'ExternalCategory',
          name: t("TradeExpeditiureMonitor.SUMMARY.ExternalCategory"),
        },
        {
          value: 'ExternalClass',
          name: t("TradeExpeditiureMonitor.SUMMARY.ExternalClass"),
        },
        {
          value: 'ExternalSubclass',
          name: t("TradeExpeditiureMonitor.SUMMARY.ExternalSubclass"),
        },
        {
          value: 'ExternalSpendType',
          name: t("TradeExpeditiureMonitor.SUMMARY.ExternalSpendType"),
        }
      ]
    },
    {
      field: 'EXP_SUMMARY_FOR',
      headerName: t("TradeExpeditiureMonitor.SUMMARY.EXP_SUMMARY_FOR"),
      valueOptions: [
        {
          value: 'Inv. Nature and Mechanic',
          name: t("TradeExpeditiureMonitor.SUMMARY.InvNatureAndMechanic"),
        }
      ]
    },
  ]


  const [columnsSummary, setColumnsSummary] = useState([

    {
      field: 'name',
      headerName: t("TradeExpeditiureMonitor.SUMMARY.Activity") + t(`TradeExpeditiureMonitor.SUMMARY.${filters.EXP_SUBTOTALS_BY}`), //t("Config.ACCRUAL_OBJECT_TYPE_DETERMINATION.AO_TYPE"),
      flex: 0.25,
      minWidth: 100,
      sortable: false,
      hideable: false,
      cellClassName: (params)=> params.row.type, 
      // renderHeader: (params)=>{
      //   console.log(filters)
      //   return (
      //     <div className="MuiDataGrid-columnHeaderTitle" 
      //       aria-label="SUMMARY" 
      //       data-mui-internal-clone-element="true">
      //       {t("TradeExpeditiureMonitor.SUMMARY.Activity") + t(`TradeExpeditiureMonitor.SUMMARY.${filters.EXP_SUBTOTALS_BY}`)}
      //     </div>
      //   )
      // },
      renderCell: (params) => {
        const className = 'MuiDataGrid-cellContent ' + params.row.type
        if (params.row.EXP_SUBTOTALS_BY === 'ExternalCategory') {
          const value = t("EXT_CATEGORY", { ns: 'mars_dictionaries' })[params.value] || params.value
          return <div className={className} >
            {value}
          </div>
        }
        if (params.row.EXP_SUBTOTALS_BY === 'ExternalSpendType') {
          const value = t("EXT_SPEND_TYPE", { ns: 'mars_dictionaries' })[params.value] || params.value
          return <div className={className} >
            {value}
          </div>
        }
        return <div className='MuiDataGrid-cellContent'>{params.value}</div>
      }
    },
    {
      field: 'VALUE',
      headerName: t("TradeExpeditiureMonitor.SUMMARY.PlanActual"),
      flex: 0.25,
      minWidth: 100,
      sortable: false,
      hideable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: ({ row }) => {
        return <div className='MuiDataGrid-cellContent' style={{ display: 'block' }}>
          <div className={row.type}>
            {t(`TradeExpeditiureMonitor.SUMMARY.${row.type}`)}
          </div>
        </div>
      }
    },
    {
      field: 'P1',
      headerName: t("TradeExpeditiureMonitor.P1"),
      minWidth: 50,
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P2',
      headerName: t("TradeExpeditiureMonitor.P2"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P3',
      headerName: t("TradeExpeditiureMonitor.P3"),
      minWidth: 50,
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P4',
      headerName: t("TradeExpeditiureMonitor.P4"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P5',
      headerName: t("TradeExpeditiureMonitor.P5"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P6',
      headerName: t("TradeExpeditiureMonitor.P6"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P7',
      headerName: t("TradeExpeditiureMonitor.P7"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P8',
      headerName: t("TradeExpeditiureMonitor.P8"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P9',
      headerName: t("TradeExpeditiureMonitor.P9"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P10',
      headerName: t("TradeExpeditiureMonitor.P10"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P11',
      headerName: t("TradeExpeditiureMonitor.P11"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P12',
      headerName: t("TradeExpeditiureMonitor.P12"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'P13',
      headerName: t("TradeExpeditiureMonitor.P13"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
    {
      field: 'YTD',
      headerName: t("TradeExpeditiureMonitor.YTD"), //t("Config.ACCRUAL_OBJECT_TYPE_DETERMINATION.AO_TYPE"),
      minWidth: 50, 
      width: 120,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'balance' && params.value < 0) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}</div>
      }
    },
  ])

  const [columnsANM, setColumnsANM] = useState([
    {
      field: 'name',
      headerName: t("TradeExpeditiureMonitor.SUMMARY.ActivityNatureAndMechanic"),
      minWidth: 130,
      sortable: false,
      hideable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: ({ row }) => {
        if (row.type !== 'total_amount') {
          return ''
        }
        return <div className='MuiDataGrid-cellContent'>
          {row.ext_inv_nature==='Discretionary'? t("SUMMARY_MECHANIC", { ns: 'mars_dictionaries' })[row.ext_inv_nature] : row.ext_inv_nature } - {t("SUMMARY_MECHANIC", { ns: 'mars_dictionaries' })[row.mechanic]}
        </div>
      }
    },
    {
      field: 'VALUE',
      headerName: t("TradeExpeditiureMonitor.SUMMARY.ActualsPosted"),
      flex: 0.25,
      minWidth: 100,
      sortable: false,
      hideable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: ({ row }) => {
        return <div style={{ display: 'block' }}>
          <div className={row.type}>
            {t(`TradeExpeditiureMonitor.SUMMARY.${row.type}`)}
          </div>
        </div>
      }
    },
    {
      field: 'P1',
      headerName: t("TradeExpeditiureMonitor.P1"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{params.row.type === 'percent_of_gsv'&&params.value===999?'>':''}{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P2',
      headerName: t("TradeExpeditiureMonitor.P2"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P3',
      headerName: t("TradeExpeditiureMonitor.P3"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P4',
      headerName: t("TradeExpeditiureMonitor.P4"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P5',
      headerName: t("TradeExpeditiureMonitor.P5"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P6',
      headerName: t("TradeExpeditiureMonitor.P6"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P7',
      headerName: t("TradeExpeditiureMonitor.P7"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P8',
      headerName: t("TradeExpeditiureMonitor.P8"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P9',
      headerName: t("TradeExpeditiureMonitor.P9"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P10',
      headerName: t("TradeExpeditiureMonitor.P10"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P11',
      headerName: t("TradeExpeditiureMonitor.P11"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P12',
      headerName: t("TradeExpeditiureMonitor.P12"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'P13',
      headerName: t("TradeExpeditiureMonitor.P13"),
      minWidth: 50, 
      width: 90,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
    {
      field: 'YTD',
      headerName: t("TradeExpeditiureMonitor.YTD"), //t("Config.ACCRUAL_OBJECT_TYPE_DETERMINATION.AO_TYPE"),
      minWidth: 50, 
      width: 120,
      align: "right",
      sortable: false,
      cellClassName: (params)=> params.row.type, 
      renderCell: (params) => {
        let className = "MuiDataGrid-cellContent " + params.row.type;
        if (params.row.type === 'percent_of_gsv' && params.value > 10 && params.value <= 20) {
          className += ' orange'
        }
        if (params.row.type === 'percent_of_gsv' && params.value > 20) {
          className += ' red'
        }
        if (isNaN(params.value) || (!params.value && params.value !== 0)) {
          return <div className={className}>{params.value}</div>
        }
        return <div className={className}>{numberFormatter(prefsData.decimal_notation).format(params.value)}{params.row.type === 'percent_of_gsv'?'%':''}</div>
      }
    },
  ])

  useEffect(() => {
    setLoading(true);
    setColumnsSummary(pr => pr.map(it=>{
      if(it.field==='name'){
        it.headerName = t("TradeExpeditiureMonitor.SUMMARY.Activity") + t(`TradeExpeditiureMonitor.SUMMARY.${filters.EXP_SUBTOTALS_BY}`)
      }
      return it;
    }))
  }, [filters])

  useEffect(() => {
    setShowPeriods({'summary': false, 'activity_nature_mechanic': false})
    let lastPeriod = {'summary': 0, 'activity_nature_mechanic': 0}
    if (data.length) {
      const r = []
      setRowsANM(() => {
        const newData = data[0].activity_nature_mechanic.map(it => {
          const total_amount = {
            ext_inv_nature: it._id.ext_inv_nature,
            mechanic: it._id.mechanic,
            type: 'total_amount',
            id: it._id.ext_inv_nature + it._id.mechanic + 'total_amount',
            YTD: it['YTD'].total_amount,
          }
          const percent_of_gsv = {
            ext_inv_nature: it._id.ext_inv_nature,
            mechanic: it._id.mechanic,
            type: 'percent_of_gsv',
            id: it._id.ext_inv_nature + it._id.mechanic + 'percent_of_gsv',
            YTD: it['YTD'].percent_of_gsv
          }

          let isZero = true;
          for (const property in it) {
            if (property.slice(-4) === filters.YEAR) {
              const name = property.slice(0, -5)
              total_amount[name] = it[property].total_amount;
              percent_of_gsv[name] = it[property].percent_of_gsv;
              if ((it[property].total_amount !== 0 || it[property].percent_of_gsv !== 0) && property.slice(1, -5) > lastPeriod.activity_nature_mechanic + 1) {
                lastPeriod.activity_nature_mechanic = property.slice(1, -5) - 1
              }
              if(it[property].total_amount!=0||it[property].percent_of_gsv!=0){
                isZero=false
              }
            }
          }
          if(it._id.ext_inv_nature==='Other' && isZero && (it._id.mechanic==='LS'||it._id.mechanic==='SB')){
            return []
          }
          return [total_amount, percent_of_gsv]
        }).flat()
        const emptyPeriods = periods.slice(lastPeriod['activity_nature_mechanic'] + 1)

        newData.map(it => {
          emptyPeriods.forEach(period => {
            it[period] = ''
          })
          return it;
        })
        const hiddenColumn = {}
        periods.map((it, ind) => {

          if (ind < lastPeriod['activity_nature_mechanic'] - 1) {
            hiddenColumn[it] = false
          } else if (ind > lastPeriod['activity_nature_mechanic'] + 1) {
            hiddenColumn[it] = false
          } else {
            hiddenColumn[it] = true
          }
        })
        if (lastPeriod.activity_nature_mechanic >= 2) {
          hiddenColumn['showPeriods'] = true
          setColumnsANM(pr => [].concat(pr.filter(it => it.field !== "showPeriods").slice(0, lastPeriod.activity_nature_mechanic + 1), showPeriodsTemplate, pr.filter(it => it.field !== "showPeriods").slice(lastPeriod.activity_nature_mechanic + 1)))
        }
        setLastPeriods(pr => ({ ...pr, 'activity_nature_mechanic': lastPeriod.activity_nature_mechanic }));
        setColumnVisibility(pr => ({ ...pr, 'activity_nature_mechanic': hiddenColumn }));

        return newData
      }
      )
      setRowsSummary(() => {
        const newData = Object.entries(data[0].summary).map(it => {
          const periods = []
          const plan = {}
          for (const period in it[1]['plan']) {
            if (period.slice(-4) === filters.YEAR) {
              plan[period.slice(0, -5)] = it[1]['plan'][period];
              if (it[1]['plan'][period] !== 0 && period.slice(1, -5) > lastPeriod.summary + 1) {
                lastPeriod.summary = period.slice(1, -5) - 1
              }
            }
          }
          plan['name'] = it[0];
          plan['id'] = 'plan' + it[0];
          plan['type'] = 'plan';
          plan['YTD'] = it[1]['plan']['YTD'];
          plan['EXP_SUBTOTALS_BY']=filters.EXP_SUBTOTALS_BY
          periods.push(plan)

          const actual = {}
          for (const period in it[1]['actual']) {
            if (period.slice(-4) === filters.YEAR) {
              actual[period.slice(0, -5)] = it[1]['actual'][period];
            }
          }
          actual['name'] = '';
          actual['id'] = 'actual' + it[0];
          actual['type'] = 'actual';
          actual['YTD'] = it[1]['actual']['YTD'];
          actual['EXP_SUBTOTALS_BY'] = filters['EXP_SUBTOTALS_BY']
          periods.push(actual)

          const balance = {}
          for (const period in it[1]['balance']) {
            if (period.slice(-4) === filters.YEAR) {
              balance[period.slice(0, -5)] = it[1]['balance'][period];
            }
          }
          balance['name'] = '';
          balance['id'] = 'balance' + it[0];
          balance['type'] = 'balance';
          balance['YTD'] = it[1]['balance']['YTD'];
          balance['EXP_SUBTOTALS_BY'] = filters.EXP_SUBTOTALS_BY
          periods.push(balance)

          return periods
        }).flat()
        const emptyPeriods = periods.slice(lastPeriod['summary'] + 1)

        newData.map(it => {
          emptyPeriods.forEach(period => {
            it[period] = ''
          })
          return it;
        })
        const hiddenColumn = {}
        periods.map((it, ind) => {

          if (ind < lastPeriod['summary'] - 1) {
            hiddenColumn[it] = false
          } else if (ind > lastPeriod['summary'] + 1) {
            hiddenColumn[it] = false
          } else {
            hiddenColumn[it] = true
          }
        })
        if (lastPeriod.summary >= 2) {
          hiddenColumn['showPeriods'] = true
          setColumnsSummary(pr => [].concat(pr.filter(it => it.field !== "showPeriods").slice(0, lastPeriod.summary + 1), showPeriodsTemplate, pr.filter(it => it.field !== "showPeriods").slice(lastPeriod.summary + 1)))
        }
        setLastPeriods(pr => ({ ...pr, 'summary': lastPeriod.summary }));
        setColumnVisibility(pr => ({ ...pr, 'summary': hiddenColumn }));


        return newData
      })

      setLoading(false)
    } else {
      setRowsANM([])
      setRowsSummary([])
      setLoading(false)
      const prevColumnVisibility={}
      prevColumnVisibility['showPeriods'] = false

      periods.forEach(it => {
        prevColumnVisibility[it] = false
      })
      setColumnVisibility({summary: prevColumnVisibility, activity_nature_mechanic: prevColumnVisibility})
    }

  }, [data])



  const onColumnHeaderClick = (ev, type) => {

    if (ev.field === 'showPeriods') {
      if (!showPeriods[type]) {
        const hiddenColumn = columnVisibility[type]

        if (lastPeriods[type] < periods.length) {
          periods.slice(0, lastPeriods[type]).forEach(it => {
            hiddenColumn[it] = true
          })
        }

        setColumnVisibility(pr => ({ ...pr, [type]: hiddenColumn }))
      } else {
        const hiddenColumn = columnVisibility[type];

        if (lastPeriods[type] < periods.length) {
          periods.slice(0, lastPeriods[type]-1).forEach(it => {
            hiddenColumn[it] = false
          })
        }

        setColumnVisibility(pr => ({ ...pr, [type]: hiddenColumn }))
      }
      if (type === 'summary') {
        setColumnsSummary(pr => pr.map(it => {
          if (it.field === 'showPeriods') {
            it.headerName = showPeriods[type] ? '+' : '-'
          }
          return it
        }))
      } else {
        setColumnsANM(pr => pr.map(it => {
          if (it.field === 'showPeriods') {
            it.headerName = showPeriods[type] ? '+' : '-'
          }
          return it
        }))
      }

      setShowPeriods(pr => ({ ...pr, [type]: !pr[type] }))
    }
  }

  const displayContent = () => {
    return (<>
      <Box sx={{ margin: '10px 0', display: 'flex', justifyContent: 'space-between' }}>
        {filtersSummary.map((it, ind) => {
          const { headerName, field, valueOptions } = it;
          return (
            <div className='bpx-modal-add-data' key={ind}>
              {headerName}
              <FormControl sx={{ m: 1 }} size="small">
                <Select
                  labelId="demo-select-small"
                  value={filters[field] || ''}
                  disabled={!data.length}
                  onChange={(e) => onFilterSummary(field, e.target.value)}
                  className='bpx-modal-add-data-field'
                >
                  {
                    valueOptions.map((item, i) => {

                      return <MenuItem key={i} value={item.value} sx={{ fontSize: '12px' }} >{item.name}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
            </div>
          )
        })}
      </Box>

      <Box sx={{ marginBottom: '30px' }}>
        <DataGridPro
          localeText={{
            noRowsLabel: t("Common.noRows")
          }}
          loading={loading}
          onColumnHeaderClick={(ev) => onColumnHeaderClick(ev, 'summary')}
          className='data-grid-1'
          sx={{
            '.MuiDataGrid-main': {
              fontSize: '10px'
            },
            '& .MuiDataGrid-main': {
              height: rowsSummary.length < 21 ? '100%' : '50vh'
            },
          }}
          autoHeight={Boolean(rowsSummary.length < 21)}
          headerHeight={40}
          rows={rowsSummary}
          // onRowsScrollEnd={handleOnRowsScrollEnd}
          columns={columnsSummary}
          // loading={loading}
          // rowModesModel={rowModesModel}
          experimentalFeatures={{ newEditingApi: true }}
          hideFooter={true}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          columnVisibilityModel={columnVisibility['summary']}
          initialState={{
            columns: {
              columnVisibilityModel: columnVisibility['summary']
            },
          }}
          getRowHeight={(props) => {
            if(props.model.type==='balance'){
              return 19
            }
            if(props.model.type==='plan'){
              return 20
            }  
            return 17;
          }}
        />
      </Box>

      <Box>
        <DataGridPro
          loading={loading}
          onColumnHeaderClick={(ev) => onColumnHeaderClick(ev, 'activity_nature_mechanic')}
          className='data-grid-2'
          sx={{
            '.MuiDataGrid-main': {
              fontSize: '10px'
            },
            '& .MuiDataGrid-main': {
              height: rowsANM.length < 14 ? '100%' : '50vh'
            },
            '& .MuiDataGrid-cell': {
              paddingTop: '4px'
            }
          }}
          autoHeight={Boolean(rowsANM.length < 14)}
          headerHeight={40}
          rowHeight={20}
          localeText={{
            noRowsLabel: t("Common.noRows")
          }}
          rows={rowsANM}
          // onRowsScrollEnd={handleOnRowsScrollEnd}
          columns={columnsANM}
          // loading={loading}
          // rowModesModel={rowModesModel}
          experimentalFeatures={{ newEditingApi: true }}
          hideFooter={true}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          columnVisibilityModel={columnVisibility['activity_nature_mechanic']}
          initialState={{
            columns: {
              columnVisibilityModel: columnVisibility['activity_nature_mechanic']
            },
          }}
        />
      </Box>

    </>)
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className='trade-summary'
      sx={{
        '& .MuiDialog-paper': {
          width: '1200px',
          minWidth: '1200px',
          fontSize: '12px'
        }
      }}
    >
      <DialogContent sx={{ paddingTop: 0 }} >
        {displayContent()}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          {t("MODAL.BUTTONS.CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}