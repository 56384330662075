import React from 'react'
import { useTranslation } from 'react-i18next'
import { Lock, LockOpen, DisabledByDefaultOutlined } from '@mui/icons-material'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import RestoreIcon from '@mui/icons-material/Restore';
import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0),
  },
  '& .MuiInputBase-input': {
    borderRadius: 5,
    width: '18.5px !important',
    height: '18px !important',
    minHeight: '18px !important',
    position: 'relative',
    border: '2px solid #ced4da',
    padding: '1px !important',
    transition: theme.transitions.create(['border-color', 'opacity']),
    '&:focus': {
      borderColor: '#e59073',
      boxShadow: "none",
    }
  },
}))

function CustomActionList({ id, data, handleBlock, handleLog, handleDelete }) {
  const { value, key } = data;

  const { t } = useTranslation('translation')

  const bp_statuses = ["Active", "Closing", "Blocked_Posting"]
  const statuses = key === "BP_STATUS" ? bp_statuses : ["Active", "Closing", "Planned_Closing", "Blocked_General", "Blocked_Sales", "Planned_Block_Sales", "Planned_Block_General"]

  return [
    <React.Fragment key={id}>
      <FormControl size="small" >
        {/* <InputLabel className="customLabelTable">{t("BPList.ACTIONS")}</InputLabel> */}
        <Select
          className='custom-link-table'
          defaultValue=""
          variant="outlined"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right"
            },
          }}
          IconComponent={(props) =><DoubleArrowIcon fontSize='small'
          {...props} />}
          input={<BootstrapInput />}
        >
          {(value !== "Deleted" && value !== "Deleted_in_AE") &&
              (statuses.includes(value)
                ?
          <MenuItem className='customOptionTable' onClick={handleBlock}>
            
                <>
                  <Lock sx={{ color: "#696969", height: "0.9rem", marginRight: "3px" }} />
                  <span>{t("Buttons.Block")}</span>
                </>
                </MenuItem>
                :
                <MenuItem className='customOptionTable' onClick={handleBlock}>
                <>
                  <LockOpen sx={{ color: "#696969", height: "0.9rem", marginRight: "3px" }} />
                  <span>{t("Buttons.Unlock")}</span>
                </>
            
          </MenuItem>)
          }
          {
              handleDelete && value !== "Deleted" && value !== "Deleted_in_AE" ?
                <MenuItem className='customOptionTable' onClick={handleDelete}>
                  <DisabledByDefaultOutlined sx={{ color: "#696969", height: "0.9rem", marginRight: "3px" }} />
                  <span>{t("Buttons.DELETE")}</span>
                </MenuItem>:
                null
          }
          <MenuItem className='customOptionTable' onClick={handleLog}>
            <RestoreIcon sx={{ color: "#696969", height: "0.9rem", marginRight: "3px" }} />
            <span>{t("Menu.changeLogs")}</span>
          </MenuItem>

        </Select>
      </FormControl>
    </React.Fragment>
  ]


}

export default CustomActionList
