

import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridPro, GridOverlay, GridRowModes, GridActionsCellItem } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { useFetch } from '../index'
import { backend_url } from '../../settings'
import { getParam } from '../../utils/utils'
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { CustomToolbar } from '../../components'

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}


export default function HookCRUDTable (props) {

  const {
    initialColumn, initialFilter, initialBreadcrumb, url, title, action = false, editKey = "", weirdResponse 
  } = props


    const { t } = useTranslation()
    const tokenBpxRequest = localStorage.getItem('token_bpx')
    const value_bu = localStorage.getItem('bu_bpx')
    const request = useFetch()

    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const [rowModesModel, setRowModesModel] = useState({})
    
    const [filter, setFilter] = useState(initialFilter)

    const changeFilterValues = (state) => {
      setFilter(state)
      setRows([])
      setPage(0)
    }
    
    const addLoadedRows = useCallback((items) => {

      let changeIdRow 

      if (weirdResponse) {
        changeIdRow = Object.keys(items).map(item => {
          return {
            id: item,
            ROLE_ID: item,
            DIMENSION_ID: items[item]
          }
        })  
      } else {
        changeIdRow = items.map((d) => {
          return {
            id: editKey ? d[editKey] : d,
            ...d,
          }
        })
  
        
      }

      setRows((old) => {
        return [...old, ...changeIdRow]
      })
      

    }, [weirdResponse])


    useEffect(() => {
            
      setLoading(true)

      let link = ""
      const queryParams = {
        business_unit: value_bu,
        limit: 100,
        skip: page,
      }

      filter.map((key) => {
        const { valueFilter, firstKeyOption, formatField, noObject, noMultiple } = key
        
        switch (formatField) {
          case "select":
            
            if (noObject && noMultiple) {
              return link = valueFilter ? valueFilter[firstKeyOption] : ""
            }

            
            if (valueFilter.length) {
              queryParams[firstKeyOption] = valueFilter
            }

            break
          case "date":
            if (valueFilter) {
              queryParams[firstKeyOption] = valueFilter
            }
            break
          default:
            return null
        }
      })

      let mainURL = ""
      const queryString = getParam(queryParams).toString() 

      if (link) {
        mainURL = `${url}/${link}?${queryString}`
      } else {
        mainURL = `${url}?${queryString}`
      }

      request.fetchData(
        mainURL,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(data => {
        setLoading(false)
        addLoadedRows(data)
        setCount(data.length)
      })
      .catch(err => {
        setLoading(false)
      })


    }, [filter, page])


    const handleOnRowsScrollEnd = (params) => {
      if (params.viewportPageSize) {
        if (count >= 100) {
          return setPage(page + 100)
        } else {
          return null
        }
      }
    }

    const handleRowEditStart = (params, event) => {
      event.defaultMuiPrevented = true
    }
  
    const handleRowEditStop = (params, event) => {
      event.defaultMuiPrevented = true
    }
  
    const handleEditClick = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
    }
  
    const handleSaveClick = (id, body) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
    }

    const handleDeleteMode = (id) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: 'delete' } })
    }

    const handleDeleteClick = (id) => () => {
      
      request.fetchData(
        `${url}/${id}`,
        'DELETE',
        null,
        null,
        tokenBpxRequest
      )
      .then(success => {

         if (success.message === "ok") {
          setRows(rows.filter((row) => row.id !== id))
         }
        
      })
      .catch(e => {
        return null
      })

    }
  
    const handleCancelClick = (id) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      })
  
      const editedRow = rows.find((row) => row.id === id)
      if (editedRow.isNew) {
        setRows(rows.filter((row) => row.id !== id))
      }
    }

    const editFetchFunc = (editRow) => {
      const exclude = { ...editRow }
      delete exclude.id
      return request.fetchData(
        `${url}/${editRow.id}`,
        'PUT',
        JSON.stringify(exclude),
        null,
        tokenBpxRequest
      )
    }
  
    const processRowUpdate = (newRow) => {
      const updatedRow = { ...newRow, isNew: false }
      
      return editFetchFunc(newRow).then(success => {
        if (success.message === "ok") {
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
          return updatedRow
        }
      })
      .catch(err => {
        return null
      })
      
    }
  
    const handleRowModesModelChange = (newRowModesModel) => {
      setRowModesModel(newRowModesModel)
    }


    const columns = [
      {
        field: 'actions',
        type: 'actions',
        hide: action,
        headerName: 'Actions',
        width: 100,
        cellClassName: 'actions',
        getActions: (param) => {

          const { id } = param
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit
          const isInDeleteMode = rowModesModel[id]?.mode === 'delete'

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                icon={<SaveIcon />}
                label="Save"
                onClick={handleSaveClick(id, param.row)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ]
          }

          if (isInDeleteMode) {
            return [
              <GridActionsCellItem
                icon={<DoneIcon />}
                label="Save"
                onClick={handleDeleteClick(id)}
              />,
              <GridActionsCellItem
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                color="inherit"
              />,
            ]
          }


  
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteMode(id)}
              color="inherit"
            />,
          ]
        },
      },
      ...initialColumn
    ]


    return (
      <div className='tableContainer'>
        <DataGridPro
          rows={rows}
          columns={columns}
          loading={loading}
          disableSelectionOnClick={true}
          disableColumnFilter
          onRowsScrollEnd={handleOnRowsScrollEnd}
          
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStart={handleRowEditStart}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          
          components={{
            Toolbar: CustomToolbar,
            LoadingOverlay: CustomLoadingOverlay,
          }}
          
          getRowHeight={() => 'auto'}
          sx={{
            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '10px' },
          }}
          localeText={{
            noRowsLabel: t("Common.noRows")
          }}
          

          componentsProps={{
            toolbar: {
              bread: initialBreadcrumb,
              title: title,
              filters:
                <CustomMultipleFilter 
                  onFilter={changeFilterValues} 
                  dataFilter={filter} 
                  preload={backend_url.my_balance_filter_values}
                  loading={loading}
                />
            },
          }}
        />
      </div>
    )

}
