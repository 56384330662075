import React, { useEffect, useState } from 'react';
import { backend_url } from '../../../../settings';
import SelectComponent from './SelectComponent';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../../../hooks'
import { getParam } from "../../../../utils/utils"
import { useUserPrefs } from '../../../UserPreferences/ProvideUserPrefs'
import moment from 'moment'

function DropDown({ handleDropDown, handleClose, state }) {

  const { t } = useTranslation('translation');
  const request = useFetch();
  const tokenBpxRequest = localStorage.getItem('token_bpx');
  const { prefsData } = useUserPrefs()

  const listOfParameters = [
    {
      value: 'SubT 1 Code',
      name: 'SubT 1 Name'
    },
    {
      value: 'SubT 2 Code',
      name: 'SubT 2 Name'
    },     
    {
      value: 'SubT 3 Code',
      name: 'SubT 3 Name'
    },
    {
      value: 'SubT 4 Code',
      name: 'SubT 4 Name'
    },
    {
      value: 'SubT 5 Code',
      name: 'SubT 5 Name'
    },
  ]
  
  const [dropDownData, setDropDownData] = useState([
    {
      name: 'PLAN_ACCOUNT',
      value: ['PLAN_ACCOUNT_NUMBER', 'PLAN_ACCOUNT_CAT'],
      title: t("Home.Highlights.DROP_BOX.PLAN_ACCOUNT"),
      type: 'singleSelect',
      url: backend_url.get_plan_accounts,
      urlParams:  backend_url.config_report_parameters,
      optionsValue: [],
      loading: true,
      params:[
        {
          field: 'Object Valid From',
          name: 'DATE_FROM_EXFACTORY',
          valueParser: (value, name)=>{
            const parse = value.split('.');
            let year = '';
            switch(parse[2]){
              case 'CY':
                year = new Date().getFullYear();
                break;
              case 'LY':
                year = new Date().getFullYear()-1;
                break;
            }
            if(!year){
              return ''
            }
            parse[2] = year;
            
            return [`${name}=${moment(parse.join('.'), "DD.MM.YYYY").format("YYYY-MM-DD")}`]
          },
        },
        {
          field: 'Object Valid To',
          name: 'DATE_TO_EXFACTORY',
          valueParser: (value, name)=>{
            const parse = value.split('.');
            let year = '';
            switch(parse[2]){
              case 'CY':
                year = new Date().getFullYear();
                break;
              case 'LY':
                year = new Date().getFullYear()-1;
                break;
            }
            if(!year){
              return ''
            }
            parse[2] = year;

            return [`${name}=${moment(parse.join('.'), "DD.MM.YYYY").format("YYYY-MM-DD")}`]
          },
        },
        {
          field: 'Object Status 1', 
          name: 'AO_STATUS',
          valueParser: (values, name)=>values.split(',').map((it)=>`${name}=${it}`),
        }
      ],
      endpointDepens: [
        {
          field: 'business_unit', 
          name: 'bu_bpx' 
        }
      ],
      endPointParameters: [
        {
          field: 'REPORT_CODE',
          value: 'PAT'
        },
      ],
      subTitles: ['PLAN_ACCOUNT_CAT', 'PLAN_ACCOUNT_NUMBER', 'PLAN_ACCOUNT_NAME'],

    },
    {
      name: 'PERIOD_TYPE',
      value: 'DATE_PERIODS',
      title: t("Home.Highlights.DROP_BOX.PERIOD_TYPE"),
      type: 'singleSelect',
      loading: false,
      optionsValue: [
        {
          name: t("Home.Highlights.DROP_BOX.PERIOD_TO_DATE"),
          value: 'P',
        },
        {
          name: t("Home.Highlights.DROP_BOX.MONTH_TO_DATE"),
          value: 'M'
        },
        {
          name: t("Home.Highlights.DROP_BOX.YEAR_TO_DATE"),
          value: 'Y'
        }
      ],
    },
    {
      name: 'CALCULATION',
      value: 'CALCULATION',
      title: t("Home.Highlights.DROP_BOX.CALCULATION"),
      type: 'multiSelect',
      optionsValue: [],
      loading: true,
      endpointDepens: [
        {
          field: 'business_unit', 
          name: 'bu_bpx' 
        }
      ],
      endPointParameters: [
        {
          field: 'REPORT_CODE',
          value: 'PAT'
        },
      ],
      params: [
        {
          value: 'ACCRUALS',
          nameValue: 'Accruals',
          valueFind: (data)=>{
            const el = data.find(it=>it.PARAMETER_NAME==='Accruals');
            if(!el || el.PARAMETER_VALUE==='false'){
              return null;
            }
            return {
              name: 'Accruals',
              value: 'ACCRUALS'
            }
          }
        },
        {
          value: 'PAYMENTS',
          nameValue: 'Payments',
          valueFind: (data)=>{
            const el = data.find(it=>it.PARAMETER_NAME==='Payments');
            if(!el || el.PARAMETER_VALUE==='false'){
              return null;
            }
    
            const name = data.find(it=>it.PARAMETER_NAME==='Payments Name')
            if(name){
              return {
                name: name.PARAMETER_VALUE,
                value: 'PAYMENTS'
              }
            }
            return {
              name: 'Payments',
              value: 'PAYMENTS'
            }
          }
        }, 
        {
          value: 'GSV',
          nameValue: 'Gross Sales',
          listOfNames: ['SubT 1 Code', 'SubT 2 Code', 'SubT 3 Code', 'SubT 4 Code', 'SubT 5 Code'],
          valueFind: (data) =>{
            const el =data.filter(it=>listOfParameters.map(t=>t.value).includes(it.PARAMETER_NAME)).find(it=>it.PARAMETER_VALUE.includes('GSV'));
            if(!el){
              return null
            }
            const name = data.find(it=>it.PARAMETER_NAME === listOfParameters.find(t=>t.value===el.PARAMETER_NAME).name);
            if(name){
              return {
                name: name.PARAMETER_VALUE,
                value: 'GSV'
              }
            }
            return {
              name: 'Gross Sales',
              value: 'GSV'
            }
          }
        },
        {
          value: 'NIV',
          nameValue: 'Net Invoice',
          listOfNames: ['SubT 1 Code', 'SubT 2 Code', 'SubT 3 Code', 'SubT 4 Code', 'SubT 5 Code'],
          valueFind: (data) =>{
            const el =data.filter(it=>listOfParameters.map(t=>t.value).includes(it.PARAMETER_NAME)).find(it=>it.PARAMETER_VALUE.includes('NIV'));
            if(!el){
              return null
            }
            const name = data.find(it=>it.PARAMETER_NAME === listOfParameters.find(t=>t.value===el.PARAMETER_NAME).name);
            if(name){
              return {
                name: name.PARAMETER_VALUE,
                value: 'NIV'
              }
            }
            return {
              name: 'Net Invoice',
              value: 'NIV'
            }
          }
        }

      ]
    }
  ]);

  const getUrl = (endpointDepens,endpointParameters) => {
    let param = {
      limit: 100,
      skip: 0
    };

    if (endpointDepens) {
      endpointDepens.forEach(it => {
        if (localStorage.getItem(it.name)) {
          param[it.field] = localStorage.getItem(it.name);
        }
      })
    }

    if(endpointParameters){
      endpointParameters.forEach(it=>{
        param[it.field] = it.value
      })
    }
    const queryString = getParam(param).toString()
    return '?' + queryString;
  }

  const fetchData = (url, endpointDepens=[{field: 'business_unit', name: 'bu_bpx' }], endPointParameters=[{field: 'REPORT_CODE',value: 'PAT'}], paramLine='') =>{
    const line = paramLine.length? '&'+ paramLine : ''
    return request.fetchData(
      url + getUrl(endpointDepens, endPointParameters) + line,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
  }

  useEffect(()=>{
    fetchData(backend_url.config_report_parameters)
      .then(data=>{
        dropDownData.map(it=>{
          const {params, url, endpointDepens, name} = it
          if(name==='PLAN_ACCOUNT'){
                const options = [];
                params.map(param=>{
                  const paramValue = data.find(it=>it.PARAMETER_NAME===param.field);
                  if(paramValue){
                    options.push(...param.valueParser(paramValue.PARAMETER_VALUE, param.name))
                  }
                })
                fetchData(url,endpointDepens,[], options.join('&') ).then((data)=>{
                  setDropDownData(prev=>{
                    return prev.map(dropData=>{
                      if(dropData.name ==='PLAN_ACCOUNT'){
                        dropData.optionsValue = data.map(it => ({ value: dropData.value.map(val => it[val]).join(' - '), name: dropData.subTitles.map(sbt => it[sbt]).join(' - ') }));
                        dropData.loading=false;
                        return dropData
                    } return dropData
                  })      
                })
                if(data.length) {
                  if (!prefsData || !prefsData.PAT_choices.PLAN_ACCOUNT) {
                      handleDropDown('PLAN_ACCOUNT', `${data[0].PLAN_ACCOUNT_NUMBER} - ${data[0].PLAN_ACCOUNT_CAT}`)
                  }
                }
              })
          } else if(name === 'CALCULATION'){
            const options = [];
            params.map(it=>{
              const option = it.valueFind(data);
              if(option){
                options.push(option)
              }
            })
            setDropDownData(prev=>{
              return prev.map(dropData=>{
                if(dropData.name ==='CALCULATION'){
                  dropData.loading=false;
                  dropData.optionsValue = options
              } return dropData
            })     
            })
            if(options.length){
              if (!prefsData || !prefsData.PAT_charts)
                handleDropDown('CALCULATION', [options[0].value])
            }
          }
        })
  })
  }, [])


  const onHandleClick = (name, data) => {
    handleDropDown(name, data)
  }

  const dropDownComponent = dropDownData.map((it, ind) => <SelectComponent key={ind} data={it} state={state} onHandleClick={onHandleClick} handleClose={handleClose} />)

  return (
    <Box className='bpx-chart-drop-box-container'>
      {dropDownComponent}
    </Box>
  )

}

export default DropDown
