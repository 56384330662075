import React, { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useFetch } from '../../hooks'
import { useNavigate } from 'react-router'
import SnackbarUtils from '../../utils/SnackbarUtils'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

export function CircularIndeterminate() {
  return (
    <Box sx={{ textAlign: "center", marginTop: "1rem"  }}>
      <CircularProgress size={60} />
    </Box>
  )
}

export default function DialogStatus({ open, setOpen, sub }) {

  const { status_id, value, endpoint, name_component } = sub
  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const statuses = ["Active", "Closing", "Planned_Closing", "Blocked_General", "Blocked_Sales", "Planned_Block_Sales", "Planned_Block_General", "Blocked_Posting"]

  const text = statuses.includes(value) ? "BLOCK" : "UNLOCK"
  
  const onClickRequest = useCallback((e) => {
    setLoading(true)
    e.preventDefault()
    const param = statuses.includes(value) ? true : false 
    const url = `${endpoint}/${status_id}/manual_block?is_manual_block=${param}&business_unit=${value_bu}`
      
    request.fetchData(
      url,
      'PUT',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
    .then(response => {
      
      if (response === "ok") {
        setLoading(false)
        navigate(0)
      }

    })
    .catch(err => {
      SnackbarUtils.error(JSON.stringify("The request was unsuccessful"))
      setLoading(false)
    })
    
    
  }, [request, endpoint, status_id, tokenBpxRequest, navigate, loading])


  const onCloseHandle = useCallback(() => {
    setOpen(false)
  }, [setOpen])

  const displayContent = (
    <>
      <p>{t("MODAL.block.are_you_sure")} {t(`MODAL.block.${text}`)} {t("MODAL.block.this")} {name_component} {t("MODAL.block.in_your_bu")}</p>
      <p style={{ fontStyle: "italic" }}>{t("MODAL.block.text1")}</p>
    </>
  )

  const displayButton = (
    <>
      <Button variant="outlined" onClick={onCloseHandle}>{t("MODAL.BUTTONS.NO")}</Button>
      <Button variant="contained" color="error" onClick={onClickRequest}>{t("MODAL.BUTTONS.YES")}</Button>
    </>
  )


  return (
    <Dialog
      open={open}
      onClose={onCloseHandle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='dialog-fonts custom-dialog-fonts'>
        <DialogTitle className='dialog-fonts-h2' id="alert-dialog-title">
          {t("MODAL.block.title")}
          <div className="close-for-modal-window">
            <IconButton
              onClick={onCloseHandle}
              className="wrap-cancel-modal"
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          { loading ? <CircularIndeterminate /> :  displayContent }
        </DialogContent>
        <DialogActions>
          { !loading && displayButton }
        </DialogActions>
      </div>
    </Dialog>
  )
}
