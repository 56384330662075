import { backend_url } from '../../settings'
import Checkbox from '@mui/material/Checkbox';
export default function createOptions(data, renderCellForDate, valueParserForDate, stateSelect) {
  const { name, description, type, default_value, optional, dictionary } = data;

  let newParametrs = {
    field: 'additionalProp',
    headerName: name,
    name,
    required: !optional,
    //defaultOption: default_value ? default_value : null,
    flex: 1,
    minWidth: 180,
    description: <>
      <span>{description}</span>
      {default_value ?
        <span><br/>Default value: {default_value}</span>
        : null}
    </>,
  };

  const columnsForAO =  [
    {
      headerName: 'Action',
      size: 40,
      content: (option, selectedOptions)=>(<Checkbox checked={!!selectedOptions.find(it=>it.id===option.id)}  size="small"/>),
      align: 'center'
    },
    {
      headerName: 'AO_ID',
      size: 50,
      content: (option)=>option.AO_ID,
      align: 'left'
    },
    {
      headerName: 'AO_Type',
      size: 100,
      content: (option)=>option.AO_TYPE,
      align: 'left'
    },
    {
      headerName: 'Activity_ID',
      size: 110,
      content: (option)=>option.ACTIVITY_ID,
      align: 'left'
    },
    {
      headerName: 'AO_Description',
      size: 100,
      content: (option)=>option.AO_DESCRIPTION,
      align: 'left'
    },
    {
      headerName: 'Plan_Account',
      size: 100,
      content: (option)=>option.PLAN_ACCOUNT_BP_NR,
      align: 'left'
    },
    {
      headerName: 'Status',
      size: 100,
      content: (option)=>option.AO_STATUS,
      align: 'left'
    },
  ]

  switch (type) {
    case 'integer':
      newParametrs.type = 'number'
      newParametrs.valueParser = (value) => {
        return Number(value)
      }
      break;
    case 'boolean':
      newParametrs.type = 'boolean';
      if (!optional) {
        newParametrs.defaultOption = false;
      }
      newParametrs.valueParser = (value) => {
        return Boolean(value)
      }
      newParametrs.valueFormatted = (value) => {
        return Boolean(value)
      }
      if( name==='adjust_business_date_to_previous_day'){
        newParametrs.style = {
          width: '350px'
        }
      }
      break;
  }
  if(dictionary){
    newParametrs.type = 'singleSelect';
    newParametrs.valueOptions = dictionary
  }
  switch (name) {
    case 'document_date': case 'posting_date': case 'business_date':
      newParametrs.type = 'date';
      newParametrs.renderCell = renderCellForDate;
      newParametrs.valueParser = valueParserForDate;
      break;
    case 'business_unit':
      newParametrs.type = 'singleSelect';
      newParametrs.valueOptions = stateSelect[name] || [];
      break;
    // case 'event_type': case 'status': case 'target_package_status':
    //   newParametrs.type = 'singleSelect';
    //   newParametrs.valueOptions = dictionary
    //   break;
    case 'ao_ids':
      newParametrs.type = 'autoCompleteCustom';
      newParametrs.url = backend_url.accrual_object_header;
      newParametrs.multiple=true;
      newParametrs.columns = columnsForAO;
      newParametrs.optionsLabel = ['AO_ID', 'ACTIVITY_ID', 'AO_DESCRIPTION']
      newParametrs.valueParser = (value)=>{
        if(value){
          return value.map(it=>String(it.AO_ID));
        }
      }
      break;
    case 'ao_id':
      newParametrs.type = 'autoCompleteCustom'
      newParametrs.url = backend_url.accrual_object_header
      newParametrs.multiple=false;
      newParametrs.columns = columnsForAO;
      newParametrs.optionsLabel = ['AO_ID', 'ACTIVITY_ID', 'AO_DESCRIPTION']
      newParametrs.valueParser = (value)=>{
        if(value){
          return String(value.AO_ID)
        }
      }
      break;
  }
  return newParametrs
}