import React, { useState } from "react"
import { Link } from 'react-router-dom'
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function hasChildren(item) {
  const { children } = item

  if (children === undefined) {
    return false
  }

  if (children.constructor !== Array) {
    return false
  }

  if (children.length === 0) {
    return false
  }

  return true
}

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel
  return <Component item={item} />
};

const SingleLevel = ({ item }) => {
  return (
    <ListItem button>
      <Link to={item.link ? item.link : ''}>
        <ListItemText primary={item.name} />
      </Link>
    </ListItem>
  );
};

const MultiLevel = ({ item }) => {
  const { children } = item
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen((prev) => !prev)
  };

  return (
    <React.Fragment>
      <ListItem button onClick={handleClick}>
        {open ? <RemoveIcon /> : <AddIcon />}
        <ListItemText primary={item.name} />
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

function CollapseMenu({ items }) {

  return items.map(
    (item, key) => <MenuItem key={key} item={item} />
  )

}


export default CollapseMenu
