import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { LoadingWithText } from '../../components'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'


export default function ModalDelete({ open, onSubmit, onClose, loading, warning}) {
  const { t } = useTranslation('translation');

  const [loadingPage, setLoadingPage] = useState(false);

  useEffect(()=>{
    setLoadingPage(loading)
  }, [loading])

  const displayBlockForm = () => {
    return (
      <>
        <DialogTitle id="alert-dialog-title" sx={{textAlign: 'center', paddingBottom: 0}}>
        {t("MODAL.DELETE_TITLE")}
          <div className="close-for-modal-window">
            <IconButton onClick={onClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>

        </DialogTitle>
        <DialogContent className='bpx-block-regenerate'> 
          <div className='bpx-top-regenerate' style={{textAlign: 'center'}}>
             <p>{t("MODAL.DELETE")}</p>
             { warning &&
            <span className=" toolbarTitle bpx-auth-warning">
              <span><b>Warning:</b> AE Business Unit Basic role is mandatory</span>
            </span>
          }
          </div>
         
         
        </DialogContent>
        <DialogActions sx={{paddingTop: '0 !important'}}>
          <Button
            variant="outlined" 
            onClick={onClose}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button> 
          <Button
            onClick={onSubmit}
            variant="contained"
            color="primary" 
          >
            {t("MODAL.BUTTONS.OK")}
          </Button>
        </DialogActions>
      </>
    )
  }

  const displayLoading = (
    loadingPage
      ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
      : displayBlockForm()
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { width: '450px' } }}
    >
      {displayLoading}
      
    </Dialog>
  )
}
