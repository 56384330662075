import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'


export default function ModalCloseAO({ aoId, activityId, open, onClose, onSubmit, status }) {

  const [idAO, setIdAO] = useState('');

  const { t } = useTranslation('translation');

  const handleInput = (e) => {
    setIdAO(e.target.value)
  }


  let content = null;
  switch (status) {
    case 'AVAILABLE': {
      content = (<>
        <DialogTitle id="alert-dialog-title">
          {t('MODAL.CLOSE_AO.AVAILABLE.TITLE')}
          <div className="close-for-modal-window">
            <IconButton onClick={onClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <p style={{ fontStyle: 'italic' }}>
            {t('MODAL.CLOSE_AO.AVAILABLE.MAIN.PART1')}
            <b>{aoId}</b>
            {t('MODAL.CLOSE_AO.AVAILABLE.MAIN.PART2')}
            {activityId}
            {t('MODAL.CLOSE_AO.AVAILABLE.MAIN.PART3')}
          </p>
          <Box sx={{ marginTop: '30px' }}>
            <p >
              {t('MODAL.CLOSE_AO.AVAILABLE.ADD_AOID')}
            </p>
            <TextField
              autoFocus
              id="closeAO"
              label="Accrual Object ID"
              autoComplete='off'
              variant="outlined"
              onChange={handleInput}
              sx={{ width: '200px' }}
              size={'small'}
            />
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            {t('MODAL.BUTTONS.CANCEL')}
          </Button>
          <Button onClick={() => onSubmit(idAO)} variant="contained" color="error">
            {t('MODAL.BUTTONS.CONFIRM')}
          </Button>
        </DialogActions>
      </>);
      break;
    }
    case 'PROCESSING': {
      content = (
        <>
          <DialogContent>
            {t('MODAL.CLOSE_AO.PROCESSING.PART1')}
            {aoId}
            {t('MODAL.CLOSE_AO.PROCESSING.PART2')}
            {activityId}
            {t('MODAL.CLOSE_AO.PROCESSING.PART3')}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="primary" >
              {t('MODAL.BUTTONS.OK')}
            </Button>
          </DialogActions>

        </>
      )
      break;
    }
    case 'INPROGRESS': {
      content = (
        <>
          <DialogContent align='center'>
            {t('MODAL.CLOSE_AO.INPROGRESS')}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="primary" >
              {t('MODAL.BUTTONS.OK')}
            </Button>
          </DialogActions>
        </>
      )
    }
    case 'CLOSED': {
      content = (<>
        <DialogTitle id="alert-dialog-title">
          {t('MODAL.CLOSE_AO.AVAILABLE.TITLE')}
          <div className="close-for-modal-window">
            <IconButton onClick={onClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <p style={{ fontStyle: 'italic' }}>
            There was already an action triggered in the past to Close this AO <b>{aoId} </b>
            and apparently it was Completed. Are you sure you want to start another Close AO action for this Accrual Object?
          </p>
          <Box sx={{ marginTop: '30px' }}>
            <p >
              {t('MODAL.CLOSE_AO.AVAILABLE.ADD_AOID')}
            </p>
            <TextField
              autoFocus
              id="closeAO"
              label="Accrual Object ID"
              autoComplete='off'
              variant="outlined"
              onChange={handleInput}
              sx={{ width: '200px' }}
              size={'small'}
            />
          </Box>

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            {t('MODAL.BUTTONS.CANCEL')}
          </Button>
          <Button onClick={() => onSubmit(idAO)} variant="contained" color="error">
            {t('MODAL.BUTTONS.CONFIRM')}
          </Button>
        </DialogActions>
      </>);
      break;
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className='bpx-block-closeoe'
    >
      <div className='dialog-fonts'>
        {content}
      </div>
    </Dialog>
  );
}
