import React from "react";
import { Box } from "@mui/material";
import Dino from '../../assets/images/Dino.png'


export default function NoAccessPage(){
  return (
  <Box sx={{display: 'flex' , justifyContent: 'center', alignContent: 'center'}}>
    <Box sx={{width:700, marginTop: '150px', fontFamily: 'Roboto, Helvetica, Arial, sans-serif'}}>
      <div className='block-help'>
          <div className='block-header-help'>
            <span>Sorry! </span> You have no access.
          </div>
          <div className='block-middle-help' style={{textAlign: 'center'}}>
            <br></br>
            <p sx={{margin: '5px'}}>Please contact your System Administrator or Application Key User for more information.</p>
            <p sx={{margin: '5px'}}>You can also contact with BPX TreXcellence Team: <a href="mailto: ae.support@bpx.pl">ae.support@bpx.pl</a>.</p>
          </div>
          <img src={Dino} style={{position: 'fixed', bottom: 0, right: 0, zIndex: '-5'}} width={500} height={'auto'}/>
        </div>
   
    </Box>
  </Box>
  )
}