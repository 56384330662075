import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFetch } from '../../../../hooks'
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next'
import LinearProgress from '@mui/material/LinearProgress';

function SelectComponent({ onHandleClick, handleClose, data, state }) {

  const { url, title, name, value, endpointDepens, optionsValue, subTitles, type , loading} = data;

  const [rows, setRows] = useState(optionsValue || [])
  const [page, setPage] = useState(0);
  const [loadingOption, setLoadingOption] = useState(true);
  const [position, setPosition] = useState(0);

  const request = useFetch();
  const tokenBpxRequest = localStorage.getItem('token_bpx');
  
  const { t } = useTranslation('translation');

  const listElem = useRef();
  const mounted = useRef();

  useEffect(()=>{
    setRows(optionsValue)
  },[optionsValue])

  const handleChange = (event) => {
    onHandleClick(name, event.target.value);
  };

  useEffect(() => {

    if (!mounted.current) {
      mounted.current = true;
    }
    else if (position && listElem.current) {
      listElem.current.scrollTop = position - listElem.current.offsetHeight;
    }
  })

  const createComponent = () => {
    switch (type) {
      case 'singleSelect':
        return (
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={state[name] || ''}
            defaultValue={''}
            label={title}
            onChange={handleChange}
            onClose={handleClose}
            MenuProps={{
              PaperProps: {
                onScroll: (currentTarget) => {
                  const scrollPosition = currentTarget.target.scrollTop + currentTarget.target.clientHeight;
                  if (currentTarget.target.scrollHeight - scrollPosition <= 1 && !loadingOption) {
                    setLoadingOption(false);
                    setPosition(scrollPosition);
                    setPage(pg => pg + 100);
                  }
                },
              }
            }}
          >
            {
              loading?
                <MenuItem disabled>
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                </MenuItem>:
                rows.length ?
                  rows.map((it, id) => <MenuItem key={id} value={it.value} className='bpx-chart-drop-box-item'>{it.name}</MenuItem>) :
                    <MenuItem value={''} className='bpx-chart-drop-box-item' disabled>No Options</MenuItem>
            }
          </Select>
        )
      case 'multiSelect': 
        return (
          <Select
            size='small'
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={state[name] || []}
            defaultValue={''}
            label={title}
            multiple
            onChange={handleChange}
            onClose={handleClose}
            renderValue={(selected) => selected.map(it=>{
              const el = optionsValue.find(el=>el.value===it)
              if(el){
                return el.name;
              }
              return it;
            }).join(',')}
          >
            {   
                loading ?
                  <MenuItem disabled>
                      <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                      </Box>
                  </MenuItem> :
                  rows.length ? 
                    rows.map((it, id) => {
                      return (
                        <MenuItem key={id} value={it.value} className='bpx-chart-drop-box-item-multiple'>
                          <Checkbox checked={state[name] ? state[name].includes(it.value) : false} size="small" />
                          <ListItemText primaryTypographyProps={{fontSize: '0.75rem' }} primary={it.name}/>
                        </MenuItem>
                      )
                    }) :
                    <MenuItem  value={'No Options'} className='bpx-chart-drop-box-item-multiple' disabled>
                      <ListItemText primaryTypographyProps={{fontSize: '0.75rem' }} primary={'No Options'}/>
                    </MenuItem>
            }
          </Select>
        )
    }
  }

  return (
    <Box className='bpx-chart-drop-box'>
      <FormControl fullWidth  size="small">
        <InputLabel id="demo-select-small-label">{title}</InputLabel>
        {createComponent()}
      </FormControl>
    </Box>
  )

}

export default SelectComponent
