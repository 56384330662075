import React, { useState, useEffect } from "react"
import {
    DndContext,
    TouchSensor,
    PointerSensor,
    KeyboardSensor,
    useSensor,
    useSensors,
    closestCorners,
    DragOverlay,
    useDroppable,
} from "@dnd-kit/core"
import {
    SortableContext,
    useSortable,
    sortableKeyboardCoordinates,
    arrayMove,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable"


import ActivitiesTotal from './ActivitiesTotal'
import RecentlyChanged from './RecentlyChanged'
import AlertTodo from './AlertTodo';
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { HighlightsBoxes,PlanAccountTotal } from './components'
import { CSS } from "@dnd-kit/utilities"
import { TurnedInNot } from "@mui/icons-material"


const Item = ({ item , children, dragActive }) => {
  const { id } = item

  const { setNodeRef, attributes, listeners, transition, transform, isDragging } = useSortable({ 
    id: id,
    disabled: dragActive !== id, 
    data: {
      type: "card"
    } 
  })

  const style = {
    //background: isDragging ? 'white' : 'none',
    zIndex: isDragging ? '5' : '1',
    //transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    transition,
    transform: CSS.Transform.toString(transform),
    opacity: isDragging ? 0.5 : 1 , 
  }

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      id={id}
      className={`bpx-home-section ${dragActive === id ? 'dragging' : ''}`}
    >
      {item.html}
      {children}
  </div>
  )
}

const CardDroppableContainer = ({ container, children }) => {
  const { setNodeRef } = useDroppable({
      id: container,
      data: {
        accepts: "card",
      },
  })

  return (
    <div className="bpx-home-separator" ref={setNodeRef}>
        {children}
    </div>
  )
}


const NestedList = () => {

  const { prefsData, saveUserPrefs, silentUpdateUserPrefs } = useUserPrefs()
  const [dragActive, setDragActive] = useState(null)
  const [activeItem, setActiveItem] = useState();
  const [activeBlock, setActiveBlock] = useState();
  const [toggle, setToggle] = useState(false)
  const [trigger, setTrigger] = useState(false)
  const [items, setItems] = useState({
    A: [
        { 
          id: "HighlightsBoxes", 
          html: (
            (prefsData.landing_page && prefsData.landing_page.Highlights) && (
              <div className='bpx-home-inner'>
                <HighlightsBoxes 
                  onSetDragActive={setDragActive}
                  toggle={toggle}
                  setToggle={setToggle} 
                />
              </div>
            )
          ),
          blocks: [],  
        },
        { 
          id: "PlanAccountTotal", 
          html: (
            (prefsData.landing_page && prefsData.landing_page.Plan_Account_Total) && (
              <div className='bpx-home-inner'>
                <PlanAccountTotal 
                  onSetDragActive={setDragActive} 
                  toggle={toggle}
                  setToggle={setToggle}
                /> 
              </div>
            )
          ),
          blocks: [], 
        },
        {
          id: "AlertAndTodo", 
          html: (
            (prefsData.landing_page && prefsData.landing_page.Alert_And_Todo) && (
              <div className='bpx-home-inner'>
                <AlertTodo 
                  onSetDragActive={setDragActive} 
                  toggle={toggle}
                  setToggle={setToggle}
                />
              </div>
            )
          ),
          blocks: [], 
        }
    ],
    B: [
      { 
        id: "RecentlyChanged", 
        html: (
          (prefsData.landing_page && prefsData.landing_page.Recently_Created_and_Changed) && (
            <div className='bpx-home-inner'>
              <RecentlyChanged 
                onSetDragActive={setDragActive} 
                toggle={toggle}
                setToggle={setToggle}
              />
            </div>
          )
        ),
        blocks: [], 
      },
      { 
        id: "ActivitiesTotal", 
        html: (
          (prefsData.landing_page && prefsData.landing_page.My_Activities_in_Totals) && (
            <div className='bpx-home-inner'>
              <ActivitiesTotal 
                onSetDragActive={setDragActive} 
                toggle={toggle}
                setToggle={setToggle}
              />
            </div>
          )
        ),
        blocks: [], 
      },
    
    ],
  })

    
    useEffect(() => {
      const saveWidget = prefsData.saveWidget
      if (saveWidget) {
        for (const key in saveWidget) {
          if (saveWidget.hasOwnProperty(key)) {
            saveWidget[key].map(item => {
              
              switch(item.id) {
                case "HighlightsBoxes": 
                  item.html = (
                    (prefsData.landing_page && prefsData.landing_page.Highlights) && (
                      <div className='bpx-home-inner'>
                        <HighlightsBoxes 
                          onSetDragActive={setDragActive}
                          toggle={toggle}
                          setToggle={setToggle} 
                        />
                      </div>
                    )
                  )
                  break
                case "RecentlyChanged": 
                  item.html = (
                    (prefsData.landing_page && prefsData.landing_page.Recently_Created_and_Changed) && (
                      <div className='bpx-home-inner'>
                        <RecentlyChanged 
                          onSetDragActive={setDragActive} 
                          toggle={toggle}
                          setToggle={setToggle}
                        />
                      </div>
                    )
                  )
                  break
                case "ActivitiesTotal":
                  item.html = (
                    (prefsData.landing_page && prefsData.landing_page.My_Activities_in_Totals) && (
                      <div className='bpx-home-inner'>
                        <ActivitiesTotal 
                          onSetDragActive={setDragActive} 
                          toggle={toggle}
                          setToggle={setToggle}
                        />
                      </div>
                    )
                  )
                  break
                case "PlanAccountTotal":
                  item.html = (
                    (prefsData.landing_page && prefsData.landing_page.Plan_Account_Total) && (
                      <div className='bpx-home-inner'>
                        <PlanAccountTotal 
                          onSetDragActive={setDragActive} 
                          toggle={toggle}
                          setToggle={setToggle}
                        /> 
                      </div>
                    )
                  )
                  break
                case "AlertAndTodo":
                  item.html = (
                    (prefsData.landing_page && prefsData.landing_page.Alert_And_Todo) && (
                      <div className='bpx-home-inner'>
                        <AlertTodo 
                          onSetDragActive={setDragActive} 
                          toggle={toggle}
                          setToggle={setToggle}
                        />
                      </div>
                    )
                  )
                  break
                default:
                  item.html = null
                  break
              }
  
            })
          }
        }
        
        setItems(saveWidget)
      } 


    }, [])

    useEffect(() => {

      if (trigger) {
        // # Save widget
        const convert = Object.keys(items).reduce((acc, key) => {
          const updatedItems = items[key].map(item => {
            const { html, ...rest } = item
            return { ...rest, html: "", blocks: [] }
          })
          acc[key] = updatedItems
          return acc
        }, {})

        saveUserPrefs({
          ...prefsData,
          saveWidget: convert
        })
        silentUpdateUserPrefs()
        setDragActive(null)
        setTrigger(false)
      } 
      
    }, [trigger])

    const cardsList = {}

    const findContainer = (id) => {
      if (id in items) {
          return id
      }
      return Object.keys(items).find((key) => items[key].map((item) => item.id).includes(id))
    }

    const findCard = (id) => {
      const array = [].concat.apply([], Object.values(items))
      array.forEach((card) => (cardsList[card.id] = card.blocks))

      if (id in cardsList) {
          return id
      }
      return Object.keys(cardsList).find((cardid) => cardsList[cardid].map((item) => item.id).includes(id))
    }

    const sensors = useSensors(
      useSensor(PointerSensor),
      useSensor(TouchSensor, {}),
      useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
      })
    )


    const handleDragStart = (result) => {
      setDragActive(result.active.id)

      if (result.active.data.current.type === "card") {
        //if the draggable type is card set the activeItem to the card
        const container = findContainer(result.active.id)
        const idx = items[container].findIndex((item) => item.id === result.active.id)
        setActiveItem(items[container][idx])
      }

    }

    const handleDragOver = (result) => {
      const { active, over } = result;
      const overId = over?.id;

      if (overId == null || active.id in items) {
        return;
      }

      if (result.active.data.current.type === "card") {
        // if the draggable type is card move the card to the new container
        const overContainer = findContainer(overId)
        const activeContainer = findContainer(active.id)

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems((items) => {
            const activeItems = items[activeContainer]
            const overItems = items[overContainer]

            const overIndex = overItems.findIndex((item) => item.id === overId)
            const activeIndex = activeItems.findIndex((item) => item.id === active.id)

            let newIndex
            if (overId in items) {
                newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                  over &&
                  active.rect.current.translated &&
                  active.rect.current.translated.top > over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 1; //@Dos was 0 change to 1

              newIndex = overIndex > 0 ? overIndex + modifier : overItems.length + 1;
            }

            //recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter((item) => item.id !== active.id),
              [overContainer]: [
                  ...items[overContainer].slice(0, newIndex),
                  items[activeContainer][activeIndex],
                  ...items[overContainer].slice(newIndex, items[overContainer].length),
              ],
            }

          })
        }

      } else if (result.active.data.current.type === "block") {
        // if the draggable type is block move the block to the new card
        const overCard = findCard(overId);
        const activeCard = findCard(active.id);

        if (!overCard || !activeCard) {
            return;
        }

        const activeContainer = result.active.data.current.container
        const overContainer = result.over.data.current.container

        if (overCard !== activeCard) {
          const activeBlocks = cardsList[activeCard];
          const overBlocks = cardsList[overCard];

          const overIndex = overBlocks.findIndex((item) => item.id === overId);
          const activeIndex = activeBlocks.findIndex((item) => item.id === active.id);

          let newIndex;
          if (overId in cardsList) {
            newIndex = overBlocks.length + 1;
          } else {
            const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height;

            const modifier = isBelowOverItem ? 1 : 0;
            newIndex = overIndex > 0 ? overIndex + modifier : overBlocks.length + 1;
          }

          const newCardsList = {
            ...cardsList,
            [activeCard]: cardsList[activeCard].filter((item) => item.id !== active.id),
            [overCard]: [
                ...cardsList[overCard].slice(0, newIndex),
                cardsList[activeCard][activeIndex],
                ...cardsList[overCard].slice(newIndex, cardsList[overCard].length),
            ],
          }

          const newItems = { ...items }
          const newActiveContainer = newItems[activeContainer].map((card) => {
            if (card.id === activeCard) {
                card.blocks = newCardsList[activeCard];
            }
            return card
          })
          const newOverContainer = newItems[overContainer].map((card) => {
            if (card.id === overCard) {
              card.blocks = newCardsList[overCard]
            }
            return card
          })

          newItems[activeContainer] = newActiveContainer
          newItems[overContainer] = newOverContainer

          setItems(newItems)
        }
      }

    }

    const handleDragEnd = (result) => {
      const { active, over } = result;

      if (result.active.data.current.type === "card") {
          const activeContainer = findContainer(active.id);

          if (!activeContainer) {
              setActiveItem(null);
              return;
          }

          const overId = over?.id;

          if (overId == null) {
              setActiveItem(null);
              return;
          }

          const overContainer = findContainer(overId);
          if (overContainer) {
            const activeIndex = items[activeContainer].findIndex((item) => item.id === active.id);
            const overIndex = items[overContainer].findIndex((item) => item.id === overId);

            if (activeIndex !== overIndex) {
              setItems((items) => ({
                  ...items,
                  [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex),
              }))

            }
          }
      } 
      else if (result.active.data.current.type === "block") {
        const activeCard = findCard(active.id);
        if (!activeCard) {
            setActiveBlock(null)
            return;
        }
        const overId = over?.id ; 
        if (overId == null) {
          setActiveBlock(null)
          return;
        }
        const overCard = findCard(overId)
        const overContainer = result.over.data.current.container

        if (overCard) {
          const activeIndex = cardsList[activeCard].findIndex((item) => item.id === active.id);
          const overIndex = cardsList[overCard].findIndex((item) => item.id === overId);
          const newCardsList = {
            ...cardsList,
            [overCard]: arrayMove(cardsList[overCard], activeIndex, overIndex),
          }
          
          const newItems = { ...items }
          const newOverContainer = newItems[overContainer].map((card) => {
            if (card.id === overCard) {
              card.blocks = newCardsList[overCard]
            }
            return card
          })

          newItems[overContainer] = newOverContainer
          setItems(newItems)
        }
      }

      setTrigger(true)
      setActiveBlock(null)
      setActiveItem(null)
    }

    const handleDragCancel = () => {
        setActiveItem(null)
        setActiveBlock(null)
    }    

    return (
      <div className="bpx-home-container">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragOver={handleDragOver}
          onDragStart={handleDragStart}
          onDragEnd={handleDragEnd}
          onDragCancel={handleDragCancel}
        >
          <div className="bpx-home-block">
            {Object.keys(items).map((container) => {
              return (
                <CardDroppableContainer key={container} container={container}>
                    <SortableContext
                      items={items[container].map((item) => item.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {items[container].map((item) => {
                        return (
                          <Item 
                            item={item} 
                            key={item.id} 
                            dragActive={dragActive}
                          >
                          </Item>
                        )
                      })}
                    </SortableContext>
                </CardDroppableContainer>
              )
            })}
          </div>

          <DragOverlay>
              {activeItem ?
                <Item item={activeItem}>
                </Item>
                : null}
          </DragOverlay>

        </DndContext>
      </div>   
    )
}



export default NestedList

