import React, { useEffect, useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useFetch } from '../../hooks'
import { Loading } from '..'
import { useTranslation } from 'react-i18next'
import SnackbarUtils from '../../utils/SnackbarUtils'
import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs";
import { dateTimeFormatter, numberFormatter } from "../../utils/utils"


const AccordionFetch = ({ 
      id, data, columns, dataKey, label, 
      collapse, fetching, url, idField, fetchKey, isPages,
      trigger, setTrigger
  }) => {

  const request = useFetch()
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const { t } = useTranslation('translation')

  const [expanded, setExpanded] = useState("")
  const [loading, setLoading] = useState(false)
  const [prevent, setPrevent] = useState(true)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const { prefsData } = useUserPrefs()
  const handleChange = (panel) => (event, newExpanded) => {

    if (newExpanded && prevent) {
      
      setLoading(true)

      const newUrl = isPages?`${url}&skip=0&limit=100`: url;

      request.fetchData(
        newUrl,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(item => {

        if(fetchKey){
          if(item[fetchKey]){
            const arr = [];
            for (const [key, value] of Object.entries(item[fetchKey])) {
              if(key==='LAST_CHANGED_ON'){
                arr.push({ ATTRIBUTE: t('AOHeaderDetail.PARAMETER.' + key), VALUE: dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true)})
              }
              else{
                arr.push({ ATTRIBUTE: t('AOHeaderDetail.PARAMETER.' + key), VALUE: value})
              }

            }      
            if(arr.find(it=>it.ATTRIBUTE === t('AOHeaderDetail.PARAMETER.'+'RUN_PLAN_REGENERATED')&&it.VALUE)){
              arr.map(it=>{
                if(it.ATTRIBUTE=== t('AOHeaderDetail.PARAMETER.'+'LAST_CHANGED_ON')){
                  it.ATTRIBUTE =  t('AOHeaderDetail.PARAMETER.' +'LAST_CHANGED_ON_RUN_PLAN')
                }
                return it
              })
            }     
            setRows(arr);
            setCount(arr.length)
          }         
        } else {
          if (dataKey === "cal_history") {
            setRows(
              item.map(pp => ({
                id: pp.RUN_ID,
                ...pp
              }))
            )
          } else {
            setRows(item)
          }
          
          setCount(item.length)
        }
        setLoading(false)
        setPrevent(!prevent)
      }).catch((e)=>{
        setRows([]);
        setLoading(false)
        SnackbarUtils.error(JSON.stringify(`Run Plan not found for AO: ${data.AO_ID}`));
      })
    }

    setExpanded(newExpanded ? panel : false)

  }

  useEffect(()=>{
    if(trigger || page){
      setLoading(true)
      request.fetchData(
        `${url}&skip=${page}&limit=100`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      )
      .then(item => {
        if(fetchKey){
          if(item[fetchKey]){
            const arr = [];
            for (const [key, value] of Object.entries(item[fetchKey])) {
              arr.push({ ATTRIBUTE: t('AOHeaderDetail.PARAMETER.' + key), VALUE: value})
            }
            setRows(pr=>[...pr, ...arr]);
            setCount(arr.length)
          }         
        } else {

          if (dataKey === "cal_history") {
            setRows((oldRows) => {
              const newIds = new Set(item.map((d) => d.RUN_ID))
              const filteredRows = oldRows.filter((row) => !newIds.has(row.id))
          
              const changeIdRow = item.map((d) => ({
                id: d.RUN_ID,
                ...d,
              }))
        
              return [...filteredRows, ...changeIdRow]
            })
          } else {
            setRows(pr=> [...pr, ...item])
          }

          setCount(item.length)
        }
        setTrigger(false)
        setLoading(false)
        setPrevent(!prevent)
      }).catch((e)=>{
        setLoading(false)
      })
    }
  },[page, trigger])

  const hideColumns = {};
  columns.filter(it=>it.hideable).map(it=>{
    hideColumns[it.field]=false
    return it;
  })

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&isPages) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

    return (
      <>

        <Accordion expanded={expanded === dataKey} onChange={handleChange(dataKey)}>

          <AccordionSummary expandIcon={<ArrowRightIcon className='bpx-arrow-icon' />} className='bpx-accordion-header'>
            <p>{label}</p>
          </AccordionSummary>

          <AccordionDetails className='bpx-accordion-detail'>
            <div style={{ height: 'auto', width: '100%' }}>
              <DataGridPro
                sx={{ '& .MuiDataGrid-main': { height: rows.length < 8 ? '100%' : '380px' } }}
                autoHeight={Boolean(rows.length < 8)}
                rows={rows}
                columns={columns}
                loading={loading}
                pageSize={50}
                rowsPerPageOptions={[20, 50, 100]}
                density="compact"
                getRowId={row => idField ? row[idField] : (Math.random() + 1).toString(36).substring(2)}
                components={{
                  LoadingOverlay: Loading,
                }}
                localeText={{
                  noRowsLabel: t("Common.noRows")
                }}
                onRowsScrollEnd={handleOnRowsScrollEnd}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      id: !Boolean(idField),
                      CONSECUTIVE_NUMBER: false,
                      ...hideColumns
                    },
                  },
                }}
              /> 
            </div>
          </AccordionDetails>

        </Accordion>
      </>
    )
}

export default AccordionFetch
