import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import FormControl from '@mui/material/FormControl';
import { useTranslation } from 'react-i18next';
import AutoCompleteComponentBP from './AutoCompleteComponentBP';
import { LoadingWithText } from '../../components'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import moment from 'moment'
import FormHelperText from '@mui/material/FormHelperText';
import Slider from '@mui/material/Slider'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { Typography } from "@mui/material"

export default function ModalRDR({ allData, table, open, onClose, handleSubmit, editData, loading }) {

  const [state, setState] = useState([{ RDR: 100 }]);
  const [errorRDR, setErrorRDR] = useState(false);
  const [errorDate, setErrorDate] = useState(false);
  const [errorSameBP, setErrorSameBP] = useState(false);

  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const { t } = useTranslation('translation');

  useEffect(() => {
    if (editData) {
      setState(editData.map(it => ({ ...it, state: 'edit' })))
      setDate(editData[0].VALID_FROM)
    } else {
      setState([{ RDR: 100 }])
    }
  }, [editData])


  useEffect(() => {
    if (!open) {
      setErrorRDR(false)
      setErrorDate(false)
      setErrorSameBP(false)
      setDate(moment(new Date()).format("YYYY-MM-DD"))
      setState([{ RDR: 100 }])
    } else if (!editData && allData.map(it => it.VALID_FROM).find(it => it === date)) {

      setErrorDate(true)
    }
  }, [open])


  const hasDuplicates = (arr) => {
    const seen = new Set();
    const newArr = arr.filter(it => it.state !== 'delete')
    for (const obj of newArr) {
      const key = obj.BP_DISTRIBUTOR_NR + '-' + obj.BP_DISTRIBUTOR_ROLE;
      if (seen.has(key)) {
        setErrorSameBP(true)
        return;
      } else {
        seen.add(key);
      }
    }
    setErrorSameBP(false)
  }

  const checkSumOfRDR = (arr) => {
    
    let rdrs = arr.filter(it => it.state !== 'delete').map(it => it.RDR)
    if(!rdrs.length){
      return
    }
    rdrs = rdrs.reduce((acc, it) => acc + it);
    if (rdrs > 100) {
      setErrorRDR(true)
    } else {
      if (errorRDR) {
        setErrorRDR(false)
      }
    }
  }

  const handleSlider = (idLine) => (e, value) => {
    setState(prev => {
      prev[idLine].RDR = value;
      checkSumOfRDR(prev)
      return [...prev]
    })
  }

  const handleChangeDistributor = (data, idLine) => {
    setState(prev => {
      prev[idLine].BP_DISTRIBUTOR_NR = data.BP_NR
      prev[idLine].BP_DISTRIBUTOR_ROLE = data.BP_ROLE
      hasDuplicates(prev)
      return [...prev]
    })
  }

  const createField = (it, dataField, idLine, idItem) => {
    const { type, dataAutoComplate } = it;
    switch (type) {
      case 'autoComplete':
        return (
          <div className='modal-rates-auto-complate' key={idLine + '-' + idItem} >
            <FormControl>
              <AutoCompleteComponentBP data={dataAutoComplate} prevState={dataField.BP_DISTRIBUTOR_NR || null} onChangeHandler={(data) => handleChangeDistributor(data, idLine)} isAllOption={false} />

            </FormControl></div>
        )
      case 'slider':
        return (
          <div key={idLine + '-' + idItem} className='modal-rates-section'>
            <Box>
              <Slider
                aria-label="small"
                size="small"
                key={`slider-${idLine}`}
                value={dataField.RDR}
                //  getAriaValueText={()=>'valuetext'}
                valueLabelDisplay="on"
                step={1}
                // marks
                min={0}
                max={100}
                onChange={handleSlider(idLine)}
              />
            </Box>
          </div>
        )
    }
  }

  const onDeleteItem = (idLine) => {
    setState(prev => {
     if(!prev.length){
      return []
     }

      if (prev[idLine].state === 'edit') {
        prev[idLine].state = 'delete'
        hasDuplicates(prev)
        checkSumOfRDR(prev)
        return [...prev]
      }
      prev.splice(idLine, 1)
      hasDuplicates(prev)
      checkSumOfRDR(prev)
      return [...prev]
    })
  }

  const content = state.map((data, idLine) => {
    if (data.state === 'delete') {
      return
    }

    return (<div className='modal-rates-content' key={idLine}>
      <Button className='bpx-btn-rates-add bpx-padding' onClick={() => onDeleteItem(idLine)} >
        <DeleteIcon />
      </Button>
      {table.map((it, idItem) => createField(it, data, idLine, idItem))}
    </div>
    )
  });

  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (editData) {
      handleSubmit('edit', state, date)
    } else {
      handleSubmit('create', state, date)
    }
  }

  const onHandlerDate = (e) => {
    const date = moment(e).format("YYYY-MM-DD")
    if (allData.map(it => it.VALID_FROM).find(it => it === date)) {
      setErrorDate(true)
    } else {
      setErrorDate(false)
    }
    setDate(date)
  }

  const handleAddNew = () => {
    setState(prev => {
      let rdrs = prev.filter(it => it.state !== 'delete').map(it => it.RDR)
      rdrs = !rdrs.length ? 0 : rdrs.reduce((acc, it) => acc + it)
      return [...prev, { RDR: 100 - rdrs, state: 'new' }]
    }
    )
  }

  const displayBlockForm = () => {
    return (
      <>
        <DialogTitle sx={{ paddingBottom: 0, textAlign: 'center' }}>
          {t('BPDetail.RDR.title')}
          <hr />
          <div className="close-for-modal-window">
            <IconButton onClick={onClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={onHandleSubmit}>

          <DialogContent sx={{ paddingTop: '20px' }} >
            <FormControl size='small'>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label={t('BPDetail.RDR.Valid_from')}
                  className='bpx-modal-add-data-field'
                  inputFormat="yyyy-MM-dd"
                  mask="____-__-__"
                  onChange={(e) => onHandlerDate(e)}
                  value={date || null}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      size="small"
                      inputProps={
                        {
                          ...params.inputProps,
                          placeholder: "YYYY-MM-DD"
                        }
                      }
                      error={!date}
                    />
                  }
                />
              </LocalizationProvider>
            </FormControl>
            <Box sx={{ paddingTop: '40px' }}>
              {content}
            </Box>


            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button className='bpx-btn-rates-add' onClick={handleAddNew} >
                <AddIcon />
              </Button>
            </Box>
            {
              errorRDR || errorDate || errorSameBP ?
                (<>
                  <Typography variant="body2" component="h6">{t('BPDetail.RDR.errors_warnings')}</Typography>
                  <hr />

                  {errorRDR && <FormHelperText sx={{ color: "red" }}>{t('BPDetail.RDR.errorRDR')}</FormHelperText>}
                  {errorDate && <FormHelperText sx={{ color: "#ed7d31" }}>{t('BPDetail.RDR.errorDate')}</FormHelperText>}
                  {errorSameBP && <FormHelperText sx={{ color: "#ed7d31" }}>{t('BPDetail.RDR.errorSameBP')}</FormHelperText>}
                </>


                )
                : null}
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
            >
              {t("MODAL.BUTTONS.CANCEL")}
            </Button>
            <Button
              disabled={!date || errorRDR || errorDate || errorSameBP}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t("MODAL.BUTTONS.SAVE")}
            </Button>
          </DialogActions>
        </form>
      </>
    )
  }

  const displayLoading = (
    loading
      ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
      : displayBlockForm()
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { minWidth: '700px' } }}
    >
      {displayLoading}
    </Dialog>
  )
}