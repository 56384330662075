import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './ProvideAuth'

const PrivateRoute = ({ children }) => {

  let auth = useAuth()
  let location = useLocation()
  
  if (auth.user) {
    return children
  } else {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  
}

export default PrivateRoute
