import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../../hooks'
import { getParam } from "../../../../utils/utils"
import { Link, useNavigate } from 'react-router-dom'

function BoxComponent({ data }) {

  const {title, url, color, endpointDepens, link, forFilter } = data;

  const [value, setValue] = useState(0);
  const navigate = useNavigate()

  const request = useFetch();
  const tokenBpxRequest = localStorage.getItem('token_bpx');
  const value_bu = localStorage.getItem('bu_bpx')

  useEffect(() => {
    if (url) {
      request.fetchData(
        url + getUrl(),
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false
      ).then(data => {
        setValue(data.length)
      })
    }
  }, [])

  const getUrl = () => {
    let param = {
      limit: 100000,
      skip: 0
    };

    if (endpointDepens) {
      endpointDepens.forEach(it => {
        if(it.value){
          param[it.field] = it.value;
        } else if (localStorage.getItem(it.name)) {
          param[it.field] = localStorage.getItem(it.name);
        }
      })
    }
    const queryString = getParam(param).toString();
    return '?' + queryString;
  }

  const redirectFilter = () => {

    if (data.hasOwnProperty("forFilter")) {
      const filters = [forFilter.field] ? 
        {[forFilter.field]: [forFilter.value]} :
        {}
      localStorage.setItem("filters", JSON.stringify(
        {
          "activitySummary": {
              "business_unit": value_bu,
              "limit": 20,
              "skip": 0,
              ...filters
          }
        }
      ))
  
      navigate(link)
    }
    
  }

  const displayBoxes = data.hasOwnProperty("forFilter") ? (
    <div className="bpx-chart-section" style={{ color: 'inherit', textDecoration: 'inherit', cursor: 'pointer' }} onClick={() => redirectFilter()}>
      <div className="bpx-chart-inner">
        <div className="bpx-chart-title">
          <p>{title}</p>
        </div>
        <div className={`bpx-chart-stat ${color}-stat`}>
          <p>{value}</p>
        </div>
      </div>
    </div>
  ) : (
    <Link className="bpx-chart-section" style={{  color: 'inherit', textDecoration: 'inherit'}} to={link}>
      <div className="bpx-chart-inner">
        <div className="bpx-chart-title">
          <p>{title}</p>
        </div>
        <div className={`bpx-chart-stat ${color}-stat`}>
          <p>{value}</p>
        </div>
      </div>
    </Link>
  )

  

  return (
    displayBoxes
  )

}

export default BoxComponent
