import { useState } from "react";
import { Typography, FormGroup, 
    FormControlLabel, Switch } from "@mui/material"
import { useUserPrefs } from "./ProvideUserPrefs";
import { DarkMode } from "../../components";
import { useTranslation } from 'react-i18next'

export const LookAndFeel = () => {

    const { prefsData, saveLandingPagePref } = useUserPrefs()
    const [state, setState] = useState(prefsData.landing_page)
    const { t } = useTranslation('translation')

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
        saveLandingPagePref({
            ...state,
            [event.target.name]: event.target.checked,
        })
    };

    return (
        <div className="block-look-feel">
            <div className="section-look-feel first">
              <Typography variant="h6">{t("preferences.Landing_page")}</Typography>
              <FormGroup>
                  <FormControlLabel 
                      control={<Switch 
                                  checked={state.My_Activities_in_Totals} 
                                  onChange={handleChange}  
                                  name="My_Activities_in_Totals"/>
                              } 
                      label="My Activities in Totals" />
                  <FormControlLabel 
                      control={<Switch 
                                  checked={state.Recently_Created_and_Changed} 
                                  onChange={handleChange} 
                                  name="Recently_Created_and_Changed"/>
                              } 
                      label="Recently Created and Changed" />
                  {<FormControlLabel 
                      control={<Switch 
                                  checked={state.Highlights} 
                                  onChange={handleChange} 
                                  name="Highlights"/>
                              } 
                      label="Highlights" />}
                  {<FormControlLabel 
                      control={<Switch 
                                  checked={state.Plan_Account_Total} 
                                  onChange={handleChange} 
                                  name="Plan_Account_Total"/>
                              } 
                      label="Plan Account Totals" />}
                  <FormControlLabel 
                      control={<Switch 
                                  checked={state.Alert_And_Todo} 
                                  onChange={handleChange} 
                                  name="Alert_And_Todo"/>
                              } 
                      label="Alert and Todo" />
              </FormGroup>
            </div>

            <DarkMode />
            
            
            {/* <hr/>
            <Typography variant="h6">Theme</Typography>
            <FormGroup>
                <FormControlLabel control={<Switch />} label="dark mode on" />
            </FormGroup> */}
        </div>
    )
}
