import CircularProgress from '@mui/material/CircularProgress'

function LoadingWithText (props) {

  const { text, size } = props

  return (
    <div className="bpx-wrap-loading">
      <div>
        <CircularProgress size={size} />
        <p>{ text }</p>
      </div>
    </div>
  )
}

export default LoadingWithText
