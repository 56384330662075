import React, { useContext, createContext, useState } from 'react'

const authContext = createContext(null)

const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  )
}

export function useAuth() {
  return useContext(authContext)
}

function useProvideAuth() {

  const initialState = localStorage.getItem('isAuth')

  const [user, setUser] = useState(initialState);
  const [isOkta, setOkta] = useState(false)

  const signin = (isOkta=false) => {
    setUser(true);
    setOkta(isOkta)
    localStorage.setItem('isAuth', true)
  }

  const signout = () => {
    setUser(false)
    const from = localStorage.getItem('fromPage')

    localStorage.clear()
    
    if(from.includes('provide_response')){
      localStorage.setItem('fromPage', from)
    }
  }

  return {
    user,
    signin,
    signout,
    isOkta
  }
}


export default ProvideAuth
