import React, { useState, useEffect } from 'react'
import { DataGridPro } from "@mui/x-data-grid-pro"
import { CustomToolbar } from "../../components"
import { useParams } from 'react-router-dom'
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { getParam, numberFormatter, dateTimeFormatter, checkSize, isValidDate, valivDateFormat } from "../../utils/utils"
import { Link } from "react-router-dom"
import { useFetch } from '../../hooks'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SnackbarUtils from '../../utils/SnackbarUtils'
import Dialog from '@mui/material/Dialog'
import Button from '@mui/material/Button'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import TextField from '@mui/material/TextField'
import moment from 'moment'
import ListIcon from '@mui/icons-material/List';
import { useNavigate } from 'react-router'

export default function AccrualPostingDetail() {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  let paramsHook = useParams()

  const request = useFetch()
  const navigate = useNavigate()

  const [rows, setRows] = useState([])
  const [lines, setLines] = useState([])
  const { prefsData, saveUserPrefs } = useUserPrefs()
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Accrual_Posting_Detail || 'm');
  const [open, setOpen] = useState({
    reverseCalculation: false,
    reverseCalculationConfirm: false,
    reversePosting: false,
    reversePostingConfirm: false,
    reverseCalculationPending: false,
    abortPosting: false,
    sendPosting: false
  })
  const [trigger, setTrigger] = useState(false)
  const [actionCal, setActionCal] = useState({})
  const [dateValue, setDateValue] = useState("")
  const [preventStatus, setPreventStatus] = useState({
    action: false,
    inProgress: "",
    statusText: ""
  })
  const [openPreventDialog, setOpenPreventDialog] = useState(false)
  
  useEffect(() => {
    localStorage.setItem("currentUrl", window.location.pathname)

    const queryParams = {
      BUSINESS_UNIT: value_bu,
      message_id: paramsHook.id,
      limit: 100,
    }
    const queryString = getParam(queryParams).toString()

    request.fetchData(
      `${backend_url.accrual_posting}/${paramsHook.id}?${queryString}`,
      'GET',
      null,
      false,
      tokenBpxRequest
    )
    .then(data => {
      
      setRows(
        [data].map((d) => {
          return {
            id: d.MESSAGE_ID,
            ...d,
          }
        })
      )
       
    })


    request.fetchData(
      `${backend_url.accrual_posting_detial}/${paramsHook.id}?business_unit=${value_bu}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(
      name => setLines(
        name.map((d) => {
          return {
            id: d.MESSAGE_ID + d.BP_NR + d.BP_ROLE + d.MATERIAL_NR,
            ...d,
          }
        })
      )
    )
    

  }, [])


  const columns = [
    { 
      field: "id", 
      headerName: "id" 
    },
    {
      field: "ACTIVITY_ID",
      headerName: t("AccrualPostingList.activity_id"),
      hideable: false,
      minWidth: 145,
      renderCell: (params) => (
        <Link
          style={{ color: "#f69e80" }}
          to={`/my-activities/accrual_object/${params.row.AO_ID}`}
          className="dark-mode-link-color"
        >
          {params.value}
        </Link>
      ),
    },
    { 
      field: "EXT_SPEND_TYPE", 
      headerName: t("AccrualPostingList.ext_spend_type"), 
      flex: 1 
    },
    { 
      field: "SALES_AREA", 
      headerName: t("AccrualPostingList.sales_area"), 
      flex: 1 
    },
    { 
      field: "PLAN_ACCOUNT", 
      headerName: t("AccrualPostingList.plan_account"), 
      flex: 1 
    },
    { 
      field: "PERIOD_ID", 
      headerName: t("AccrualPostingList.period"), 
      flex: 1 
    },
    { 
      field: "DATE_ACCRUAL", 
      headerName: t("AccrualPostingList.date_accrual"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
    },
    { 
      field: "DATE_POSTING", 
      headerName: t("AccrualPostingList.date_posting"), 
      flex: 1,
      renderCell: ({ value }) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
    },
    { 
      field: "CURRENCY", headerName: t("AccrualPostingList.currency"), flex: 1 
    },
    { 
      field: "AMOUNT", 
      headerName: t("AccrualPostingList.amount"), 
      flex: 1,
      renderCell: ({ value }) => numberFormatter(prefsData.decimal_notation).format(value),
    },
    { 
      field: "SAP_DOC_NO", 
      headerName: t("AccrualPostingList.sap_doc_no"), 
      flex: 1 
    },
    { 
      field: "STATUS", 
      headerName: t("AccrualPostingList.status"), 
      flex: 1,
      renderCell: ({ value }) => {
        switch(value) {
          case "Success":
            return (
              <div style={{ color: "#00a651" }}>
                {value}
              </div>
            )
          case "Pending":
            return (
              <div style={{ color: "#f66d38" }}>
                {value}
              </div>
            )
          case "Processing":
            return (
              <div style={{ color: "#f66d38" }}>
                {value}
              </div>
            )
          case "Error":
            return (
              <div style={{ color: "#de7300" }}>
                {value}
              </div>
            )
          default:
            return (
              <div style={{ color: "#000" }}>
                {value}
              </div>
            )
        }
      } 
    },
  ]

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("AccrualPostingDetail.Accrual_Balance"), link: "" },
    { label: t("Menu.accrualPostingList"), link: "/accrual-balance/accrual_posting" },
    { label: t("AccrualPostingDetail.Posting_Lines"), link: window.location.pathname },
  ]

  const linesColumn = [
    { field: "BP_NR", headerName: t("AccrualPostingDetail.BP_NR"), flex: 1 },
    { field: "MATERIAL_NR", headerName: t("AccrualPostingDetail.MATERIAL_NR"), flex: 1 },
    { field: "AMOUNT", 
      headerName: t("AccrualPostingDetail.AMOUNT"), 
      flex: 1,
      renderCell: ({ value }) => numberFormatter(prefsData.decimal_notation).format(value),
    },
    { field: "GL_ACCOUNT", 
      headerName: t("AccrualPostingDetail.GL_ACCOUNT"), 
      flex: 1
    },
    { field: "TEXT", headerName: t("AccrualPostingDetail.TEXT"), flex: 1 },
  ]

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Accrual_Posting_Detail: value
      }
    })
  }

  const mappingState = (parentObj, field, value) => { 
    const changingState = {
      [field]: value
    }
    setDateValue(changingState)
  }

  const onChangeDateFilter = (value, typing, keyObj, parentObj = "") => {
      
    if (isValidDate(typing, prefsData && prefsData.date_format)) {        
      let convertDate = valivDateFormat(typing, prefsData && prefsData.date_format)
      mappingState(null, keyObj, convertDate)
    } else {
      if (typing === undefined) {
        mappingState(null, "",)
      } else {
        mappingState(null, null, "")
      }
    }

  }

  const handlePassStateForUpdate = (state) => {
    setTrigger(state)
  }

  const handlePreventStateUpdate = (state) => {
    setPreventStatus(state)
  }

  const handleAction = (params, current_status, new_status, action, nameDialog) => {
    setOpen({
      ...open,
      [nameDialog]: true
    })
    
    setActionCal({ ...params, current_status, new_status, action })
  }

  const handlePreventDialog = () => {
    setOpenPreventDialog(true)
  }

  const handleCloseAction = (nameDialog) => {
    setOpen({
      ...open,
      [nameDialog]: false
    })
    setDateValue("")
    setActionCal({})
  }

  const handleCloseActionOption = (nameDialog) => {
    setOpen({
      ...open,
      [nameDialog]: false
    })
  }

  const handleOpenOption = (nameDialog) => {
    setOpen({
      ...open,
      [nameDialog]: true
    })
  }

  const handleActionRequest = (nameDialog) => {

    request.fetchData(
      `${backend_url.staging_accrual_posting_header_status}`,
      'PATCH',
      JSON.stringify({
        "messages_ids": [actionCal.MESSAGE_ID],
        "action": actionCal.action,
        "business_unit": value_bu,
        ...(dateValue && dateValue.status_date && { "posting_date": dateValue.status_date })
      }),
      false,
      tokenBpxRequest,
      false
    )
    .then((success) => {

      if (success === 204) {
        setOpen({
          reverseCalculation: false,
          reverseCalculationConfirm: false,
          reversePosting: false,
          reversePostingConfirm: false,
          reverseCalculationPending: false,
          abortPosting: false,
          sendPosting: false
        })
        setDateValue("")

        SnackbarUtils.success(JSON.stringify("The status has been sent for processing, it will take a few minutes"))
        // setTrigger(!trigger)
      }
      
    })
    .catch((err) => {
      SnackbarUtils.error(JSON.stringify("Status has not changed, try again"))
    })

  }

  const dialogAction = (
    <Dialog
      onClose={() => handleCloseAction("reverseCalculation")}
      open={open.reverseCalculation}
      className="bpx-dialog-accstatus-bg"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.MODAL.reverse_ERP_Posting_and_Calculation")}</div>
          </div>
          <div 
            className="bpx-dialog-accstatus-close" 
            onClick={() => handleCloseAction("reverseCalculation")}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">

          <div className="bpx-dialog-accstatus-section">
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <p>{t("AccrualPostingList.MODAL.reversed_document_info")}</p>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.msg")}: </span>
                <span>{actionCal.MESSAGE_ID}</span>
              </div>
            </div>
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.ERP_document_number")}: </span>
                <span>{actionCal.SAP_DOC_NO}</span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.accrual_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.amount")}: </span>
                <span>{actionCal.AMOUNT} <b>{actionCal.CURRENCY}</b></span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.posting_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_POSTING, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>
          </div>

          <div className="bpx-dialog-accstatus-section">

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
              <p>{t("AccrualPostingList.MODAL.reversal_document_info")}</p>
              </div>
              <div className="bpx-dialog-accstatus-right"></div>
            </div>

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.amount")}: </span>
                <span>{(-1) * actionCal.AMOUNT} <b>{actionCal.CURRENCY}</b></span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.accrual_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left"></div>
              <div className="bpx-dialog-accstatus-right">
                <div className="bpx-dialog-accstatus-datepicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker
                      inputFormat={prefsData && prefsData.date_format}
                      value={dateValue.status_date || null}
                      required={true}
                      onChange={(value, typing) => onChangeDateFilter(value, typing, "status_date")}
                      onAccept={(value) => {
                        let convertDate = moment(new Date(value)).format("YYYY-MM-DD")
                        mappingState(null, "status_date", convertDate)
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params} 
                          size="small"
                          label={t("AccrualPostingList.MODAL.posting_date")}
                          variant="outlined" 
                          // error={dateValue ? false : true}
                          inputProps={
                            {
                              ...params.inputProps,
                              placeholder: prefsData && prefsData.date_format
                            }
                          }
                        />
                      }
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

          </div>

          <div className="bpx-dialog-accstatus-warning">
          {t("AccrualPostingList.MODAL.warning3")}
          </div>
          
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button 
            variant="outlined" 
            onClick={() => handleCloseAction("reverseCalculation")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button 
            variant="contained"
            onClick={() => handleOpenOption("reverseCalculationConfirm")}
          >
            {t("MODAL.BUTTONS.REVERSE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionOption = (
    <Dialog
      onClose={() => handleCloseActionOption("reverseCalculationConfirm")}
      open={open.reverseCalculationConfirm}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.Reverse_ERP_Posting_Calculation")}</div>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
          {t("AccrualPostingList.MODAL.are_you_sure")}<b> {t("AccrualPostingList.MODAL.Reverse_ERP_Doc")} <br /> {t("AccrualPostingList.MODAL.No")}</b>:
            <b>{actionCal.SAP_DOC_NO}</b> {t("AccrualPostingList.MODAL.for_the_amount_of")}
            <i> {(-1) * actionCal.AMOUNT}</i> <i>{actionCal.CURRENCY}</i>?
          </div>
          <div>
            {t("AccrualPostingList.MODAL.action_will_generate")}
          </div>
          <br />
          <div className="color-orange">
          {t("AccrualPostingList.MODAL.warning4")}    
          </div>
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button 
            variant="outlined" 
            onClick={() => handleCloseActionOption("reverseCalculationConfirm")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button 
            variant="contained"
            onClick={() => handleActionRequest("reverseCalculationConfirm")}
          >
            {t("MODAL.BUTTONS.REVERSE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionERP = (
    <Dialog
      onClose={() => handleCloseAction("reversePosting")}
      open={open.reversePosting}
      className="bpx-dialog-accstatus-bg"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.Reverse_ERP_Posting")}</div>
          </div>
          <div 
            className="bpx-dialog-accstatus-close"
            onClick={() => handleCloseAction("reversePosting")}
          >
            <CloseIcon />
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">

          <div className="bpx-dialog-accstatus-section">
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
              <p>{t("AccrualPostingList.MODAL.reversed_document_info")}</p>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.msg")}: </span>
                <span>{actionCal.MESSAGE_ID}</span>
              </div>
            </div>
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.ERP_document_number")}: </span>
                <span>{actionCal.SAP_DOC_NO}</span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.accrual_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>
            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.amount")}: </span>
                <span>{actionCal.AMOUNT} <b>{actionCal.CURRENCY}</b></span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.posting_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_POSTING, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>
          </div>

          <div className="bpx-dialog-accstatus-section">

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
              <p>{t("AccrualPostingList.MODAL.reversal_document_info")}</p>
              </div>
              <div className="bpx-dialog-accstatus-right"></div>
            </div>

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left">
                <span>{t("AccrualPostingList.MODAL.amount")}: </span>
                <span>{(-1) * actionCal.AMOUNT} <b>{actionCal.CURRENCY}</b></span>
              </div>
              <div className="bpx-dialog-accstatus-right">
                <span>{t("AccrualPostingList.MODAL.accrual_date")}: </span>
                <span>{dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</span>
              </div>
            </div>

            <div className="bpx-dialog-accstatus-inline">
              <div className="bpx-dialog-accstatus-left"></div>
              <div className="bpx-dialog-accstatus-right">
                <div className="bpx-dialog-accstatus-datepicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns} >
                    <DesktopDatePicker
                      inputFormat={prefsData && prefsData.date_format}
                      value={dateValue.status_date || null}
                      required={true}
                      onChange={(value, typing) => onChangeDateFilter(value, typing, "status_date")}
                      onAccept={(value) => {
                        let convertDate = moment(new Date(value)).format("YYYY-MM-DD")
                        mappingState(null, "status_date", convertDate)
                      }}
                      renderInput={(params) =>
                        <TextField
                          {...params} 
                          size="small"
                          label={t("AccrualPostingList.MODAL.posting_date")}
                          variant="outlined" 
                          // error={dateValue ? false : true}
                          inputProps={
                            {
                              ...params.inputProps,
                              placeholder: prefsData && prefsData.date_format
                            }
                          }
                        />
                      }
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>

          </div>

          <div className="bpx-dialog-accstatus-warning">
          {t("AccrualPostingList.MODAL.warning5")} ​<br />
          {t("AccrualPostingList.MODAL.sales_volumes_will")}
          </div>
          
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button 
            variant="outlined"
            onClick={() => handleCloseAction("reversePosting")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button 
            variant="contained"
            onClick={() => handleOpenOption("reversePostingConfirm")}
          >
            {t("MODAL.BUTTONS.REVERSE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionOptionERP = (
    <Dialog
      onClose={() => handleCloseActionOption("reversePostingConfirm")}
      open={open.reversePostingConfirm}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.Reverse_ERP_Posting")}</div>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
          {t("AccrualPostingList.MODAL.are_you_sure")} <b>{t("AccrualPostingList.MODAL.Reverse_ERP_Doc")}<br />  {t("AccrualPostingList.MODAL.No")}</b>:
            <b>{actionCal.SAP_DOC_NO}</b> {t("AccrualPostingList.MODAL.for_the_amount_of")} 
            <i> {(-1) * actionCal.AMOUNT}</i> <i>{actionCal.CURRENCY} </i>?
          </div>
          <div>
            {t("AccrualPostingList.MODAL.action_will_generate")} 
          </div>
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button 
            variant="outlined"
            onClick={() => handleCloseActionOption("reversePostingConfirm")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button 
            variant="contained"
            onClick={() => handleActionRequest("reversePostingConfirm")}
          >
            {t("MODAL.BUTTONS.REVERSE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionPendingERP = (
    <Dialog
      onClose={() => handleCloseAction("reverseCalculationPending")}
      open={open.reverseCalculationPending}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.MODAL.reverse_ERP_Posting_and_Calculation")}</div>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
          {t("AccrualPostingList.MODAL.are_you_sure")} <b>{t("AccrualPostingList.MODAL.reverse_calc")} </b>
          {t("AccrualPostingList.MODAL.for")} <b>{t("AccrualPostingList.MODAL.activity_id")}</b> {actionCal.ACTIVITY_ID}? <br />
          {t("AccrualPostingList.MODAL.accrual_posting_will_be_stopped")}
          </div>
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button 
            variant="outlined"
            onClick={() => handleCloseAction("reverseCalculationPending")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button 
            variant="contained"
            onClick={() => handleActionRequest("reverseCalculationPending")}
          >
            {t("MODAL.BUTTONS.EXCUTE")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

  const dialogActionAbortERP = (
    <Dialog
      onClose={() => handleCloseAction("abortPosting")}
      open={open.abortPosting}
      className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
    >
      <div className="bpx-dialog-accstatus-block">

        <div className="bpx-dialog-accstatus-header">
          <div className="bpx-dialog-accstatus-title">
            <div>{t("AccrualPostingList.MODAL.abort_ERP_posting")}</div>
          </div>
        </div>

        <div className="bpx-dialog-accstatus-content">
          <div className="bpx-dialog-accstatus-option-content">
          {t("AccrualPostingList.MODAL.are_you_sure")} <b> {t("AccrualPostingList.MODAL.Abort_ERP_Posting")} </b>
          {t("AccrualPostingList.MODAL.for")} <br/><b>{t("AccrualPostingList.MODAL.activity_id")}</b> {actionCal.ACTIVITY_ID}?
          </div>

          <div className="color-orange">
          {t("AccrualPostingList.MODAL.warning")} ​<br />
          {t("AccrualPostingList.MODAL.warning2")}
          </div>
        </div>

        <div className="bpx-dialog-accstatus-buttons">
          <Button 
            variant="outlined"
            onClick={() => handleCloseAction("abortPosting")}
          >
            {t("MODAL.BUTTONS.CANCEL")}
          </Button>
          <Button 
            variant="contained"
            onClick={() => handleActionRequest("abortPosting")}
          >
            {t("MODAL.BUTTONS.ABORT")}
          </Button>
        </div>

      </div>
    </Dialog>
  )

    const dialogActionSendERP = (
      <Dialog
        onClose={() => handleCloseAction("sendPosting")}
        open={open.sendPosting}
        className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
      >
        <div className="bpx-dialog-accstatus-block">

          <div className="bpx-dialog-accstatus-header">
            <div className="bpx-dialog-accstatus-title">
              <div>{t("AccrualPostingList.MODAL.Send_Posting_to_ERP")}</div>
            </div>
          </div>

          <div className="bpx-dialog-accstatus-content">
            <div className="bpx-dialog-accstatus-option-content">
            {t("AccrualPostingList.MODAL.are_you_sure")} <b>{t("AccrualPostingList.MODAL.send")}</b>
              {/* for <br/><b>Activity ID</b> {actionCal.ACTIVITY_ID}? */}
            </div>

            <div className="bpx-dialog-accstatus-blockline"> 
              <div className="bpx-dialog-accstatus-sectionline">
                <div className="bpx-dialog-accstatus-leftline">
                {t("AccrualPostingList.MODAL.act_id")}: {actionCal.ACTIVITY_ID}
                </div>
                <div className="bpx-dialog-accstatus-rightline">
                {t("AccrualPostingList.MODAL.accrual_date")}: {dateTimeFormatter(actionCal.DATE_ACCRUAL, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}
                </div>
              </div>

              <div className="bpx-dialog-accstatus-sectionline">
                <div className="bpx-dialog-accstatus-leftline">
                {t("AccrualPostingList.MODAL.msg")}: {actionCal.MESSAGE_ID} 
                </div>
                <div className="bpx-dialog-accstatus-rightline">

                    <div className="bpx-dialog-accstatus-datepicker">
                      <LocalizationProvider dateAdapter={AdapterDateFns} >
                        <DesktopDatePicker
                          inputFormat={prefsData && prefsData.date_format}
                          value={dateValue.status_date || null}
                          required={true}
                          onChange={(value, typing) => onChangeDateFilter(value, typing, "status_date")}
                          onAccept={(value) => {
                            let convertDate = moment(new Date(value)).format("YYYY-MM-DD")
                            mappingState(null, "status_date", convertDate)
                          }}
                          renderInput={(params) =>
                            <TextField
                              {...params} 
                              size="small"
                              label= {t("AccrualPostingList.MODAL.posting_date")}
                              variant="outlined" 
                              // error={dateValue ? false : true}
                              inputProps={
                                {
                                  ...params.inputProps,
                                  placeholder: prefsData && prefsData.date_format
                                }
                              }
                            />
                          }
                        />
                      </LocalizationProvider>
                    </div>

                </div>
              </div>
            </div>

          </div>

          <div className="bpx-dialog-accstatus-buttons">
            <Button 
              variant="outlined"
              onClick={() => handleCloseAction("sendPosting")}
            >
              {t("MODAL.BUTTONS.CANCEL")}
            </Button>
            <Button 
              variant="contained"
              onClick={() => handleActionRequest("sendPosting")}
            >
              {t("MODAL.BUTTONS.SEND")}
            </Button>
          </div>

        </div>
      </Dialog>
    )


    const dialogActionPrevent = (
      <Dialog
        onClose={() => setOpenPreventDialog(false)}
        open={openPreventDialog}
        className="bpx-dialog-accstatus-bg bpx-dialog-accstatus-option"
      >
        <div className="bpx-dialog-accstatus-block">

          <div className="bpx-dialog-accstatus-header">
            <div className="bpx-dialog-accstatus-title">
              <div>Status information</div>
            </div>
            <div className="bpx-dialog-accstatus-close" onClick={() => setOpenPreventDialog(false)}>
              <CloseIcon />
            </div>
          </div>

          <div className="bpx-dialog-accstatus-content">
            <div className="bpx-dialog-accstatus-option-content">
            {t("AccrualPostingList.MODAL.please_wait")} <b>"{preventStatus.inProgress}"</b> {t("AccrualPostingList.MODAL.status_is_currently") } <b> "{preventStatus.statusText}", </b> 
            </div>
          </div>

          <div className="bpx-dialog-accstatus-buttons">
            <Button variant="outlined" onClick={() => setOpenPreventDialog(false)}>
            {t("MODAL.BUTTONS.CANCEL")}
            </Button>
          </div>

        </div>
      </Dialog>
    )
  
    const onClickERPStatuses = ()=>{
      navigate(`/accrual-balance/accrual_posting/erp_statuses/${paramsHook.id}`)
    }

  const onClickReverseERPPostingCalculation = ()=>{
  
    const {STATUS, REVERSED} = rows[0]
    if (STATUS === "Success" && REVERSED === false) {
      preventStatus.action 
              ? handlePreventDialog() 
              : handleAction(rows[0], STATUS, "Reversed", "cancel", "reverseCalculation")
    }else if (STATUS === "Pending" || STATUS === "Error"){
      preventStatus.action 
              ? handlePreventDialog() 
              : handleAction(rows[0], STATUS, "Reversed", "cancel", "reverseCalculationPending")
    }
  }

  const onClickReverseERPPosting = ()=>{
    preventStatus.action 
    ? handlePreventDialog() 
    : handleAction(rows[0], rows[0].STATUS, "Reversed", "reverse", "reversePosting")
  }

  const onClickAbortERPPosting = ()=>{
    preventStatus.action 
    ? handlePreventDialog()
    : handleAction(rows[0], rows[0].STATUS, "Aborted", "abort", "abortPosting")
  }

  const onClickSendPostingtoERP = ()=>{
    preventStatus.action 
    ? handlePreventDialog()
    : handleAction(rows[0], rows[0].STATUS, "Sended", "send", "sendPosting")
  }

  return (
    <div className="tableContainer">
      <div className="block-lines">

        <div>
          <DataGridPro
            sx={{
              '& .MuiDataGrid-row': {
                height: `${checkSize(fontSize, 'row_height')}px !important`,
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              },
              "& .MuiDataGrid-main" : {
                height: Math.min((rows.length||1) * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height'), 3 * checkSize(fontSize, 'row_height') + checkSize(fontSize, 'header_height')) + 'px',
                fontSize: checkSize(fontSize, 'font_size')
              },
              "& .MuiCheckbox-root": {
                transform: `scale(${checkSize(fontSize, 'scale')})`
              }
            }}
            rows={rows}
            columns={columns}
            disableSelectionOnClick={true}
            disableColumnFilter
            hideFooter={true}
            getRowHeight={() => 'auto'}
            headerHeight={checkSize(fontSize, 'header_height')}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              toolbar: {
                bread: objBreadcrumb,
                title: t("AccrualPostingDetail.title1"),
                isFontSize: true,
                fontSize: fontSize,
                onChangeFontSize: onChangeFontSize,
                extraButton: [
                  {
                    headerName: t("AccrualPostingList.ERP_Statuses"),
                    icon: <ListIcon sx={{ fontSize: "1rem"}}/>,
                    onClick: onClickERPStatuses,
                    extraClass: 'toolbarGridOrange',
                  },
                  {
                    headerName: t("AccrualPostingList.Reverse_ERP_Posting_Calculation"),
                    icon: <Rotate90DegreesCcwIcon sx={{ fontSize: "1rem"}}/>,
                    onClick: onClickReverseERPPostingCalculation,
                    extraClass: 'toolbarGridOrange',
                    isHide: !rows.length||!((rows[0].STATUS === "Success" && rows[0].REVERSED === false)||rows[0].STATUS === "Pending" || rows[0].STATUS === "Error")||rows[0].TYPE==='Accrual Adjustment'
                  },
                  {
                    headerName: t("AccrualPostingList.Reverse_ERP_Posting"),
                    icon: <Rotate90DegreesCcwIcon sx={{ fontSize: "1rem"}}/>,
                    onClick: onClickReverseERPPosting,
                    extraClass: 'toolbarGridOrange',
                    isHide: !rows.length||!((rows[0].STATUS === "Success" && rows[0].REVERSED === false))||rows[0].TYPE==='Accrual Adjustment'
                  },
                  {
                    headerName: t("AccrualPostingList.Abort_ERP_Posting"),
                    icon: <CloseIcon sx={{ fontSize: "1rem"}}/>,
                    onClick: onClickAbortERPPosting,
                    extraClass: 'toolbarGridRed',
                    isHide: !rows.length||!(rows[0].STATUS === "Pending" || rows[0].STATUS === "Error")
                  },
                  {
                    headerName: t("AccrualPostingList.Send_Posting_to_ERP"),
                    icon: <ArrowForwardIcon sx={{ fontSize: "1rem"}}/>,
                    onClick: onClickSendPostingtoERP,
                    isHide: !rows.length||!(rows[0].STATUS === "Pending" || rows[0].STATUS === "Error")
                  },
                ]
              }
            }}
          />
        </div>

        <div className="block-lines-bottom">
          <h2 className="toolbarTitle">{t("AccrualPostingDetail.title2")}</h2>
          <DataGridPro
            sx={{ 
              '& .MuiDataGrid-row': {
                minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
                height: 'auto !important'
              },
              '& .MuiDataGrid-cell': {
                whiteSpace: 'nowrap',
                overflow: 'hidden'
              },
              '& .MuiDataGrid-main':{
                fontSize: checkSize(fontSize, 'font_size')
              }      
            }} 
            rows={lines}
            columns={linesColumn}
            disableSelectionOnClick={true}
            disableColumnFilter={true}
            hideFooter={true}
            getRowHeight={() => 'auto'}
            headerHeight={checkSize(fontSize, 'header_height')}
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </div>

      </div>
      { open.reverseCalculation && dialogAction }
      { open.reverseCalculationConfirm && dialogActionOption }
      { open.reversePosting && dialogActionERP }
      { open.reversePostingConfirm && dialogActionOptionERP }
      { open.reverseCalculationPending && dialogActionPendingERP }
      { open.abortPosting && dialogActionAbortERP }
      { open.sendPosting && dialogActionSendERP }
      { openPreventDialog && dialogActionPrevent }

      
    </div>
  )
}
