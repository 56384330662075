import React, { useState } from 'react'
import { Dialog } from '@mui/material'


import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next'

function HelpComponent () {

  const [open, setOpen] = useState(false)

  const { t } = useTranslation()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <HelpOutlineOutlinedIcon 
        sx={{ mr: 1, color: 'rgb(25, 118, 210)', cursor: 'pointer' }} 
        onClick={handleClickOpen}
      />
 
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='bg-help'
      >
        <div className='block-help'>
          <div className='block-header-help'>
            <span>{t("helpPopup.hello")}</span> 
            {t("helpPopup.how_can")}
            <div className="close-for-modal-window">
              <IconButton onClick={handleClose} className="wrap-cancel-modal">
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          
          <div className='block-middle-help'>
            <p>{t("helpPopup.welcome")}</p>
            <p>{t("helpPopup.zoho0")}<b>{t("helpPopup.zoho1")}</b>{t("helpPopup.zoho2")}<b>{t("helpPopup.zoho3")}</b>{t("helpPopup.zoho4")}<a href="mailto: ae.support@bpx.pl">ae.support@bpx.pl</a>.</p>
            <p>{t("helpPopup.please")}<b>{t("helpPopup.monday")}</b>{t("helpPopup.to")}<b>{t("helpPopup.friday")}</b>, <br />{t("helpPopup.from")}<b>{t("helpPopup.9am")}</b>{t("helpPopup.to")}<b>{t("helpPopup.5pm")}</b>{t("helpPopup.cet")}</p>
          </div>
          
          <div className='block-bottom-help'>
              <a href='https://bpxaccrualengine.zohodesk.eu/portal/en/newticket' target="_blank" className='section-help'>
                  <div className='section-inner-help'>
                    <div className='section-icon-help'>
                      <ErrorOutlineIcon />
                    </div>
                    <div className='section-text-help'>
                    {t("helpPopup.rise1")}<br/>{t("helpPopup.rise2")}
                    </div>
                  </div>
              </a>

               <a href='https://team.effem.com/sites/VisualfabriqPolandPetcare/_layouts/15/stream.aspx?id=%2Fsites%2FVisualfabriqPolandPetcare%2FShared%20Documents%2FProcedury%20i%20szkolenia%2FAccrual%20Engine%2FKAMaccrualengine%2Emp4&referrer=StreamWebApp%2EWeb&referrerScenario=AddressBarCopied%2Eview' target="_blank" className='section-help'>
                  <div className='section-inner-help'>
                    <div className='section-icon-help'>
                      <PlayCircleIcon />
                    </div>
                    <div className='section-text-help'>
                      Check TreXcellence introduction videos
                    </div>
                  </div>
              </a>

               <a href='https://bpxaccrualengine.zohodesk.eu/portal/en/myarea' target="_blank" className='section-help'>
                  <div className='section-inner-help'>
                    <div className='section-icon-help'>
                      <VisibilityIcon />
                    </div>
                    <div className='section-text-help'>
                    {t("helpPopup.ticket")}
                    </div>
                  </div>
              </a>

              <a href='https://bpxaccrualengine.zohodesk.eu/portal/en/kb/tickettips' target="_blank" className='section-help'>
                  <div className='section-inner-help'>
                    <div className='section-icon-help'>
                      <AutoStoriesIcon />
                    </div>
                    <div className='section-text-help'>
                    {t("helpPopup.knowledge")}
                    </div>
                  </div>
              </a>

          </div>
        </div>
      </Dialog>
    </>
  )

}

export default HelpComponent
