import React, {useState } from 'react'
import { useTranslation } from 'react-i18next'
import { backend_url } from '../../../../settings'

import CRUDTable from '../../Components/CRUDTable'
import List from '../../Components/List'
import { useParams } from 'react-router-dom'
import { dateTimeFormatter } from "../../../../utils/utils"
import { useUserPrefs } from "../../../UserPreferences/ProvideUserPrefs";
import { CustomToolbar } from '../../../../components'

function UserDetail () {

  const { t } = useTranslation()
  const params = useParams();
  const { prefsData } = useUserPrefs()

  const [table, setTable] = useState([
    {
      id: "1",
      title: "Manage User",
      url: backend_url.model_userRoles,
      action: false,
      disabled: false,
      filter: [],
      endpointForPut:[ 'DATE_VALID_TO','DIMENSION_ID','ROLE_ID','USER_ID','SEQUENCE'],
      columns: [
        { 
          field: "ROLE_ID", 
          headerName: t("UserDetail.ROLE"), 
          flex: 1, 
          editable: false 
        },
        { 
          field: "DATE_VALID_FROM", 
          headerName: t("UserDetail.DATE_VALID_FROM"), 
          flex: 1, 
          renderCell: ({ value }) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
          editable: false 
        },
        { 
          field: "DATE_VALID_TO", 
          headerName: t("UserDetail.DATE_VALID_TO"), 
          flex: 1, 
          renderCell: ({ value }) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
          editable: false 
        },
        { 
          field: "BUSINESS_UNIT", 
          headerName: t("UserDetail.BUSINESS_UNIT"), 
          flex: 1, 
          editable: false 
        },
        { 
          field: "BUSINESS_PARTNER", 
          headerName: t("UserDetail.BUSINESS_PARTNER"), 
          flex: 1, 
          editable: false,
        },
        { 
          field: "CUSTOMER_HIERARCHY", 
          joinTable: 'BUSINESS_PARTNER',
          headerName:  t("UserDetail.CUSTOMER_HIERARCHY"), 
          flex: 1, 
          editable: false
        },
      ],
      breadcrumb: [
        { label: t("Breadcrumb.home"), link: "/" },
        { label: t("Breadcrumb.authorization"), link: "" },
        { label: t("Breadcrumb.manage_user"), link: "/authorization/manage-user" },
        { label: params.id, link: `` },
      ],
      Toolbar: (props) => <CustomToolbar {...props}  />,
      Component: (props) => <List {...props} />
    }
  ])

  const displayPage = () => {
    return table.map((item, index) => {

      const { 
        title, url, action, disabled, filter, columns, breadcrumb, Toolbar, Component,endpointForPut
      } = item

      return (
        <CRUDTable 
          endpointForPut={endpointForPut}
          userId = {params.id}
          key={index}
          title={title}
          url={url}
          action={action}
          disabled={disabled}
          initialFilter={filter}
          initialColumns={columns}
          breadcrumb={breadcrumb}
          Toolbar={Toolbar}
          Component={Component}
        />
      )
    })
  }

  return(
    <>
      { displayPage() }    
    </>)
}

export default UserDetail
