import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { LoadingWithText } from '../../../components'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
export default function ModalRequest({ open, onClose, filters, text }) {
  const { t } = useTranslation('translation')

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    // sx={{ '& .MuiDialog-paper': { maxWidth: '858px' } }}
    >
      <DialogContent sx={{ paddingTop: 0 }} >
        <div className="close-for-modal-window position-smaller">
          <IconButton onClick={onClose} className="wrap-cancel-modal">
            <CloseIcon />
          </IconButton>
        </div>
        <LoadingWithText text={text} size={50} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={onClose}
        >
          {t("MODAL.BUTTONS.CLOSE")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}