import React, { useState, useEffect } from 'react';
import { CustomToolbarHome } from '../../../../components'
import { useTranslation } from 'react-i18next'
import { DropDown } from '../index'
import Chart from "react-apexcharts"
import { backend_url } from "../../../../settings"
import { useFetch } from "../../../../hooks"
import { getParam } from '../../../../utils/utils';
import SnackbarUtils from '../../../../utils/SnackbarUtils'
import { useUserPrefs } from '../../../UserPreferences/ProvideUserPrefs';


const wordToHex = {
    green: "#00a651",
    red: "#ff0000",
    blue: "#1976d2",
    orange: "#f66d38",
    Green: "#00a651",
    Red: "#ff0000",
    Blue: "#1976d2",
    Orange: "#f66d38"
};

export const PlanAccountTotal = ({ onSetDragActive, toggle, setToggle }) => {

    const { t } = useTranslation('translation')
    const value_bu = localStorage.getItem("bu_bpx")
    const tokenBpxRequest = localStorage.getItem("token_bpx")
    const [config, setConfig] = useState({})
    const [chartsData, setChartsData] = useState({})
    const [periods, setPeriods] = useState()
    const [currency, setCurrency] = useState("PLN")
    const { prefsData, silentUpdateUserPrefs, saveUserPrefsWithoutRefresh } = useUserPrefs()
    const [stateDropDown, setStateDropDown] = useState(prefsData ? {
        "CALCULATION": prefsData.PAT_charts ? prefsData.PAT_charts : [],
        "PLAN_ACCOUNT": (prefsData.PAT_choices && prefsData.PAT_choices.PLAN_ACCOUNT) ? prefsData.PAT_choices.PLAN_ACCOUNT : "", 
        "PERIOD_TYPE": (prefsData.PAT_choices && prefsData.PAT_choices.PERIOD_TYPE) ? prefsData.PAT_choices.PERIOD_TYPE : ""
    } : {})
    

    const request = useFetch()

    useEffect(() => {
        var queryParams = {
            business_unit: value_bu,
            REPORT_CODE: "PAT"
        }
        var queryString = getParam(queryParams).toString()

        request.fetchData(
            `${backend_url.config_report_parameters}?${queryString}`,
            "GET",
            null,
            false,
            tokenBpxRequest,
            false
        )
        .then(data => {
            data.map((param) => {
                setConfig((prevState) => ({
                    [param.PARAMETER_NAME]: [param.PARAMETER_VALUE],
                    ...prevState
                }))
            })
        })
    }, [])

    useEffect(() => {

        if ("PLAN_ACCOUNT" in stateDropDown && "PERIOD_TYPE" in stateDropDown && "CALCULATION" in stateDropDown && config && Object.keys(config).length !== 0) {
            stateDropDown.CALCULATION.map((cal) => {
                var queryParams = {}
                var url = ""
                var param = ""
                if (cal === "ACCRUALS") {
                    queryParams = {
                        business_unit: value_bu,
                        PLAN_ACCOUNT: stateDropDown.PLAN_ACCOUNT.replace(/\s+/g,'').split('-')[0],
                        BP_ROLE: stateDropDown.PLAN_ACCOUNT.replace(/\s+/g,'').split('-')[1],
                        TYPE_OF_DATE: stateDropDown.PERIOD_TYPE,
                        CAL_ID: config && config["Calendar Code"],
                        YEAR_T0_DATE_CONFIG: config && config["Timeline Code"],
                        AO_STATUS: config && config["Object Status 2"][0].split(',')
                    }
                    url = backend_url.plan_account_total_accruals
                } else if (cal === "GSV") {
                      queryParams = {
                          business_unit: value_bu,
                          PLAN_ACCOUNT: stateDropDown.PLAN_ACCOUNT.replace(/\s+/g,'').split('-')[0],
                          BP_ROLE: stateDropDown.PLAN_ACCOUNT.replace(/\s+/g,'').split('-')[1],
                          TYPE_OF_DATE: stateDropDown.PERIOD_TYPE,
                          CAL_ID: config && config["Calendar Code"],
                          YEAR_T0_DATE_CONFIG: config && config["Timeline Code"]
                      }
                      url = backend_url.plan_account_subtotal
                      param = `/PL_GSV`
                } else if (cal === "NIV") {
                      queryParams = {
                          business_unit: value_bu,
                          PLAN_ACCOUNT: stateDropDown.PLAN_ACCOUNT.replace(/\s+/g,'').split('-')[0],
                          BP_ROLE: stateDropDown.PLAN_ACCOUNT.replace(/\s+/g,'').split('-')[1],
                          TYPE_OF_DATE: stateDropDown.PERIOD_TYPE,
                          CAL_ID: config && config["Calendar Code"],
                          YEAR_T0_DATE_CONFIG: config && config["Timeline Code"]
                      }
                      url = backend_url.plan_account_subtotal
                      param = `/PL_NIV`
                } else if (cal === "PAYMENTS") {
                        queryParams = {
                            business_unit: value_bu,
                            PLAN_ACCOUNT: stateDropDown.PLAN_ACCOUNT.replace(/\s+/g,'').split('-')[0],
                            BP_ROLE: stateDropDown.PLAN_ACCOUNT.replace(/\s+/g,'').split('-')[1],
                            TYPE_OF_DATE: stateDropDown.PERIOD_TYPE,
                            CAL_ID: config && config["Calendar Code"],
                            YEAR_T0_DATE_CONFIG: config && config["Timeline Code"],
                            PAYMENT_STATUS: config && config["Object Status 3"][0].split(',')
                        }
                        url = backend_url.plan_account_confirmed_payments
                }

                var queryString = getParam(queryParams).toString()

                request.fetchData(
                    `${url}${param}?${queryString}`,
                    "GET",
                    null,
                    false,
                    tokenBpxRequest,
                    false
                )
                .then(data => {
                    var periods = []
                    setChartsData((prevState) => {
                        var amounts = []
                        var currency
                        const filtered = Object.keys(prevState)
                            .filter(key => stateDropDown.CALCULATION.includes(key))
                            .reduce((obj, key) => {
                                obj[key] = prevState[key];
                                return obj;
                        }, {});
                        data.map((el) => {
                            amounts.push(el.AMOUNT)
                            currency = el.CURRENCY
                            if (stateDropDown.PERIOD_TYPE === 'Y')
                                periods.push(el.PERIOD)
                            else
                                periods.push(el.DATE)
                        })
                        return {...filtered, [cal]: {AMOUNTS: amounts, PERIODS: periods}}
                    
                    })
                    setCurrency(currency)
                    setPeriods(periods)
                })
                .catch((e) => {
                }) 
            })
        }
    }, [stateDropDown, config])

    const handleDropDown = (name, data) => {
        setChartsData({})
        if (prefsData) {
            if (name === "CALCULATION") {
                saveUserPrefsWithoutRefresh({
                    ...prefsData,
                    PAT_charts: data
                })   
            } else {
                saveUserPrefsWithoutRefresh({
                    ...prefsData,
                    PAT_choices: {
                        ...prefsData.PAT_choices,
                        [name]: data
                    }
                })  
            }
        }
        setStateDropDown(st=>({...st, [name]: data }))
    }

    const handleCloseSelectCharts = () => {
        silentUpdateUserPrefs()
    }

    const getTypeAndColor = (config, color=false) => {
        let extractedProps = config[0].replace(/\s+/g,'').slice(6).split(',')
        if (color)
            return wordToHex[extractedProps[1]]
        return extractedProps[0]
    }

    const getSubTotalName = (subTName) => {
        var name = ""
        Object.entries(config).map(([k, v]) => {
            if (v[0].includes(subTName))
                name = k.slice(0,6)
        })
        return name
    }

    const formatAmounts = (amount) => {
        let formattedAmount = Math.abs(amount) > 999 ? Math.sign(amount)*((Math.abs(amount)/1000).toFixed(1)) + ' k' : Math.sign(amount)*Math.abs(amount)
        var parts = formattedAmount.toString().split(".")
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        return parts.join(".")
    }

    const names = {
        GSV: config && `${getSubTotalName("GSV")} Parameters`, 
        TRADE: "Total-Expenditures Parameters", 
        ACCRUALS: "Accruals Parameters", 
        NIV: config && `${getSubTotalName("NIV")} Parameters`, 
        PAYMENTS: "Payments Parameters"
    }

    var options =  {
        chart: {
            id: "plan-account-charts",
            toolbar: {
                show: false,
            }
        },
        stroke: {
            show: true,
            curve: 'smooth',
            lineCap: 'butt',
            width: 1.5,
        },
        tooltip: {
            theme: "dark",
        },
        xaxis: {
          categories: periods ? periods : [],
          labels: {
            show: true,
            align: 'right',
            minWidth: 0,
            maxWidth: 160,
            offsetX: 0,
            offsetY: 0,
            rotate: -45,
            formatter: (value) => {
                if (periods) {
                    if (value !== 'undefined' && value !== null) {
                        if (typeof value === "string") {
                            if (value.includes('_')) {
                                return value.split('_')[0]
                            }
                            return value
                        }        
                    } 
                }             
                return periods && value
            },
        },
        },
        yaxis: {
            labels: {
                show: true,
                align: 'right',
                formatter: (value) => { return (value && value !== 'undefined' && value !== null) ? `${formatAmounts(value)} ${currency && currency}`: `0 ${currency}`},
            },
        },
    }

    var series = (config && chartsData) ? Object.entries(chartsData).map(([k, v]) => {
        return {name: (k !== "ACCRUALS" && k !== "PAYMENTS" && k !== "TRADE") ? config[`${getSubTotalName(k)} Name`][0] : k.toLowerCase().charAt(0).toUpperCase() + k.toLowerCase().slice(1), 
                type: getTypeAndColor(config[names[k]]), 
                data: v.AMOUNTS, 
                color: getTypeAndColor(config[names[k]], true),
            }
    }) : []

    return (      
      <div>
        <CustomToolbarHome 
          title={t("Home.Highlights.plan_account_totals")}
          isResize={false} 
          current="PlanAccountTotal"
          onSetDragActive={onSetDragActive} 
          toggle={toggle}
          setToggle={setToggle}
        /> 
        <div className='bpx-chart-plan-account-container'>  
            <div className="bpx-chart-plan-account">
                <Chart
                    options={options}
                    series={series}
                    height="320"
                /> 
            </div>
            <div className="bpx-chart-block">
                <DropDown handleDropDown={handleDropDown} state={stateDropDown} handleClose={handleCloseSelectCharts}/>
            </div>
        </div>
      </div>
  )

}
