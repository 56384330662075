import LinearProgress from '@mui/material/LinearProgress'
import { Box, Typography } from '@mui/material'


export default function NumberLinearProgress(props) {

  var prop_color = "error"
  if (props.value >= 0 && props.value <= 33) {
    prop_color = "success"
  } 
  else if (props.value > 33 && props.value <= 66) {
    prop_color = "warning"
  }

  return (
    <Box position="relative" display="inline-block"
      sx={{
        width: 100, height: 17
      }}
    >
      <Box top={0} left={0} bottom={0} right={0} position="absolute" >
        <LinearProgress variant="determinate" color={prop_color} value={parseInt(props.value, 10)}

          sx={{
            borderRadius: 0,
            padding: '8px 30px 8px 30px',  
          }}
        />
      </Box>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography sx={{ textAlign: "center", fontSize: 10 }} color={prop_color === "error"?"#fff":"textPrimary"}>{`${
          props.value
        }%`}</Typography>
      </Box>
    </Box>
  )
}