import React from 'react'
import { Breadcrumbs, Typography } from '@mui/material'
import { Link } from "react-router-dom"

export default function LoggedIn({ breadcrumbs }) {

  return (
    <div className='toolbarSection'>
      <Breadcrumbs separator="|" className='breadCrumbsBlock' aria-label="breadcrumb" sx={{mb: 2}}>
          {
            breadcrumbs.map((item, idx) => {

              if (item) {
                const { label, link } = item

                if (link === '') {
                  return <Typography color="text.primary" key={idx}>{label}</Typography>
                } else {
                  return (
                    <Link to={link} key={idx}>
                      {label}
                    </Link>
                  )
                }
                
              }

            })
          }
      </Breadcrumbs>
    </div>
  )
  
}
