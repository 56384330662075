import React, { useState, useEffect } from 'react'
import { DataGridPro, GridActionsCellItem, GridOverlay } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox';
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { getParam, checkObjectKey } from "../../../utils/utils"
import SnackbarUtils from '../../../utils/SnackbarUtils'
import { useFetch, usePermissions } from '../../../hooks'
import { ModalAddData, ModalDelete, ModalAddDataHighlight } from '../../../components'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { backend_url } from '../../../settings'
import { useTranslation } from 'react-i18next'


function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}

function ConfigTableHighlight({ table, configuration, onHandleClick, business_unit }) {

  const {
    url, endpoint, titleTable, idReplace, selected, select, endpointForParametr,
    columnsTable, notistack, columnSingleSelect, configurationDepend, value, endpointForGet, endpointForPost, fields, sections,
  } = table

  let randomId = () => Math.random() * 1000
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()

  const { t } = useTranslation('translation')

  const { obj, childObj } = usePermissions()
  const checkConfiguration = checkObjectKey(obj, childObj, 'Configuration_Change');

  const [rows, setRows] = useState([]);
  const [filterRows, setFilterRows] = useState([])
  const [state, setState] = useState([]);
  const [modal, showModal] = useState({ 'warning': false, 'highlight': false });
  const [editTable, setEditTable] = useState(null);
  const [deleteRow, setDeleteRow] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);

  const [parameters, setParameters] = useState(fields)

  const filterData = (data) => {
    if (configurationDepend.length) {
      data = data.filter(it => {
        return configuration[configurationDepend] === it[configurationDepend[0]]
      })
    }
    return data;
  }

  useEffect(() => {
    setFilterRows(filterData(rows));
  }, [rows])

  const getUrl = (skip, endpoint = endpointForGet, data) => {
    let param = {
      //limit: 100,
      skip
    };

    if (endpoint) {
      endpoint.forEach(it => {
        if (localStorage.getItem(it.name)) {
          param[it.line] = localStorage.getItem(it.name);
        } else if (it.value) {
          param[it.line] = it.value
        } else if (data[it.name]) {
          param[it.line] = data[it.name]
        }
      })
    }

    if (configurationDepend) {
      configurationDepend.forEach(it => {
        if (it.field) {
          if (configuration[it.field]) {
            param[it.name] = configuration[it.field];
          }
        } else if (configuration[it]) {
          param[it] = configuration[it];
        }
      })
    }

    const queryString = getParam(param).toString();
    return '?' + queryString;
  }

  useEffect(() => {
    setLoading(true)
    setPage(0)
    request.fetchData(
      url + getUrl(0),
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      notistack
    )
      .then(data => {
        if (data.length) {
          const uniqueMap = new Map();
          data.forEach(obj => {
            uniqueMap.set(obj.REPORT_CODE, obj);
          });
          const uniqueArray = Array.from(uniqueMap.values());
          setRows(() =>
            uniqueArray.map(d => ({
              id: !idReplace ? randomId() : d.id,
              ...d
            }))
          )
        } else {
          setRows([])
          setLoading(false)
        }

        setCount(data.length);
        setLoading(false)
      })
      .catch(() => {
        setRows([])
        setLoading(false)
      })
  }, [configuration, business_unit])

  useEffect(() => {
    setLoading(true)
    if (page > 0) {
      request.fetchData(
        url + getUrl(page),
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        notistack
      )
        .then(data => {
          setRows((old) => [...old,
          ...data.map(d => ({
            id: idReplace ? idReplace : randomId(),
            ...d
          }))
          ])
          setCount(data.length);
          setLoading(false)
        })
        .catch(() => setLoading(false))
    }
  }, [page])

  useEffect(() => {
    if (columnSingleSelect) {
      columnSingleSelect.map(ww => {
        const { url, code, field, endpoint } = ww
        request.fetchData(
          url + getUrl(0, endpoint),
          'GET',
          null,
          false,
          tokenBpxRequest,
          false,
          false
        )
          .then(data => {
            let valueArray = data.map(tt => tt[code])
            let unique = valueArray.filter((v, i, a) => a.indexOf(v) === i)
            setState(prev => {
              return { ...prev, [field]: unique }
            })

            setParameters(prevParameters => {
              return prevParameters.map(it => {
                if (it.field === field) {
                  it.valueOptions = unique
                }
                return it
              })

            })


          })
        return ww
      })
    }

  }, [])

  const singleSelectArray = (arr, obj) => {
    return arr.map(val => {
      const { field } = val
      let condition = Object.keys(obj).find(key => key === field)

      if (condition) {
        val.valueOptions = obj[field]
      }
      return val
    })
  }

  const getUrlArray = (row) => {
    const getUrl = endpoint.filter(tt => tt.field).map(tt => tt.field);
    const param = {};
    let getUrlLine = endpoint.filter(tt => tt.line).map(tt => {
      if (localStorage.getItem(it.name)) {
        param[it.field] = localStorage.getItem(it.name);
      }
      return tt.line
    });

    getUrlLine += endpoint.filter(it => it.value).map((it) => {
      param[it.name] = row[it.value];
      return it
    })

    const filteredUrl = getUrl.map(it => row[it]);

    let convertUrl = Object.values(filteredUrl).join('/');

    if (getUrlLine.length) {
      convertUrl = convertUrl + '?' + getParam(param).toString();
    }
    return convertUrl
  }

  const excludeProperty = (row, exclude) => {
    return Object.keys(row)
      .filter(key => !exclude.includes(key))
      .reduce((obj, key) => Object.assign(obj, { [key]: row[key] }), {})
  }

  const handleClick = (open = true) => {
    showModal({ ...modal, highlight: open });
  }

  const handleEditClick = (id) => () => {
    const row = rows.find(it => it.id === id);

    if (row) {
      setLoadingForm(true);
      showModal({ ...modal, highlight: true });
      fetchParam(backend_url.config_report_parameters, row)
        .then(data => {
          if (data.length) {
            const obj = {
              BUSINESS_UNIT: data[0].BUSINESS_UNIT,
              REPORT_CODE: data[0].REPORT_CODE,
              id: row.id
            };

            data.map(it => {
              obj[it.PARAMETER_NAME] = it.PARAMETER_VALUE
            })
            setEditTable(obj);
            setLoadingForm(false)
            //handleClick(true);
            return
          }
        })
        .catch(() => setLoadingForm(false))


    }
  }

  const fetchParam = (url, data) => {
    return request.fetchData(
      url + getUrl(0, endpointForParametr, data),
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
  }

  const fetchDelete = (url, data) => {
    return request.fetchData(
      url + '/' + getUrlArray(data),
      "DELETE",
      null,
      false,
      tokenBpxRequest,
      false,
      notistack
    )
  }

  const fetchPost = (url, data) => {
    return request.fetchData(
      url + getUrlForPost(data[0]),
      "POST",
      JSON.stringify(data),
      false,
      tokenBpxRequest,
      false,
      notistack
    )
  }

  const fetchPut = (url, oldData, newData) => {
    const body = [excludeProperty(newData, ['id', 'isNew'])];
    return request.fetchData(
      url + '/' + getUrlArray(oldData),
      "PUT",
      JSON.stringify(body[0]),
      false,
      tokenBpxRequest,
      false,
      notistack
    )
  }


  const deleteElement = () => {
    const { row, id } = deleteRow;
    setLoadingForm(true)

    fetchParam(backend_url.config_report_parameters, row)
      .then((data) => {
        const promises = [];
        data.map(it => {
          promises.push(fetchDelete(backend_url.config_report_parameters, it))
        })
        Promise.all(promises)
          .then(() => {
            setDeleteRow({});
            setRows(rows.filter((row) => row.id !== id));
            showModal({ ...modal, warning: false });
            setLoadingForm(false)
            SnackbarUtils.success(JSON.stringify('Successfully fetched'))
          })
          .catch(() => {
            setDeleteRow({});
            showModal({ ...modal, warning: false });
            setLoadingForm(false);
            SnackbarUtils.error(JSON.stringify('Server error'))
          })
      })
    return

  }

  const handleDeleteClick = (id, row) => () => {
    showModal({ ...modal, warning: true })
    console.log(id, row)
    setDeleteRow({ id, row })
  }

  const getUrlForPost = (newRow) => {
    let param = {};

    if (endpointForPost) {
      endpointForPost.forEach(it => {
        if (localStorage.getItem(it.name)) {
          param[it.field] = localStorage.getItem(it.name);
        }
      })
    }


    const queryString = getParam(param).toString();
    return queryString ? '?' + queryString : '';
  }

  const processRowUpdate = (newRow) => {
    setLoadingForm(true)
    const oldRow = rows.find(it => it.id === newRow.id)

    const { isNew, dataPost, dataPut, dataDelete, prevState, state } = newRow;
    const updatedRow = { ...state, id: newRow.id }
    if (isNew) {
      if (rows.filter(it => it.REPORT_CODE === state.REPORT_CODE && it.BUSINESS_UNIT === state.BUSINESS_UNIT).length) {
        setLoadingForm(false)
        SnackbarUtils.error(JSON.stringify('Duplicate report'))
        return
      }

      if (dataPost.length) {
        fetchPost(backend_url.config_report_parameters, dataPost).then(() => {
          setRows((prev) => [updatedRow, ...prev])
          setLoadingForm(false)
          showModal({ ...modal, highlight: false });
          SnackbarUtils.success(JSON.stringify('Successfully fetched'))
        })
          .catch(() => {
            setLoadingForm(false)
            //SnackbarUtils.error(JSON.stringify('Duplicate report or incorrect data'))
            return;
          })

      } else {
        setLoadingForm(false)
        SnackbarUtils.error(JSON.stringify('Add data'))
        return;
      }

    } else {
      if (!dataPost.length && !dataPut.length) {
        setLoadingForm(false)
        SnackbarUtils.error(JSON.stringify('Add data'))
        return;
      }
      const promises = [];
      dataPost.length && promises.push(fetchPost(backend_url.config_report_parameters, dataPost))
      dataPut.map(it => promises.push(fetchPut(backend_url.config_report_parameters, { ...prevState, PARAMETER_NAME: it.PARAMETER_NAME }, it)));
      dataDelete.map(it => promises.push(fetchDelete(backend_url.config_report_parameters, it)));

      Promise.all(promises)
        .then(() => {
          setLoadingForm(false)
          setRows(prev => prev.map((row) => (row.id === newRow.id ? updatedRow : row)));
          showModal({ ...modal, highlight: false });
          setEditTable(null);
          SnackbarUtils.success(JSON.stringify('Successfully fetched'))

        }).catch(() => {
          setLoadingForm(false)
          SnackbarUtils.error(JSON.stringify('Duplicate report or incorrect data'))
          return
        })
    }
    return updatedRow



  }

  const hancleCheckBox = (e, data) => {
    onHandleClick(value, { checked: e.target.checked, data });
  }

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 130,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {

        const editDeleteArr = checkConfiguration ?
          [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id, row)}
              color="inherit"
            />,
          ] : [];

        if (selected) {
          editDeleteArr.unshift(<Checkbox
            sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: '#000' } }}
            icon={<PanoramaFishEyeIcon />}
            checkedIcon={<CheckCircleOutlineIcon />}
            checked={configuration[value] ? configuration[value] === row[select] : false}
            onChange={(e) => hancleCheckBox(e, row[select])}
          />)
        }
        return editDeleteArr
      },
    },
    ...singleSelectArray(columnsTable, state),
  ]

  const closeModal = () => {
    handleClick(false)
    setEditTable(null);
  }

  const onRowClick = (e) => {
    if (selected) {
      onHandleClick(value, { checked: configuration[value] ? configuration[value] !== e.row[select] : true, data: e.row[value] });
    }
  }

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  return (
    <>
      <div className='bpx-config-datagrid'>
        <h3 className='bpx-config-datagrid-title'>{titleTable}</h3>
        <DataGridPro
          sx={{ '& .MuiDataGrid-main': { height: filterRows.length < 8 ? '100%' : '50vh' } }}
          autoHeight={Boolean(filterRows.length < 8)}
          rows={filterRows}
          onRowsScrollEnd={handleOnRowsScrollEnd}
          columns={columns}
          loading={loading}
          // rowModesModel={rowModesModel}
          experimentalFeatures={{ newEditingApi: true }}
          hideFooter={true}
          processRowUpdate={processRowUpdate}
          localeText={{
            noRowsLabel: t("Common.noRows")
          }}
          onRowClick={onRowClick}
          components={{
            LoadingOverlay: CustomLoadingOverlay
          }}
        />
        {
          checkConfiguration ?
            (
              <div className="bpx-config-datagrid-bottom">
                <Button
                  className="bpx-config-datagrid-btn"
                  startIcon={<AddIcon />}
                  onClick={() => handleClick()}
                />
              </div>
            )
            : null
        }
        <ModalAddDataHighlight
          titleTable={titleTable}
          open={modal.highlight}
          onClose={closeModal}
          handleSubmit={processRowUpdate}
          editData={editTable}
          highlightParameters={parameters}
          sections={sections}
          loading={loadingForm} />
        <ModalDelete
          open={modal.warning}
          onClose={() => showModal({ ...modal, warning: false })}
          onSubmit={deleteElement}
          loading={loadingForm} />

      </div>
    </>
  )
}

export default ConfigTableHighlight
