import { useState } from 'react'
import MenuItem from '@mui/material/MenuItem'
import ModalWindow from './ModalWindow'
import SvgIcon from '@mui/material/SvgIcon';

const ExcelExport = (props) => {

  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  return (
    <>
      <ModalWindow 
        open={open} 
        setOpen={setOpen} 
        {...props} 
      />
      {
        props.setting.isTrade?
          <div className='section-menu-toolbar'  onClick={() => handleClickOpen()}>    
            <span className='icon-menu-toolbar reset'>
              <SvgIcon>
                <path d='M19 12v7H5v-7H3v7c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-7h-2zm-6 .67l2.59-2.58L17 11.5l-5 5-5-5 1.41-1.41L11 12.67V3h2z'></path>
              </SvgIcon>
            </span>
            <span className='text-menu-toolbar'>{props.setting.toolbarExport}</span>
          </div> :
          <MenuItem onClick={() => handleClickOpen()} sx={{ paddingLeft: "0.5rem", paddingRight: "0.5rem", fontSize: "0.8rem" }}>
            Download as excel
          </MenuItem>
      }
{/* 
      <MenuItem onClick={() => handleClickOpen()} sx={{ paddingLeft: "0.5rem", paddingRight: "0.5rem", fontSize: "0.8rem" }}>
        Download as excel
      </MenuItem> */}
    </>
  )
}

export default ExcelExport
