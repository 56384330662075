import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import { GridOverlay } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'
import Checkbox from '@mui/material/Checkbox'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useTranslation } from 'react-i18next'
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import moment from 'moment'
import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs";
import { dateTimeFormatter } from "../../utils/utils"
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'

function DialogRecalculateAO({ open, onCloseHandle, handleSubmit, data, isCalculated }) {

  const { t } = useTranslation('translation')
  const { prefsData } = useUserPrefs()
  const [state, setState] = useState({
    ignore_no_accrual_day: false,
    force_ao_status_update: false,
    business_date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
  })


  const onDateChange = (e) => {
    setState({ ...state, business_date: moment(e).format("YYYY-MM-DD HH:mm:ss") })
  }


  const onHandleSubmit = (e) => {
    e.preventDefault();
    handleSubmit({ ...state, business_date: moment(state.business_date).utc().format("YYYY-MM-DDTHH:mm:ss.SSSSSS") })
  }

  const displayBlockForm = () => {

    return (
      <div className='bpx-block-regenerate bpx-block-recalculate' style={{ padding: 0 }}>
        <DialogTitle className='bpx-header-regenerate' sx={{ paddingBottom: 0 }}>
          <div className='bpx-title-regenerate'>
            {t("AOHeaderDetail.RECALCULATE_AO.Title")}
          </div>
          <div className="close-for-modal-window">
            <IconButton onClick={onCloseHandle} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <form onSubmit={onHandleSubmit}>
          <DialogContent className='bpx-content-regenerate' sx={{ paddingTop: 0, paddingBottom: 0 }}>

            <div className='bpx-top-regenerate'>
              <div className='bpx-top-regenerate-text'>
                {t("AOHeaderDetail.RECALCULATE_AO.text1")}
                <b>{t("AOHeaderDetail.RECALCULATE_AO.text2")}</b>
                {t("AOHeaderDetail.RECALCULATE_AO.text3")}<b>{data.ACTIVITY_ID}</b> (AO ID: {data.AO_ID}).
                <span>{t("AOHeaderDetail.RECALCULATE_AO.text4")}</span>
              </div>
              <div className='recalculate-ao '>
                <span>{t("AOHeaderDetail.RECALCULATE_AO.LAST_CALCULATED")}</span>
                <span className='bpx-right-regenerate'>{data.LAST_CALCULATED ? dateTimeFormatter(data.LAST_CALCULATED, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true) : 'n/a'}</span>
              </div>
              <div className='recalculate-ao '>
                <span>{t("AOHeaderDetail.RECALCULATE_AO.LAST_RECALCULATED")}</span>
                <span className='bpx-right-regenerate'>{data.LAST_RECALCULATED ? dateTimeFormatter(data.LAST_RECALCULATED, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true) : 'n/a'}</span>
              </div>
              <div className='recalculate-ao '>
                <span>{t("AOHeaderDetail.RECALCULATE_AO.LAST_CHANGED_ON")}</span>
                <span className='bpx-right-regenerate'>{data.run_plan && data.run_plan.LAST_CHANGED_ON ? dateTimeFormatter(data.run_plan.LAST_CHANGED_ON, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true) : 'n/a'}</span>
              </div>
            </div>

            <div className='bpx-bottom-regenerate'>
              <div className='recalculate-ao '>
                <span>
                  {t("AOHeaderDetail.RECALCULATE_AO.business_date")}
                </span>
                <span className='bpx-right-regenerate'>
                  <FormControl size='small'>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={onDateChange}
                        value={state.business_date}
                        renderInput={(params) =>
                          <TextField
                            {...params}
                            size="small"
                            sx={{ width: '150px' }}
                            inputProps={
                              {
                                ...params.inputProps,
                                placeholder: "YYYY-MM-DD"
                              }
                            }
                          />
                        }
                      />
                    </LocalizationProvider>
                  </FormControl>
                </span>
              </div>

              <div className=' recalculate-ao' >
                <span>
                  {t("AOHeaderDetail.RECALCULATE_AO.recalculate")}
                </span>
                <span className='bpx-right-regenerate'>
                  <Checkbox
                    checked={state.ignore_no_accrual_day}
                    onChange={(event) => {
                      setState({
                        ...state,
                        ignore_no_accrual_day: event.target.checked,
                      })
                    }}
                    name="recalculate"
                  />
                </span>
              </div>
              <div className='bpx-section-regenerate' style={{ margin: 0 }}>
                <i className='bpx-desc-regenerate'>({t("AOHeaderDetail.RECALCULATE_AO.recalculate_default")})</i>
              </div>

              <div className='recalculate-ao'>
                <span>
                  {t("AOHeaderDetail.RECALCULATE_AO.force_ao_status_update")}
                </span>
                <span className='bpx-right-regenerate'>
                  <Checkbox
                    checked={state.force_ao_status_update}
                    onChange={(event) => {
                      setState({
                        ...state,
                        force_ao_status_update: event.target.checked,
                      })
                    }}
                    name="force_ao_status_update "
                  />
                </span>
              </div>
              <div className='bpx-section-regenerate' style={{ margin: 0 }}>
                <i className='bpx-desc-regenerate'>({t("AOHeaderDetail.RECALCULATE_AO.force_ao_status_update_default")})</i>
              </div>
              {isCalculated && <div style={{ paddingTop: '10px' }}> <b>{t("AOHeaderDetail.RECALCULATE_AO.is_calculated")}</b></div>}
            </div>
          </DialogContent>

          <DialogActions className='bpx-footer-regenerate'>
            <Button disabled={isCalculated} type="submit"> {t("MODAL.BUTTONS.EXCUTE")}</Button>
          </DialogActions>
        </form>
      </div>
    )

  }


  return (
    <Dialog
      open={open}
      onClose={onCloseHandle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { maxWidth: '600px', width: '100%' } }}
    >
      {displayBlockForm()}
    </Dialog>
  )

}

export default DialogRecalculateAO;
