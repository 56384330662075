import { CustomToolbar } from '../../../components'
import './styles.css'
import { useTranslation } from 'react-i18next'

export default function NotFound () {
  const { t } = useTranslation('translation')
  const breadCrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label:  t("Breadcrumb.not_found"), link: '' },
  ]

  return (
    <>
      <CustomToolbar 
        bread={breadCrumb} 
        title={"404"} 
      />

      <section className="page_404">
        <div className="container">
          <div className="row"> 
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">

                <div className="four_zero_four_bg"></div>
                
                <div className="contant_box_404">
                  <h3 className="bpx-lost-text">Look like you're lost</h3>
                  <p className="bpx-sub-text">the page you are looking for not avaible!</p>
                  <a href="/login" className="link_404">Go to Home</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
   
  )
}
