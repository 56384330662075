
import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro, GridOverlay, useGridApiRef, GridSortModel } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { CustomToolbar } from '../../components'
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { Link, useLocation } from 'react-router-dom'
import { useFetch, usePermissions, useConvertJSON } from '../../hooks'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { getParam, numberFormatter, checkObjectKey, applyFilters, checkSize } from "../../utils/utils"


function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

const additionalLines = (showBalance) => {
  if (showBalance) {
    return <><div className='trade-ballance-row'>
    </div>
      <div className='trade-ballance-row'>
      </div></>
  }
  return null
}



export default function ActivitySummary() {

  const apiRef = useGridApiRef()

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()
  const { json2array } = useConvertJSON()
  const { obj, childObj } = usePermissions()
  const checkActivityID = checkObjectKey(obj, childObj, "Activity_ID")

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)

  const [exportQuery, setExportQuery] = useState("")
  const [sortModel, setSortModel] = useState()
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order&&prefsData.reports_column_order.Activity_Summary || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Activity_Summary || 'm');

  const columnVisibility = {};

  
  const location = useLocation()

  if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.Activity_Summary){
    prefsData.reports_column_visibilty.Activity_Summary.forEach(it=>columnVisibility[it]=false);
  }
  
  

  const saveColumnVisibility = (data)=>{
  
    saveUserPrefs({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        Activity_Summary: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
  }

  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "ACTIVITY_ID",
      title: t("AOHeaderList.ACTIVITY_ID"),
      inputText: t("ActivitySummaryPage.ACTIVITY_ID_INPUT"),
      firstKeyOption: "ACTIVITY_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("ACTIVITY_ID", "activitySummary"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "PLAN_ACCOUNT_BP",
      title: t("AOHeaderList.PLAN_ACCOUNT_BP_NR"),
      inputText: t("AOHeaderList.PLAN_ACCOUNT_BP_NR_INPUT"),
      firstKeyOption: "PLAN_ACCOUNT_BP",
      secondKeyOption: "NAME1",
      valueFilter: applyFilters("PLAN_ACCOUNT_BP", "activitySummary"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "EXT_SPEND_TYPE",
      title: t("ActivitySummaryPage.SPEND_TYPE"),
      inputText: t("ActivitySummaryPage.SPEND_TYPE_INPUT"),
      firstKeyOption: "EXT_SPEND_TYPE",
      secondKeyOption: "",
      valueFilter: applyFilters("EXT_SPEND_TYPE", "activitySummary"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "EXT_CATEGORY",
      title: t("ActivitySummaryPage.CATEGORY"),
      inputText: t("ActivitySummaryPage.CATEGORY_INPUT"),
      firstKeyOption: "EXT_CATEGORY",
      secondKeyOption: "",
      valueFilter: applyFilters("EXT_CATEGORY", "activitySummary"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "AO_STATUS",
      title: t("ActivitySummaryPage.STATUS"),
      inputText: t("ActivitySummaryPage.STATUS_INPUT"),
      firstKeyOption: "AO_STATUS",
      secondKeyOption: "",
      valueFilter: applyFilters("AO_STATUS", "activitySummary"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "BUSINESS_SEGMENT",
      title: t("MyBalance.BUSINESS_SEGMENT"),
      inputText: t("MyBalance.BUSINESS_SEGMENT_INPUT"),
      firstKeyOption: "BUSINESS_SEGMENT",
      secondKeyOption: "",
      valueFilter: applyFilters("BUSINESS_SEGMENT", "activitySummary", [], json2array("BUSINESS_SEGMENT")),
      changingArray: json2array("BUSINESS_SEGMENT"),
      translateJSON: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "PLAN_ACCOUNT_BP_CHANNEL",
      title: t("ActivitySummaryPage.PLAN_ACCOUNT_BP_CHANNEL"),
      inputText: t("ActivitySummaryPage.PLAN_ACCOUNT_BP_CHANNEL_INPUT"),
      firstKeyOption: "PLAN_ACCOUNT_BP_CHANNEL",
      secondKeyOption: "",
      valueFilter: applyFilters("PLAN_ACCOUNT_BP_CHANNEL", "activitySummary"),
      changingArray: [],
      noObject: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "PLAN_ACCOUNT_BP_SUB_CHANNEL",
      title: t("ActivitySummaryPage.PLAN_ACCOUNT_BP_SUB_CHANNEL"),
      inputText: t("ActivitySummaryPage.PLAN_ACCOUNT_BP_SUB_CHANNEL_INPUT"),
      firstKeyOption: "PLAN_ACCOUNT_BP_SUB_CHANNEL",
      secondKeyOption: "",
      valueFilter: applyFilters("PLAN_ACCOUNT_BP_SUB_CHANNEL", "activitySummary"),
      changingArray: [],
      noObject: true,
      formatField: "select",
    }
  ])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items, sortParam=false) => {
    const changeIdRow = items.map(d => {
      return {
        id: d.AO_ID + '_' + d.ACTIVITY_ID,
        ACTIVITY_ID: d.ACTIVITY_ID,
        EXT_CLASS: d.EXT_CLASS,
        PLAN_ACCOUNT: d.PLAN_ACCOUNT_BP_NR + " - " + d.PLAN_ACCOUNT_NAME,
        ...d,
        TE_GSV_ACT: d.TE_GSV_ACT
      }
    }
    )
    if (sortParam)
        setRows((old) => {
          return [...changeIdRow]
        })
    else
        setRows((old) => {
          return [...old, ...changeIdRow]
        })

  }, [])
  const limit = 20
  useEffect(() => {

    setLoading(true)

    var queryParams = {
      business_unit: value_bu,
      limit: limit,
      skip: page,
    }

    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField, translateJSON } = key

      switch (formatField) {
        case "select":
          if (valueFilter.length) {
            
            if (translateJSON) {
              
              const getKey = valueFilter.map(jsonkey => Object.keys(jsonkey))
              queryParams[firstKeyOption] = getKey

            } else {
              queryParams[firstKeyOption] = valueFilter
            }

          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      activitySummary: queryParams
    }

    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["activitySummary"] = queryParams
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))

    let exportParams = Object.keys(queryParams).filter(
        (key) => !["limit", "skip"].includes(key)).reduce(
            (obj, key) => {
              return {...obj, [key]: queryParams[key]};
            },
        {}
    );

    let sort_url = ""
    if (sortModel) {
      let sortType = sortModel[0].field
      if (sortType === "id")
        sortType = "AO_ID"
      let sortOrder = sortModel[0].sort
      sort_url = `&SORT=${sortType}&SORT_ORDER=${sortOrder}`
    }

    let additional_filter = ""
    if (location && location.pathname.includes("overpayments")) {
        additional_filter = "&ACCRUAL_BALANCE=0.0"
        queryParams.limit = 20
    } else if (location && location.pathname.includes("without_payments")) {
        additional_filter = "&PAYMENT_VALUE=0.0"
    }

    const queryString = getParam(queryParams).toString()
    const exportQueryString = getParam(exportParams).toString()

    setExportQuery(exportQueryString)

    request.fetchData(
      `${backend_url.activity_summary_header}?${queryString}${additional_filter}${sort_url}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
      .then(data => {
        setLoading(false)
        addLoadedRows(data)
        setCount(data.length)
      })

  }, [filter, page])

  useEffect(() => {
      return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
          silentUpdateUserPrefs()
      })
  }, [apiRef])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (location && location.pathname.includes("overpayments")) {
          return setPage(page + limit)
      } else if (count >= limit) {
          return setPage(page + limit)
      } else {
          return null
      }
    }
  }

  const columns = React.useMemo(() => {
    const allColumns = {
        id: { field: 'id', headerName: 'id' },
        ACTIVITY_ID: {
            field: 'ACTIVITY_ID',
            headerName: t("ActivitySummaryPage.ACTIVITY_ID"),
            hideable: false,
            flex: 0.08,
            minWidth: 50,
            renderCell: (params) => {
              if (checkActivityID) {
                return (
                  <div className="MuiDataGrid-cellContent">
                    <Link style={{ color: '#f69e80' }} to={`/my-activities/accrual_object/${params.row.AO_ID}`} className="dark-mode-link-color">
                      {params.value}
                    </Link>
                  </div>
                )
              }
            }
        },
        EXT_SPEND_TYPE: { 
            field: 'EXT_SPEND_TYPE', 
            headerName: t("ActivitySummaryPage.SPEND_TYPE"), 
            flex: 0.05,
            minWidth: 50,
            renderCell: ({value})=>{
              if(!value){
                return ''
              }
              return(
                <div className="MuiDataGrid-cellContent">
                  {t("EXT_SPEND_TYPE." + value, { ns: 'mars_dictionaries' })}
                </div>
              )
            }
        },
        EXT_CATEGORY: { 
            field: 'EXT_CATEGORY', 
            headerName: t("ActivitySummaryPage.CATEGORY"), 
            flex: 0.05,
            minWidth: 50,
            renderCell: ({value})=>{
              if(!value){
                return ''
              }
              return(
                <div className="MuiDataGrid-cellContent">
                  {t("EXT_CATEGORY." + value, { ns: 'mars_dictionaries' })}
                </div>
              )
            }
        },
        EXT_CLASS: { 
            field: 'EXT_CLASS', 
            headerName: t("ActivitySummaryPage.INVESTMENT"), 
            flex: 0.07,
            minWidth: 50,
        },
        PLAN_ACCOUNT_BP_CHANNEL: {
          field: 'PLAN_ACCOUNT_BP_CHANNEL',
          headerName: t("TradeExpeditiureMonitor.PLAN_ACCOUNT_BP_CHANNEL"),
          minWidth: 100,
          flex: 0.20,
          cellClassName: 'bpx-trade-cell',
          renderCell: (params) => {
            return (
              <div className="MuiDataGrid-cellContent">
                <div className='trade-main-row'>
                  {params.value}
                </div>
                {additionalLines(params.row.showBalance)}
              </div>
            )
          }
        },
        PLAN_ACCOUNT_BP_SUB_CHANNEL: {
          field: 'PLAN_ACCOUNT_BP_SUB_CHANNEL',
          headerName: t("TradeExpeditiureMonitor.PLAN_ACCOUNT_BP_SUB_CHANNEL"),
          minWidth: 100,
          flex: 0.20,
          cellClassName: 'bpx-trade-cell',
          renderCell: (params) => {
            return (
              <div className="MuiDataGrid-cellContent">
                <div className='trade-main-row'>
                  {params.value}
                </div>
                {additionalLines(params.row.showBalance)}
              </div>
            )
          }
        },
        PLAN_ACCOUNT: { 
            field: 'PLAN_ACCOUNT', 
            headerName: t("ActivitySummaryPage.PLAN_ACCOUNT_BP_NR_CONCAT"), 
            flex: 0.10,
            minWidth: 50,
            renderHeader: ({colDef})=>{
              if(colDef.width < 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Plan account" 
                  data-mui-internal-clone-element="true">
                      {t("ActivitySummaryPage.PLAN_ACCOUNT_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Plan account" 
                  data-mui-internal-clone-element="true">
                    {t("ActivitySummaryPage.PLAN_ACCOUNT_BP_NR_CONCAT")}
                </div>
              )
            },
        },
        EXT_SUBCLASS: { 
            field: 'EXT_SUBCLASS', 
            headerName: t("ActivitySummaryPage.SUB_INVESTMENT"), 
            flex: 0.07,
            minWidth: 50,
            renderHeader: ({colDef})=>{
              if(colDef.width < 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Sub Investment" 
                  data-mui-internal-clone-element="true">
                      {t("ActivitySummaryPage.SUB_INVESTMENT_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Sub Investment" 
                  data-mui-internal-clone-element="true">
                    {t("ActivitySummaryPage.SUB_INVESTMENT")}
                </div>
              )
            },
        },
        AO_STATUS: { 
            field: 'AO_STATUS', 
            headerName: t("ActivitySummaryPage.STATUS"), 
            flex: 0.05,
            minWidth: 50,
        },
        ACCRUALS_POSTED: {
            field: 'ACCRUALS_POSTED',
            headerName: t("ActivitySummaryPage.ACCRUALS_POSTED"),
            flex: 0.06,
            minWidth: 50,
            sortable: false,
            renderCell: ({ value }) => {
              if (isNaN(value)) {
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              return <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>;
            },
            align: "right",
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual Posted"
                  data-mui-internal-clone-element="true">
                      {t("ActivitySummaryPage.ACCRUALS_POSTED_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual Posted"
                  data-mui-internal-clone-element="true">
                    {t("ActivitySummaryPage.ACCRUALS_POSTED")}
                </div>
              )
            },
        },
        CURRENCY_ACCRUALS_POSTED:{ 
            field: 'CURRENCY_ACCRUALS_POSTED', 
            headerName: t("ActivitySummaryPage.CURRENCY_ACCRUALS_POSTED"), 
            flex: 0.03,
            minWidth: 50,
            sortable: false,
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual Posted Currency"
                  data-mui-internal-clone-element="true">
                      {t("ActivitySummaryPage.CURRENCY_ACCRUALS_POSTED_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual Posted Currency"
                  data-mui-internal-clone-element="true">
                    {t("ActivitySummaryPage.CURRENCY_ACCRUALS_POSTED")}
                </div>
              )
            },
        },
        TE_GSV_ACT: {
            field: 'TE_GSV_ACT',
            headerName: t("ActivitySummaryPage.TE_GSV_ACT"),
            flex: 0.05,
            minWidth: 50,
            sortable: false,
            renderCell: ({ value }) => {
              if (isNaN(value)) {
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              return <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value) + " %"}</div>;
            },
            align: "right",
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Trade Expenditure vs. GSV (Actuals)"
                  data-mui-internal-clone-element="true">
                      {t("ActivitySummaryPage.TE_GSV_ACT_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Trade Expenditure vs. GSV (Actuals)"
                  data-mui-internal-clone-element="true">
                    {t("ActivitySummaryPage.TE_GSV_ACT")}
                </div>
              )
            },
        },
        PAYMENTS: {
            field: 'PAYMENTS',
            headerName: t("ActivitySummaryPage.PAYMENTS"),
            flex: 0.06,
            minWidth: 50,
            sortable: false,
            renderCell: ({ value }) => {
              if (isNaN(value)) {
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              return <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>;
            },
            align: "right"
        },
        CURRENCY_PAYMENTS: { 
            field: 'CURRENCY_PAYMENTS', 
            headerName: t("ActivitySummaryPage.CURRENCY_PAYMENTS"), 
            flex: 0.03,
            minWidth: 50,
            sortable: false,
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Payments Currency"
                  data-mui-internal-clone-element="true">
                      {t("ActivitySummaryPage.CURRENCY_PAYMENTS_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Payments Currency"
                  data-mui-internal-clone-element="true">
                    {t("ActivitySummaryPage.CURRENCY_PAYMENTS")}
                </div>
              )
            },
        },
        ACCRUAL_BALANCE: { 
            field: 'ACCRUAL_BALANCE',
            headerName: t("ActivitySummaryPage.ACCRUAL_BALANCE"),
            flex: 0.06,
            minWidth: 50,
            sortable: false,
            renderCell: ({ value }) => {
              if (isNaN(value)) {
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              if(value<0){
                return <div className="MuiDataGrid-cellContent"style={{ color: "#FF0000" }}>
                      {numberFormatter(prefsData.decimal_notation).format(value)}
                    </div>   
              }
              return <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>;
            },
            align: "right",
            renderHeader: ({colDef})=>{
              if(colDef.width <= 100){
                return (
                  <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual balance"
                  data-mui-internal-clone-element="true">
                      {t("ActivitySummaryPage.ACCRUAL_BALANCE_SHORT")}
                  </div>
                )
              }
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                  aria-label="Accrual balance"
                  data-mui-internal-clone-element="true">
                    {t("ActivitySummaryPage.ACCRUAL_BALANCE")}
                </div>
              )
            },
        },
        SELL_IN_VOL_CS: { 
            field: 'SELL_IN_VOL_CS', 
            headerName: t("ActivitySummaryPage.SELL_IN_VOL_CS"), 
            flex: 0.06,
            minWidth: 50,
            sortable: false,
            renderCell: ({ value }) => {
              if (isNaN(value)) {
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              return <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>;
            },
            align: "right"
        },
        GSV_PLN: {
            field: 'GSV_PLN',
            headerName: t("ActivitySummaryPage.GSV_PLN"),
            flex: 0.06,
            minWidth: 50,
            sortable: false,
            renderCell: ({ value }) => {
              if (isNaN(value)) {
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              return <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>;
            },
            align: "right"
        },
        NIV_PLN: {
            field: 'NIV_PLN',
            headerName: t("ActivitySummaryPage.NIV_PLN"),
            flex: 0.06,
            minWidth: 50,
            sortable: false,
            renderCell: ({ value }) => {
              if (isNaN(value)) {
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              return <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>;
            },
            align: "right"
        },
        NSV_PLN: {
            field: 'NSV_PLN',
            headerName: t("ActivitySummaryPage.NSV_PLN"),
            flex: 0.06,
            minWidth: 50,
            sortable: false,
            renderCell: ({ value }) => {
              if (isNaN(value)) {
                return <div className="MuiDataGrid-cellContent">{value}</div>;
              }
              return <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>;
            },
            align: "right"
        },
    }

    return orderedColumns.length?orderedColumns.reduce((acc, field) => {
      return [...acc, allColumns[field]];
    }, []):Object.values(allColumns)
  }, [orderedColumns]);

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Menu.activities"), link: '' },
    { label: t("ActivitySummaryPage.ACTIVITY_SUMMARY"), link: '/my-activities/activity_summary' },
  ]

  const handleColumnOrderChange = React.useCallback((params) => {
    setOrderedColumns((prevOrderedColumns) => {
      const newOrderedColumns = [...prevOrderedColumns];
      const oldIndex = params.oldIndex;
      const targetIndex = params.targetIndex;
      const oldColumn = prevOrderedColumns[oldIndex];
      newOrderedColumns.splice(oldIndex, 1);
      newOrderedColumns.splice(targetIndex, 0, oldColumn);
      saveUserPrefsWithoutRefresh({
        ...prefsData,
        reports_column_order: {
          ...prefsData.reports_column_order,
          Activity_Summary: newOrderedColumns
        }
      })
      return newOrderedColumns;
    });
  }, []);

  const handleSortModelChange =((sortModel) => {
    setLoading(true)
    var queryParams = {
      business_unit: value_bu,
      limit: limit,
      skip: page,
    }
    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField, translateJSON } = key

      switch (formatField) {
        case "select":
          if (valueFilter.length) {
            
            if (translateJSON) {
              
              const getKey = valueFilter.map(jsonkey => Object.keys(jsonkey))
              queryParams[firstKeyOption] = getKey

            } else {
              queryParams[firstKeyOption] = valueFilter
            }

          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })
    const queryString = getParam(queryParams).toString()
    let sortType = sortModel[0].field
    if (sortType === "id")
      sortType = "AO_ID"
    let sortOrder = sortModel[0].sort
    request.fetchData(
      `${backend_url.activity_summary_header}?${queryString}&SORT=${sortType}&SORT_ORDER=${sortOrder}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
      )
      .then(data => {
          setLoading(false)
          addLoadedRows(data, true)
          setPage(0)
          setCount(data.length)
      }).catch(()=>{
        setLoading(false)
      })
      console.log(sortModel)
      setSortModel(sortModel);
  });

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Activity_Summary: value
      }
    })
  }

  return (
    <div className="tableContainer">
      <DataGridPro
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }
        }} 
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}
        disableColumnFilter
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        onColumnOrderChange={handleColumnOrderChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        onPreferencePanelClose={()=> updateUserPrefsOnClose()}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        initialState={{
          columns: {
            columnVisibilityModel: columnVisibility,
          },
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}

        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.ActivitySummary"),
            displayStandartReportButton: true,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            filters:
              <CustomMultipleFilter
                onFilter={changeFilterValues}
                dataFilter={filter}
                loading={loading}
              />,
            exportSetting: {
              endpoint: backend_url.activity_summary_excel_file + '?' + exportQuery,
              endpointCount: backend_url.activity_summary_excel_count + '?' + exportQuery,
              fileName: "Activity Summary Report"
            }
          }
          
        }}
      />
    </div>
  )
} 
