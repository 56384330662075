import { useEffect, useState } from 'react'
import {  dateTimeFormatter } from "../../utils/utils"
import { useUserPrefs } from '../UserPreferences/ProvideUserPrefs';
import { useTranslation } from "react-i18next"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { backend_url } from '../../settings'
import { useFetch } from '../../hooks'
import { LoadingWithText } from '../../components'
import { DataGridPro } from '@mui/x-data-grid-pro';
import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tooltip } from '@mui/material';

const createArrow = () => {
  return (<div className="approval-history-arrow-container">
    <div className="approval-history-line"></div>
    <div className="approval-history-arrow-right"></div>
  </div>)
}

const returnIcons = (data) => {
  const msg = data.MESSAGE
  const content = {
    description: {
      date: data.date,
      msg: msg,
    },
    arrow: true,
    icon: <TimerOffIcon />,
    next_description: ''
  }
  if (msg.includes('New AW created by')) {
    content.icon = <PlayCircleFilledIcon />;
    content.next_description = 'Processing';
  } else if (msg.includes('Information email sent to')) {
    content.icon = <AlternateEmailIcon />;
    content.next_description = 'Waiting for decision from' + msg.replace('Information email sent to', '');
  } else if (msg.includes('Request email sent to')) {
    content.icon = <MailOutlineIcon />;
    content.next_description = 'Waiting for decision from' + msg.replace('Request email sent to', '');
  } else if (msg.includes('Time for decision passed')) {
    content.icon = <MailOutlineIcon />;
    content.next_description = 'Waiting for decision from' + msg.replace('Request email sent to backup approvers', '');
  } else if (msg.includes('canceled')) {
    content.icon = <TimerOffIcon />;
    content.arrow = false
  } else if (msg.includes('rejected by')) {
    content.icon = <ThumbDownAltIcon />;
    content.arrow = false
  } else if (msg.includes('approved by')) {
    content.icon = <ThumbUpAltIcon />;
    content.next_description = 'Processing';
  } else if (msg.includes('fully approved')) {
    content.icon = <CheckCircleIcon />;
    content.arrow = false
  }
  return content
}


const createContent = (data) => {
  const content = data.map((it, id) => {
    const el = returnIcons(it)
    const greyColor = id == data.length - 1 ? 'grey' : ''
    return (<div className={'approval-history-content ' + greyColor} key={'icons-' + id}>
      <Tooltip
        title={
          <div style={{ textAlign: 'center' }}>
            <div>{el.description.date}</div>
            <div>{el.description.msg}</div>
          </div>
        }

        placement='top-start'
        arrow enterDelay={200}
        enterNextDelay={200}>
        <div className='approval-history-content-icon'>{el.icon}</div>
      </Tooltip>
      {el.arrow && createArrow()}
      {el.arrow && id == data.length - 1 &&
        <div className='approval-history-content-number'>
          <Tooltip title={el.next_description} placement='top-start' arrow enterDelay={200} enterNextDelay={200}>
            <div>{id + 1}</div>
          </Tooltip>
        </div>}
    </div>)
  })


  return (<div className='approval-history-content-wrap'>
    <div className='approval-history-content-container'>
      {content}
    </div>

  </div>)

}


export const AdjustmentsApprovalHistoryModal = ({ approvalHistory, handleClose }) => {

  const { t } = useTranslation("translation")
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem("token_bpx")
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const request = useFetch()
  const { prefsData } = useUserPrefs();


  useEffect(() => {
    setLoading(true)
    if (approvalHistory.open) {
      const func = "Accrual Adjustment";
      request.fetchData(
        `${backend_url.approval_document_history}/${func}/${approvalHistory.AC_AD_ID}?BUSINESS_UNIT=${value_bu}`,
        "GET",
        null,
        false,
        tokenBpxRequest,
        false
      ).then((data) => {
        setLoading(false)
        setData(data)
      }).catch(() => {
        setData(null)
        setLoading(false)
      })
    }

  }, [approvalHistory.open])


  const columns = [
    {
      field: 'id',
      headerName: t("ApprovalHistory.AW_VERSION"),
      flex: 1,
      required: true,
      minWidth: 50,
      sortable: false,
    },
    {
      field: 'STATUS',
      headerName:  t("ApprovalHistory.STATUS"),
      flex: 1,
      required: true,
      minWidth: 100,
      sortable: false,
    },
    {
      field: 'USER_ID',
      headerName:  t("ApprovalHistory.USER_ID"),
      flex: 3,
      required: true,
      minWidth: 150,
      sortable: false,
      renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{value.replace("accrualengine", "TreXcellence Accrual Engine")}</div>,

    },
    {
      field: 'CHANGE_ON',
      headerName: t("ApprovalHistory.CHANGE_ON"),
      flex: 2,
      required: true,
      minWidth: 100,
      sortable: false,
      renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>,
    },
    {
      field: 'MESSAGE',
      headerName:  t("ApprovalHistory.MESSAGE"),
      flex: 4,
      required: true,
      minWidth: 200,
      sortable: false,
    },
  ]


  const displayContent = () => {

    if (!data || !Object.values(data).length || !data.AW_HISTORY.length || data.AW_HISTORY.find(it => !it.MESSAGE)) {
      return (<div style={{ textAlign: 'center', padding: '20px 0' }}>
        {t("ApprovalHistory.no_data1")}{approvalHistory.AC_AD_ID}{t("ApprovalHistory.no_data2")}
      </div>)
    }
    const rows = data.AW_HISTORY.sort((a, b) => a.AW_VERSION - b.AW_VERSION).map((it, id) => (
      {
        ...it,
        date: dateTimeFormatter(it.CHANGE_ON, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
        id
      }
    ))
    return (<>
      <div>
        {createContent(rows)}
      </div>
      <DataGridPro
        sx={{ '& .MuiDataGrid-main': { height: rows.length < 8 ? '100%' : '50vh' } }}
        autoHeight={Boolean(rows.length < 8)}
        rows={rows}
        columns={columns}
        hideFooter={true}
        localeText={{
          noRowsLabel: t("Common.noRows")
        }}
      />
    </>)

  }
  const displayBlockForm = () => {
    return (
      <div className='bpx-block-regenerate bpx-block-recalculate' style={{ padding: 0 }}>
        <DialogTitle className='bpx-header-regenerate' sx={{ paddingBottom: 0 }}>
          <div className='bpx-title-regenerate'>
            {t("ApprovalHistory.title")} {approvalHistory.AC_AD_ID} | {t("ApprovalHistory.title2")}
          </div>
          <div className="close-for-modal-window">
            <IconButton onClick={handleClose} className="wrap-cancel-modal">
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent className='bpx-content-regenerate' sx={{ paddingTop: 0, paddingBottom: 0 }}>
          <div className='bpx-top-regenerate'>
            {displayContent()}
          </div>

        </DialogContent>
        <DialogActions className='bpx-footer-regenerate'>
          <Button onClick={handleClose} > {t("MODAL.BUTTONS.CLOSE")}</Button>
        </DialogActions>
      </div>
    )

  }

  const displayLoading = (
    loading
      ? <LoadingWithText text="Your request is being processed, please wait." size={50} />
      : displayBlockForm()
  )


  return (
    <Dialog
      open={approvalHistory.open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ '& .MuiDialog-paper': { minWidth: '1000px', maxWidth: "1000px" } }}
    >
      {displayLoading}
    </Dialog>

  );
}
