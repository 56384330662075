import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useOktaAuth } from '@okta/okta-react';
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../hooks'
import { useAuth } from '../Login/ProvideAuth'
import { Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom'
export default function ProvideResponse() {

  const params = useParams();
  const request = useFetch()
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  let auth = useAuth()
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [answer, setAnswer] = useState('')

  useEffect(() => {
    const body = {
      decision_key: params.hash
    }
    request.fetchData(
      backend_url.email_response,
      'POST',
      JSON.stringify(body),
      false,
      tokenBpxRequest,
      false,
      false
    ).then(data => {
      setLoading(false);
      setAnswer('Thank you for making the decision')
    }).catch(data => {
      if(String(data).includes('410')){
        setLoading(false)
        setAnswer('Link expired') 
      }else{
        if(String(data).includes('401')||String(data).includes('403')){
          auth.signout()
        }else{
          setLoading(false)
          setAnswer('Error') 
        }
      }  
    })

  }, [])


  const handleClickIsOkta = () => {
    // navigate('/login', { replace: true })
    auth.signout()
    
  }

  const handleClick = () => [
    navigate('/', { replace: true })
  ]


  const button = auth.isOkta ?
    <Button variant="contained" onClick={handleClickIsOkta}>Exit</Button> :
    <Button variant="contained" onClick={handleClick}>Return to Main Page</Button>;


  return (
    <>
      {
        loading ?
          <Box sx={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
            <CircularProgress />
          </Box> :
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '50px' }}>
              <p>{answer}</p>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
              {button}
            </Box>
          </>
      }
    </>
  )
}