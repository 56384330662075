import React, { useState } from 'react'
import { GridRowModes, GridActionsCellItem } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

import SaveIcon from '@mui/icons-material/Save'
import CancelIcon from '@mui/icons-material/Close'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import EditIcon from '@mui/icons-material/Edit'
import SouthEastIcon from '@mui/icons-material/SouthEast'
import DeleteIcon from '@mui/icons-material/Delete'

import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

import { styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(0),
  },
  '& .MuiInputBase-input': {
    borderRadius: 5,
    position: 'relative',
    border: '2px solid #ced4da',
    padding: '5px 5px 5px 5px',
    transition: theme.transitions.create(['border-color', 'opacity']),
    '&:focus': {
      borderColor: '#e59073',
      boxShadow: "none",
    }
  },
}))


function CustomActionTable ({ id }) {

  const { t } = useTranslation('translation')
  const [rowModesModel, setRowModesModel] = useState({})
  const [rows, setRows] = useState([])


  function handleChangeSelect(evt) {

    console.log(evt.target.value)
    return [
      <GridActionsCellItem
        icon={<EditIcon />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(id)}
        color="inherit"
      />,
      <GridActionsCellItem
        icon={<DeleteIcon />}
        label="Delete"
        onClick={handleDeleteClick(id)}
        color="inherit"
      />,
    ]
  }

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id))
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }





  const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

  if (isInEditMode) {
    return [
      <GridActionsCellItem
        icon={<SaveIcon />}
        label={t("CustomToolbar.save")}
        onClick={handleSaveClick(id)}
        color="primary"
      />,
      <GridActionsCellItem
        icon={<CancelIcon />}
        label={t("CustomToolbar.cancel")}
        className="textPrimary"
        onClick={handleCancelClick(id)}
        color="inherit"
      />,
    ]
  }

  return [
    <React.Fragment key={id}>
      <FormControl sx={{ minWidth: 130 }} size="small" variant='outlined'>
        <InputLabel className="customLabelTable">{t("BPList.ACTIONS")}</InputLabel>
        <Select
          className='customSelectTable'
          defaultValue=""
          onChange={handleChangeSelect}
          variant="outlined"
          input={<BootstrapInput />}
        >
          <MenuItem className='customOptionTable' value="Goto">
            <SouthEastIcon sx={{ color: "#696969", height: "0.9rem", marginRight: "3px" }} />
            <span>{t("CustomToolbar.goTo")}</span>
          </MenuItem>
          <MenuItem className='customOptionTable' value="Deactivate">
            <EditIcon sx={{ color: "#696969", height: "0.9rem", marginRight: "3px" }} />
            <span>{t("CustomToolbar.deactivate")}</span>
          </MenuItem>
          <MenuItem className='customOptionTable' value="More">
            <MoreHorizIcon sx={{ color: "#696969", height: "0.9rem", marginRight: "3px" }} />
            <span>{t("CustomToolbar.more")}</span>
          </MenuItem>
        </Select>
      </FormControl>
    </React.Fragment>
  ]


}

export default CustomActionTable

