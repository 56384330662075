import React, { useState } from 'react'
import { Link,useLocation } from 'react-router-dom'
import { Button, Menu, MenuItem } from '@mui/material'

const MenuGroup = ({ menuText }) => {

  const [anchorEl, setAnchorEl] = useState(null);
  const location = useLocation();

  const handleClick = (index, event) => {
    setAnchorEl({ [index]: event.currentTarget })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const displayMenuBtn = (name, index, child) => {
    
    const checkStatus = anchorEl && anchorEl[index];
    const checkLinksChildren = child.map(it=>it.linkTo).includes(location.pathname);

    return (
      <div key={index}>
        <Button
          disableRipple
          onClick={(e) => handleClick(index, e)}
          className={`bpx-btn-header 
          ${checkLinksChildren ?
            'bpx-active-menu' : 
            '' 
          }`
          }
        >
          {name}
        </Button>
        <Menu 
          keepMounted 
          anchorEl={checkStatus} 
          open={Boolean(checkStatus)} 
          onClose={handleClose}
        >
          { displayMenuItem(child) }
        </Menu>
      </div>
    )

  }

  const displayMenuItem = (child) => {

    return child.map(unit => {
      const { label, linkTo } = unit

      return (
        <MenuItem 
          onClick={handleClose} 
          component={Link} 
          to={linkTo} 
          key={label}
        >
          {label}
        </MenuItem>
      )
    })

  }



  return (
    <>
      {
        menuText.map((item, index) => {
          const { name, child } = item
          return displayMenuBtn(name, index, child)
        })
      }

    </>
  )
      
      
    
}

export default MenuGroup
