import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { Dialog } from '@mui/material'


import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import VisibilityIcon from '@mui/icons-material/Visibility'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'

import CloseIcon from '@mui/icons-material/Close'
import IconButton from '@mui/material/IconButton'

function HelpLoginComponent() {
  const [open, setOpen] = useState(false)
 
  const { t } = useTranslation('translation')

  const handleClick = ()=>{
    setOpen(true)
  }

  const handleClose = ()=>{
    setOpen(false)
  }

  return (
    <React.Fragment>
      <HelpOutlineOutlinedIcon 
        sx={{ mr: 1, cursor: 'pointer' , color: '#908e8e', transform: 'scale(1.2)'}} 
        onClick={handleClick}
      />
       <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className='bg-help'
        PaperProps={{
          style: {
            backgroundSize: '40%'
          },
        }}
      >
        <div className='block-help'>
          <div className='block-header-help'>
            <span>{t("helpPopup.hello")}</span> 
            {t("helpPopup.how_can")}
            <div className="close-for-modal-window">
              <IconButton onClick={handleClose} className="wrap-cancel-modal">
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          
          <div className='block-middle-help'>
            <p>Welcome in TreXellence Accrual Engine Support Centre! If you wish to know more about TreXellence Accrual Engine <b>please visit our <a style={{textDecoration: 'underline'}} href="https://www.bpxglobal.com/en/solutions/rgm/">website</a>.</b></p>
            <p>If you have problems with access, please <b>contact your System Administrator</b> or <b>Application Key User</b> for more information.</p>
            <p>You can also contact our <b>Support Team</b> via <a href="mailto: ae.support@bpx.pl">ae.support@bpx.pl</a>.</p>
          </div>
        </div>
      </Dialog>
      {/* <MenuIcon fontSize="large"  onClick={handleClick} />
      <Menu 
        anchorEl={anchorEl} 
        open={open} 
        onClose={handleClose} 
        className="bpx-header-menu-dropdown"
        anchorOrigin={{
          vertical: 30,
          horizontal: 25
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: "right"
        }}
      >
          
        <MenuItem onClick={handleClose}>
           <a href="https://www.bpxglobal.com/en/solutions/rgm/" className="bpx-header-link">{t("Login.MENU.what_is")}</a>
        </MenuItem>
        <MenuItem onClick={handleCloseAcces}>
           <span className="bpx-header-link" >{t("Login.MENU.how_to_get_access")}</span>
        </MenuItem>
       
      </Menu>
      <PopupGetAccess open={openPopup} onClose={()=>setOpenPopup(false)}/> */}
    </React.Fragment>
  )
}

export default HelpLoginComponent
