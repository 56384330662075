import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'


i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: localStorage.getItem("current_language") || "en",
    debug: false,
    ns: ['translation', 'uom', 'mars_dictionaries', 'material_dictionaries'],
    defaultNS: "translation",
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false
    },
    returnObjects: true
  })

  export default i18n
