import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material'
import { useFetch } from '../../hooks'
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';


export default function AutoCompleteCustom({ data,onChangeHandler }) {
  const { url, headerName, multiple, valueParser, required, columns, optionsLabel } = data
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([])
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(['translation'])

  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch();

  const bu = localStorage.getItem('bu_bpx');

  useEffect(() => {
    request.fetchData(
      url + `?business_unit=${bu}&limit=1000000&skip=0`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    ).then(data => {
      setOptions(data.map((it, id) => ({ ...it, group: 0, id })))
      setLoading(false)
    })
  }, [])



  const handleChange = (value) => {
    if (multiple || !value.length) {
      setSelectedOptions(value)
      onChangeHandler(valueParser(value))
    } else {
      setSelectedOptions([value.at(-1)])
      onChangeHandler(valueParser(value.at(-1)))
    }
  }
  return (
    <Autocomplete
      value={selectedOptions}
      disableCloseOnSelect={false}
      id="grouped-demo"
      className='bpx-userid-select'
      loading={loading}
      multiple
      getOptionLabel={(option) => optionsLabel.map(it => option[it]).join(' - ')}
      options={options.sort()}
      groupBy={(option) => option.group}
      renderGroup={(params) => {

        return (
          <TableContainer key={params.key} sx={{ maxHeight: 280 }}>
            <Table stickyHeader aria-label="sticky table" size="small" >
              <TableHead sx={{ backgroundColor: '#e0e0e0' }}>
                <TableRow sx={{ backgroundColor: '#e0e0e0' }} >
                  {columns.map((it, id) => {
                    return (<TableCell key={'column' + id} style={{ minWidth: it.size, width: it.size, fontSize: "12px", padding: '6px' }} sx={{ backgroundColor: '#e0e0e0' }}>{t(`Scheduler.${it.headerName}`)}</TableCell>)
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {params.children}
              </TableBody>

            </Table>
          </TableContainer>
        )
      }}
      renderOption={(props, option) => {
        delete props.className

        const newColumns = (<>
          {columns.map((it, id) => <TableCell key={'rowcell' + id} style={{ minWidth: it.size, width: it.size, fontSize: "12px", padding: '6px' }} align={it.align} size='small'>{it.content(option, selectedOptions)}</TableCell>)}
        </>)
        return (
          <TableRow {...props} key={'row' + option.id} style={{ cursor: 'pointer' }}
          >
            {newColumns}
          </TableRow>

        )
      }}
      onChange={(event, value) => {
        handleChange(value)

      }}
      renderInput={(params) =>
        <TextField
          {...params}
          label={headerName}
          className='bpx-modal-add-data-autocomplete'
          size="small"
          inputProps={{
            ...params.inputProps,
            required: required && selectedOptions.length === 0
          }}
          required={required}
        />
      }
    />
  )
}