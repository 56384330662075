import React, { useEffect, useState, useCallback } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useFetch } from '../../hooks'
import { exportBlob } from "../../utils/utils"
import CircularProgress from '@mui/material/CircularProgress'
import SnackbarUtils from '../../utils/SnackbarUtils'
import { backend_url } from '../../settings'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'


export function CircularProgressWithLabel(props) {

  const displayMessage = 
    props.value.join() === "Export complete" 
      ? <b>The file is downloading... </b> 
      : (<>Please wait a while while your file is loading: <b>{props.value}</b></>)

  return (
    <div>
      <Box className='modal-circle-loading'>
        <CircularProgress size={60}/>
      </Box>
      <div className='modal-circle-text'>
        <p>
           {displayMessage}
        </p>
      </div>  
    </div>
  )
}


export default function SimulationModalExport ({ open, setOpen, setting, hideMenu }) {

  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const request = useFetch()

  const value_bu = localStorage.getItem('bu_bpx')

  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState([])
  // const [count, setCount] = useState("")

  const [languageOption, setLanguageOption] = useState("EN")
  const countries = [
    { code: 'EN', label: 'User setup (standard is English EN)'},
    { code: 'PL', label: 'Polish PL' },
  ]

  
  let intervalCount

  const displayMessages = {
    getFileName: () => SnackbarUtils.success(JSON.stringify('The file was successfully added to the download')),
    getFileNameError: () => SnackbarUtils.error(JSON.stringify('No such file exists')),

    fileStatus: () => SnackbarUtils.success(JSON.stringify('Export complete')),
    fileStatusError: () => SnackbarUtils.error(JSON.stringify('No status file - generation of tmp0wbrv0a8.xlsxs failed?')),

    getFile: () => SnackbarUtils.success(JSON.stringify('Your file has been successfully downloaded')),
    getFileError: () => SnackbarUtils.error(JSON.stringify('The file was not downloaded')),
  }

  // useEffect(() => {

  //   request.fetchData(
  //     setting.endpointCount,
  //     'GET',
  //     null,
  //     false,
  //     tokenBpxRequest,
  //     false,
  //     false
  //   )
  //   .then(status => {
  //     setCount(status.rows_in_excel)
  //   })

  // }, [open])


  const getFile = (filename)=>{
    return request.fetchData(
      `${backend_url.get_excel_file}?filename=${filename}&business_unit=${value_bu}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false,
      true // Blob 
    )
    .then(data => {
      clearInterval(intervalCount)
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
      })
      exportBlob(blob, `${setting.fileName}.xlsx`)
      displayMessages.getFile()

      setOpen(false)
      setLoading(false)

    })
    .catch(e => {
      setLoading(false)
      displayMessages.getFileError()
    })

  }

  function downloadFile (filename) {
    intervalCount = setInterval(() => getFile(filename), 5000);
  }

  const getStatus = (fileName) => {
    request.fetchData(
      `${backend_url.get_excel_file_status}?filename=${fileName}&business_unit=${value_bu}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
    .then(fileStatus => {

        setProgress([fileStatus.export_status])

        //console.log(fileStatus)
        if (fileStatus.export_status[0].includes("Export complete")) {
          clearInterval(intervalCount);
          downloadFile(fileName);
        }

    })
    // .catch(() => {
    //   displayMessages.fileStatusError()
    // })
  }

  function getFileStatus (fileName) {
    intervalCount = setInterval(() => getStatus(fileName), 3000);
  }

  
  const requestExcel = useCallback(() => {
    setLoading(true)
    request.fetchData(
      setting.endpoint + '&LANG=' + languageOption,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
    .then(({ filename }) => {

      if (filename) {
        getFileStatus(filename)
      }
      
    })
    .catch(e => {
      displayMessages.getFileNameError()
      setLoading(false)
    })

  }, [displayMessages, request])


  const onCloseHandle = useCallback( () => {
    setOpen(false)
  }, [setOpen])

  const displayLoading = (
    <CircularProgressWithLabel value={progress} />
  )

  const displayText = (
    <div>
      <p>
        {/* You have selected <b>{count}</b> records to download. */}
        You have selected records to download.
        Proceed with export?
      </p>
      <p style={{ fontStyle: "italic" }}>
        Note: Large number of records (e.g. more than 1000) can take a long time to extract.
        Consider using extra filters before proceeding with download.
      </p>
    </div>
  )
  
  const displayScroller = (
    <div style={{ marginTop: "2rem", width: "100%" }}>
      <Autocomplete
        id="country-select-demo"
        options={countries}
        autoHighlight
        size='small'
        onChange={(e, v) => {
          setLanguageOption(v.code)
        }}
        defaultValue={countries[0]}
        getOptionLabel={(option) => option.label}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.label}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Choose a language"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', 
            }}
          />
        )}
      />
    </div>
  )

  const displayButtons = (
    <>
      <Button variant="outlined" onClick={onCloseHandle}>No</Button>
      <Button variant="contained" color="error" onClick={requestExcel} disabled={loading}>Yes</Button>
    </>
  )

  return (
    <Dialog
      open={open}
      onClose={onCloseHandle}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div className='dialog-fonts'>
        <DialogTitle id="alert-dialog-title">
          {"Data Export"}
        </DialogTitle>
        <DialogContent sx={{minHeight: "140px", minWidth: "600px"}}>
          {loading ? displayLoading : displayText }
          {/* {loading ? "" : displayScroller} */}
        </DialogContent>
        <DialogActions>
          {loading ? "" : displayButtons}
        </DialogActions>
      </div>
    </Dialog>
  )
}
