import { useState,useEffect } from "react"
import { Modal, Box, Typography, 
            Grid, Tabs, Tab, InputLabel} from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { PreferencesTab } from "./PreferencesTab";
import { SecurityTab } from "./SecurityTab";
import { LookAndFeel } from "./LookAndFeelTab";
import {useUserPrefs} from './ProvideUserPrefs'
import { useTranslation } from 'react-i18next'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 650,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    padding: '10px'
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

return (<div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
            )}
        </div>
    );
}



export const UserPreferencesModal = ({open, handleClose}) => {
    
    const { t } = useTranslation('translation')
    const currentUser = JSON.parse(localStorage.getItem('current_user'))
    const [value, setValue] = useState(0);
    const { prefsData, updateUserPrefsOnClose } = useUserPrefs();

    const preferencesTabs = [t("preferences.Preferences"), t("preferences.Security"), t("preferences.Look_and_feel")];
    const handleCloseUserPrefs = () => {
        updateUserPrefsOnClose()
        handleClose(false)
      }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    }

    return (
        <Modal
            open={open}
            onClose={handleCloseUserPrefs}
        >
            <Box sx={style}>
                <HighlightOffIcon sx={{cursor:'pointer', float:'right'}} onClick={handleCloseUserPrefs}/>
                <Grid container justifyContent={"space-around"}>
                    <Grid item>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            {currentUser.name} {currentUser.surname}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                            {currentUser.username}
                        </Typography>
                    </Grid>
                </Grid>
                <Box sx={{ width: '100%', paddingTop: "20px" }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} variant="fullWidth">
                            {preferencesTabs.map((pref, ind) => {
                                return (<Tab label={pref} key={pref} value={ind}/>)
                            })}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <PreferencesTab handleClose={handleCloseUserPrefs}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <SecurityTab handleClose={handleCloseUserPrefs}/>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <LookAndFeel handleClose={handleCloseUserPrefs}/>
                    </TabPanel>
                </Box>     
            </Box>
        </Modal>
    )
}
