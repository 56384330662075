import React from 'react';
import { backend_url } from '../../../../settings';
import BoxComponent from './BoxComponent';
import { useTranslation } from 'react-i18next'
import { CustomToolbarHome } from '../../../../components';

function HighlightsBoxes({ onSetDragActive, toggle, setToggle }) {

  const { t } = useTranslation('translation');

  const boxesData = [
    {
      title: `${t("Home.Highlights.activities")} ${t("Home.Highlights.inFlight")}`,
      url: backend_url.accrual_object_header,
      endpointDepens: [
        {
          field: 'business_unit', 
          name: 'bu_bpx'
        },
        {
          field: 'AO_STATUS',
          value: 'ACTIVE'
        }
      ],
      color: 'green',
      link: '/my-activities/activity_summary',
      forFilter: {
        field: "AO_STATUS",
        value: "ACTIVE"
      }
    },
    {
      title: t("Home.Highlights.overPayments"),
      url: backend_url.activities_payments,
      endpointDepens: [
        {
          field: 'business_unit', 
          name: 'bu_bpx'
        },
        {
          field: 'payment_flag',
          value: 'overpayments'
        }
      ],
      color: 'red',
      link: '/my-activities/activity_summary/overpayments',
      forFilter: {}
    },
    {
      title: t("Home.Highlights.payments"),
      url: backend_url.activities_payments,
      endpointDepens: [
        {
          field: 'business_unit', 
          name: 'bu_bpx'
        },
        {
          field: 'payment_flag',
          value: 'without_payments'
        }
      ],
      color: 'red',
      link: '/my-activities/activity_summary/without_payments',
      forFilter: {}
    },
    {
      title: t("Home.Highlights.tobeClosed"),
      url: backend_url.accrual_object_header,
      endpointDepens: [
        {
          field: 'business_unit', 
          name: 'bu_bpx'
        },
        {
          field: 'AO_STATUS',
          value: 'ENDED'
        }
      ],
      color: 'orange',
      link: '/my-activities/activity_summary',
      forFilter: {
        field: "AO_STATUS",
        value: "ENDED"
      }
    }
  ]

  const boxesComponent = boxesData.map((it, ind) => <BoxComponent key={ind} data={it} />)

  return (
    <div>
      <CustomToolbarHome 
        title={t("Home.Highlights.header")} 
        isResize={false} 
        current="HighlightsBoxes" 
        onSetDragActive={onSetDragActive}
        toggle={toggle}
        setToggle={setToggle}
      /> 
      <div className="bpx-chart-highlights-boxes">
        {boxesComponent}
      </div>
    </div>
  )

}

export default HighlightsBoxes
