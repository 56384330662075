import { CustomToolbar } from '../../components'
import { useTranslation } from 'react-i18next'

export default function AccessInfo () {
  const { t } = useTranslation('translation')
  const title = "Access Information Page"

  const breadCrumb = [
    { label: t("Breadcrumb.home"), link: '/' },
    { label: t("Breadcrumb.access"), link: '' },
  ]
  
  return (
    <div className='tableContainer'>

      <CustomToolbar 
        bread={breadCrumb} 
        title={title} 

      />
      
      <div className='bpx-bg-detail'>
        <div className='bpx-block-detail'>

          <div className='bpx-access-info-block'>
              <div className='bpx-access-info-section'>
                <h1>You do not have access to this page</h1>
                <p>If you have any questions, please contact support</p>
              </div>
          </div>

        </div> 

      </div>
    </div>
  )
}
