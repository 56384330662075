import React, { useState, useEffect, useCallback } from 'react'
import { Link } from "react-router-dom"
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Tooltip } from '@mui/material'
import { CustomToolbarHome } from '../../components/index'
import { backend_url } from '../../settings'
import { getParam } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { useFetch } from '../../hooks'
import { dateTimeFormatter } from '../../utils/utils'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { CustomLinkTable } from "../../components"
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import BuildCircleIcon from '@mui/icons-material/BuildCircle'
import DoneIcon from '@mui/icons-material/Done'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import SnackbarUtils from '../../utils/SnackbarUtils'


function AlertTodo({ onSetDragActive, toggle, setToggle }) {

  const { t } = useTranslation('translation')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const value_bu = localStorage.getItem('bu_bpx')
  // const tableHeight = JSON.parse(localStorage.getItem('tableHeight'))
  const userRoles = localStorage.getItem('userRoles')?localStorage.getItem('userRoles').split(","):''

  const request = useFetch()

  const [rows, setRows] = useState([])
  const [queryOptions, setQueryOptions] = useState({})
  const { prefsData } = useUserPrefs()
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)

  const actionsLinks = {
    REPROCESS: '/',
    MATERIALS: '/master-data/products',
    SUPPORT: '/support/alerts-todos-manager',
    CONFIGURATION: '/configuration'
  }

  const handleReprocess = (alert_id) => {
      request.fetchData(
        `${backend_url.alert_reprocess}?business_unit=${value_bu}&alert_id=${alert_id}`,
        'POST',
        null,
        false,
        tokenBpxRequest,
        false,
        false
      )
      .then(data => {
          if (data) {
              SnackbarUtils.success(`Todo successfully created: ${data.alert_id}`)
          }
      })
  }

  const columns = [
    {
      field: 'processingStage',
      headerName: t("Home.ToDo.state"),
      // flex: 0.09,
      width: 4,
      renderCell: (params) => {
        if (params.row.status === "processing" || params.row.status === "pending" || params.row.status === "triggered")
            return <div className="MuiDataGrid-cellContent"><AccessTimeIcon fontSize='small'/></div>
        else if (params.row.status === "new")
            return <div className="MuiDataGrid-cellContent"><ReportProblemIcon color='error' fontSize='small'/></div>
        else if (params.row.status === "closed")
            return <div className="MuiDataGrid-cellContent"><DoneIcon color='success' fontSize='small'/></div>
        else if (params.row.status === "error")
            return <div className="MuiDataGrid-cellContent"><ErrorOutlineIcon color='error' fontSize='small'/></div>
        else
            return <div className="MuiDataGrid-cellContent"><BuildCircleIcon fontSize='small'/></div>
      }
    },
    {
      field: 'created_at',
      headerName: t("Home.ToDo.created_at"),
      flex: 0.09,
      // width: 120,
      renderCell: (params) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(params.row.last_updated_at, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
    },
    {
      field: 'process',
      headerName: t("Home.ToDo.process"),
      flex: 0.09,
      // width: 90,
    },
    {
      field: 'description',
      headerName: t("Home.ToDo.description"),
      flex: 0.14,
      // width: 380,
      cellClassName: "description-cell",
      renderCell: (params) => (
        <Tooltip title={params.row.description} >
            <span className="table-cell-trucate">{params.row.description}</span>
        </Tooltip>
      )
    },
    {
      field: 'alert_id',
      headerName: t("Home.ToDo.alert_id"),
      flex: 0.12,
      // width: 70,
    },
    {
      field: 'status',
      headerName: t("Home.ToDo.status"),
      flex: 0.09,
      // width: 70,
    },
    {
      field: 'error_code',
      headerName: t("Home.ToDo.error_code"),
      flex: 0.09,
      minWidth: 50,
    },
    {
      field: 'run_id',
      headerName: t("Home.ToDo.run_id"),
      flex: 0.09,
      minWidth: 50,
    },
    {
      field: 'last_updated_at',
      headerName: t("Home.ToDo.last_updated_at"),
      flex: 0.09,
      minWidth: 50,
      renderCell: (params) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(params.row.last_updated_at, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
    },
    {
      field: 'updated_by',
      headerName: t("Home.ToDo.updated_by"),
      flex: 0.09,
      minWidth: 50,
    },
    {
      field: "actions",
      type: "actions",
      headerName:t("Home.ToDo.action"),
      // flex: 0.09,
      width: 60,
      headerAlign: 'left',
      hideable: false,
      getActions: (params) => {
          let actions = params.row.actions
          if (params.row.status !== "new" && params.row.status !== "retry_failed") {
              actions = actions?.filter(action => action !== "REPROCESS" && action !== "RETRY")
          }

          const links = actions ? actions.map((action) => {
            if (action === "REPROCESS" || action === "RETRY") {
                  return (
                        <a
                            style={{ color: "#f69e80" }}
                            onClick={() => handleReprocess(params.row.alert_id)}
                            className="dark-mode-link-color"
                        >
                            {action}
                        </a>
                  )
            }

            return (
              <Link
                style={{ color: "#f69e80", textDecoration:'none' }}
                to={actionsLinks[action]}
                className="dark-mode-link-color">
                    {action}
              </Link>)
          }) : []

          return [
              <CustomLinkTable
                id={params.row.id}
                links={links}
              />
          ]
      }
    }
  ]

  const addLoadedRows = useCallback((items, rows) => {
    const changeIdRow = items.map((d, ind) => ({
      ...d,
      id: d._id,
      idx: rows.length + ind + 1,
      alert_id: d._id,
      process: d.dag_id
    }))

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [queryOptions])

  useEffect(() => {

    const queryParams = {
      business_unit: value_bu,
      limit: 100,
      skip: page,
      include_todos: false,
      ...queryOptions
    }
    const searchParam = getParam(queryParams).toString()

    request.fetchData(
      `${backend_url.alert_todo}?${searchParam}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
      .then(data => {
        addLoadedRows(data, rows)
        setCount(data.length)
      })


  }, [queryOptions, page])


  const onFilterChange = useCallback((filterModel) => {

    filterModel.items.map(cc => {

      if (cc.value) {
        setQueryOptions({ [cc.columnField]: cc.value })
      } else {
        setQueryOptions()
      }

    })

  }, [])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const checkActionAccess = () => {
      if (userRoles?.includes("AE Full Access") || userRoles?.includes("Super User") || userRoles?.includes("System User")) {
          return true
      }
      return false
  }

  return (
    <DataGridPro
      sx={{ '& .MuiDataGrid-main': {
          height: (prefsData.tableHeight && prefsData.tableHeight.AlertAndTodo) && prefsData.tableHeight.AlertAndTodo + "px"
        },
      }} 
      rows={rows}
      columns={columns}
      pageSize={50}
      hideFooter={true}
      loading={rows.length === 0}
      filterMode="server"
      onFilterModelChange={onFilterChange}
      onRowsScrollEnd={handleOnRowsScrollEnd}
      // density="compact"
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
            error_code: false,
            run_id: false,
            last_updated_at: false,
            updated_by: false,
            actions: checkActionAccess()
          },
        },
      }}
      localeText={{
        toolbarColumns: t("Buttons.Columns"),
        noRowsLabel: t("Common.noRows")
      }}
      components={{
        Toolbar: CustomToolbarHome,
      }}
      componentsProps={{
        toolbar: {
          title: t("Home.Highlights.alerts_and_todo"),
          isResize: true,
          current: "AlertAndTodo",
          onSetDragActive: onSetDragActive,
          toggle: toggle,
          setToggle: setToggle,
          showColumns: true
        }
      }}
    />
  )

}

export default AlertTodo
