
import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro, GridOverlay,GridActionsCellItem } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import { backend_url } from '../../settings'
import { useTranslation } from 'react-i18next'
import { CustomToolbar,SwitchEdit } from '../../components'
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../mui-components/CustomPanel'
import { Link } from 'react-router-dom'
import { useFetch, usePermissions } from '../../hooks'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { getParam, numberFormatter, checkObjectKey, applyFilters } from "../../utils/utils"
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import { exportBlob, checkSize  } from '../../utils/utils'

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


export default function DownloadManager() {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkDownloadDetails = checkObjectKey(obj, childObj, "Download_details")

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [countFiles, setCountFiles] = useState([])


  //Download Manager
  const [switcher, setSwitcher] = useState(false)

  const [exportQuery, setExportQuery] = useState("")
  const { prefsData, saveUserPrefs } = useUserPrefs()

  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.DownloadManager || 'm');

  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "ACTIVITY_ID",
      title: t("AOHeaderList.ACTIVITY_ID"),
      inputText: t("ActivitySummaryPage.ACTIVITY_ID_INPUT"),
      firstKeyOption: "ACTIVITY_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("ACTIVITY_ID", "activitySummary"),
      changingArray: [],  
      atLeastThreeLetter: true,
      formatField: "select",
    },

    /*
    Dodałem   mydownload_list: `${base_url}/get-excel-list`, w settings.js
    Narazie filty nie potrzebne
    {
      endpoint: backend_url.mydownload_list,
      queryKeyToTyping: "XXX",
      title: t("AOHeaderList.ACTIVITY_ID"),
      inputText: t("ActivitySummaryPage.ACTIVITY_ID_INPUT"),
      firstKeyOption: "ACTIVITY_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("ACTIVITY_ID", "activitySummary"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },


    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "EXT_SPEND_TYPE",
      title: t("ActivitySummaryPage.SPEND_TYPE"),
      inputText: t("ActivitySummaryPage.SPEND_TYPE_INPUT"),
      firstKeyOption: "EXT_SPEND_TYPE",
      secondKeyOption: "",
      valueFilter: applyFilters("EXT_SPEND_TYPE", "activitySummary"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "EXT_CATEGORY",
      title: t("ActivitySummaryPage.CATEGORY"),
      inputText: t("ActivitySummaryPage.CATEGORY_INPUT"),
      firstKeyOption: "EXT_CATEGORY",
      secondKeyOption: "",
      valueFilter: applyFilters("EXT_CATEGORY", "activitySummary"),
      changingArray: [],
      formatField: "select"
    },
    {
      endpoint: backend_url.activity_summary_filter_values,
      queryKeyToTyping: "AO_STATUS",
      title: t("ActivitySummaryPage.STATUS"),
      inputText: t("ActivitySummaryPage.STATUS_INPUT"),
      firstKeyOption: "AO_STATUS",
      secondKeyOption: "",
      valueFilter: applyFilters("AO_STATUS", "activitySummary"),
      changingArray: [],
      formatField: "select"
    }, */

  ])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const onHandleSwitcher = (state) => {
    setSwitcher(!state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map((d,id) => {
      return {
        id: d.filename + '' + d.started_at,
        //ACTIVITY_ID: d.ACTIVITY_ID,
        //EXT_CLASS: d.EXT_CLASS,
        ...d,
        //EXT_SPEND_TYPE: t("EXT_SPEND_TYPE." + d.EXT_SPEND_TYPE, { ns: 'mars_dictionaries' }),
        //EXT_CATEGORY: t("EXT_CATEGORY." + d.EXT_CATEGORY, { ns: 'mars_dictionaries' }),
      }
    }
    )

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [])

  useEffect(() => {

    setLoading(true)

    var queryParams = {
      business_unit: value_bu,
      limit: 100,
      skip: page,
      show_all: switcher
    }

    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField } = key

      switch (formatField) {
        case "select":
          if (valueFilter.length) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
      activitySummary: queryParams
    }
    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["activitySummary"] = queryParams
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))

    const queryString = getParam(queryParams).toString()

    setExportQuery(queryString)
console.log(queryString)
    request.fetchData(
      `${backend_url.mydownload_list}?${queryString}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      if(data.length){
        setLoading(false)
        addLoadedRows(data[0])
        setCount(data[0].length)
        setCountFiles(data.slice(1))
      }
    })

  }, [page, switcher])

  
  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const handleDownloadClick = (id) => () => {
    //Formatted Marcin Nowak Code
    const row = rows.find(it => it.id ===id)
    const fileName = row.filename.slice(0,-7)

    if(row){

      request.fetchData(
        `${backend_url.get_excel_file}?filename=${fileName}&business_unit=${value_bu}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false,
        true // Blob
      )
      .then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        })
        exportBlob(blob, fileName)
        setRows(rows.filter(row=>row.id!=id));
      })

    }

  }

  const handleCancelClick = (id)=> () =>{

    const row = rows.find(it=>it.id===id)
    const fileName = row.filename.slice(0,-7)

     if(row){

      request.fetchData(
        `${backend_url.mydownload_cancel}?filename=${fileName}&business_unit=${value_bu}`,
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        false,
        true // Blob
      )
      .then(
        (data) => {
          setRows(
            rows.map(it => {
              if (it.id === id) {
                row.content = 'Canceled'
              }
              //return it
            })
          )
        }
      )

     }

  }

  
  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: t("download_manager.actions"),
      width: 60,
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
    
        const downloadBtn = [
          <GridActionsCellItem
            icon={<ArrowCircleDownIcon />}
            label="Download"
            className="textPrimary"
            onClick={handleDownloadClick(id)}
            color="primary"
          />,
        ]

        const cancelBtn = [
          <GridActionsCellItem
          icon={<DoDisturbIcon />}
          label="Cancel"
          className="textPrimary"
          onClick={handleCancelClick(id)}
          color="error"
        />,
        ]

        if(row.content && row.content === 'Export complete'){
          return downloadBtn
        }
        if(row.content && row.content.includes('Generated')){
          return cancelBtn
        }
        return []
      },
    },
    { 
      field: 'filename', 
      headerName: t("download_manager.filename"),
    },
    { 
      field: 'content', 
      headerName: t("download_manager.status"),
      flex: 1 
    },
    { 
      field: 'requester', 
      headerName: t("download_manager.requester"),
      flex: 1 
    },
    { 
      field: 'report_page', 
      headerName: t("download_manager.report_page"),
      flex: 1 
    },
    { 
      field: 'business_unit', 
      headerName: t("download_manager.business_unit"), 
      flex: 1 
    },
    { 
      field: 'started_at', 
      headerName:  t("download_manager.started_at"), 
      flex: 1 
    },
    { 
      field: 'expected_end_time', 
      headerName: t("download_manager.finished_at"), 
      flex: 1 
    },
  ]

  const objBreadcrumb = [
    { label: t('Breadcrumb.home'), link: '/' },
    { label: t('Breadcrumb.download_manager'), link: '/download' },
  ]

  const titleCountFiles = checkDownloadDetails&&countFiles.length? `(${t("download_manager.total")}: ${countFiles[0]} / ${t("download_manager.user")}: ${countFiles[1]} )` : ''

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        DownloadManager: value
      }
    })
  }

  return (
    <div className="tableContainer">
      <DataGridPro
        rows={rows}
        columns={columns}
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }
        }} 
        loading={loading}
        disableSelectionOnClick={true}
        //disableColumnFilter
        onRowsScrollEnd={handleOnRowsScrollEnd}
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: `${t("download_manager.title")} ${titleCountFiles}`,
            displayStandartReportButton: true,
            extraButton: [
              {
                headerName: "",
                anotherButton: <div className='toolbarIconSection toolbarSwitch' key="down_manager"><SwitchEdit onSwitcher={onHandleSwitcher} switcher={switcher} loading={loading} text={t("Buttons.Show_All")}/></div>,
                disabled: false,
                isHide: !checkDownloadDetails,
              },
            ],
            loading: loading,
            showExport: false,
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            /*
            filters:
              <CustomMultipleFilter 
                onFilter={changeFilterValues} 
                dataFilter={filter} 
                loading={loading}
              />,*/
              /*
            exportSetting: {
              endpoint: backend_url.activity_summary_excel_file + '?' + exportQuery,
              endpointCount: backend_url.activity_summary_excel_count + '?' + exportQuery,
              fileName: "Activity Summary Report"
            }*/
          }
        }}
      />
    </div>
  )
} 
