import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { CustomToolbarHome, CustomActionTable } from '../../components/index'
import { backend_url } from '../../settings'
import { Link } from 'react-router-dom'
import { getParam, checkObjectKey } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { numberFormatter } from '../../utils/utils'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress'
import { Box, Typography, ThemeProvider, } from "@mui/material"
import { useFetch, usePermissions } from '../../hooks'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs";
import Stack from '@mui/material/Stack'


function NumberLinearProgress(props) {

  var prop_color = "error"
  if (props.value >= 0 && props.value <= 33) {
    prop_color = "success"
  } 
  else if (props.value > 33 && props.value <= 66) {
    prop_color = "warning"
  }

  return (
    <Box position="relative" display="inline-block"
      sx={{
        mx: "auto", width: 100, height: 17
      }}
    >
      <Box top={0} left={0} bottom={0} right={0} position="absolute" >
        <LinearProgress variant="determinate" color={prop_color} value={parseInt(props.value, 10)}

          sx={{
            borderRadius: 0,
            padding: '8px 30px 8px 30px',  
          }}
        />
      </Box>
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography sx={{ textAlign: "center", fontSize: 10 }} color={prop_color === "error"?"#fff":"textPrimary"}>{`${
          props.value
        }%`}</Typography>
      </Box>
    </Box>
  );
}


function ActivitiesTotal ({ onSetDragActive, toggle, setToggle }) {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkActivityID = checkObjectKey(obj, childObj, "Activity_ID")

  const [rows, setRows] = useState([])
  const [queryOptions, setQueryOptions] = useState({})
  const [grid_loading, setGridLoading] = useState(false)
  const { prefsData } = useUserPrefs()

  const columns = [
    { field: 'id', headerName: 'id', filterable: false, hide: true },
    { 
      field: 'ACTIVITY_ID', 
      headerName: t("Home.Activities.ACTIVITY_ID"), 
      hideable: false, 
      flex: 0.15,
      minWidth: 50,
      renderCell: (params) => {
        
        if (checkActivityID) {
          return (
            <div className="MuiDataGrid-cellContent">
              <Link style={{ color: '#f69e80' }} to={`/my-activities/accrual_object/${params.row.id}`} className="dark-mode-link-color">
                {params.value}
              </Link>
            </div>
          )
        }
        
      } 
    },
    { 
      field: 'BUSINESS_PARTNER', 
      headerName: t("Home.Activities.BUSINESS_PARTNER"), 
      flex: 0.20,
      minWidth: 50,
      renderHeader: ({colDef})=>{
        if(colDef.width < 100){
          return (
            <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
            aria-label="Plan Account" 
            data-mui-internal-clone-element="true">
                {t("Home.Activities.BUSINESS_PARTNER_SHORT")}
            </div>
          )
        }
        return (
          <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
            aria-label="Plan Account" 
            data-mui-internal-clone-element="true">
              {t("Home.Activities.BUSINESS_PARTNER")}
          </div>
        )
      },
    },
    { 
      field: 'EXT_CLASS', 
      headerName: t("Home.Activities.EXT_CLASS"), 
      flex: 0.19,
      minWidth: 50,
      hideable: false, 
      renderHeader: ({colDef})=>{
        if(colDef.width < 100){
          return (
            <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
            aria-label="Investment description" 
            data-mui-internal-clone-element="true">
                {t("Home.Activities.EXT_CLASS_SHORT")}
            </div>
          )
        }
        return (
          <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
            aria-label="Investment description" 
            data-mui-internal-clone-element="true">
              {t("Home.Activities.EXT_CLASS")}
          </div>
        )
      },
    },
    { 
      field: 'STATUS', 
      headerName: t("Home.Activities.STATUS"), 
      flex: 0.10,
      minWidth: 50,
      hideable: false 
    },
    { 
      field: 'TIME_PASSED', 
      headerName: t("Home.Activities.TIME_PASSED"), 
      flex: 0.10,
      minWidth: 50,
      hideable: false,
      renderCell: (params) => (
        <NumberLinearProgress value={params.value}/>
      )
    },
    { 
      field: 'FORMATED_ACCRUALS', 
      headerName: t("Home.Activities.ACCRUALS"), 
      flex: 0.10,
      minWidth: 50,
      hideable: false 
    },
    { 
      field: 'FORMATED_PAYMENTS', 
      headerName: t("Home.Activities.PAYMENTS"), 
      flex: 0.10,
      minWidth: 50,
      hideable: false 
    },
    { 
      field: 'CURRENCY', 
      headerName: t("Home.Activities.CURRENCY"), 
      flex: 0.06,
      minWidth: 50, 
      hideable: false 
    },
  ]

  
  useEffect(() => {

    const queryParams = {
      limit: 100,
      business_unit: value_bu,
      ...queryOptions
    }
    const searchParam = getParam(queryParams).toString()

    request.fetchData(
      `${backend_url.activities_total}?${searchParam}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false,
      false
    )
    .then(data => setRows(
      data.map(d => ({
          id: d.AO_ID,
          BUSINESS_PARTNER: d.PLAN_ACCOUNT_BP_CAT + d.PLAN_ACCOUNT_BP_NR + ' - ' + d.BUSINESS_PARTNER_NAME,
          FORMATED_ACCRUALS: d.ACCRUALS && numberFormatter(prefsData.decimal_notation).format(d.ACCRUALS),
          FORMATED_PAYMENTS: d.PAYMENTS && numberFormatter(prefsData.decimal_notation).format(d.PAYMENTS),
          ...d
      }))
    ))

  }, [queryOptions, prefsData.decimal_notation])


  const onFilterChange = useCallback((filterModel) => {

    filterModel.items.map(cc => {

      if (cc.value) {
        setQueryOptions({ [cc.columnField]: cc.value })
      } else {
        setQueryOptions()
      }

    })

  }, [])

  
  return (
    <DataGridPro
      sx={{ '& .MuiDataGrid-main': {
       height: (prefsData.tableHeight && prefsData.tableHeight.ActivitiesTotal) && prefsData.tableHeight.ActivitiesTotal + "px"
      } }} 
      rows={rows}
      columns={columns}
      pageSize={50}
      hideFooter={true}
      loading={grid_loading}
      filterMode="server"
      density="compact"
      disableColumnFilter={true}
      onFilterModelChange={onFilterChange}
      localeText={{
        noRowsLabel: t("Common.noRows")
      }}
      keepNonExistentRowsSelected
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      components={{
        Toolbar: CustomToolbarHome,
        NoRowsOverlay: () => {
          return (
            <Stack height="100%" alignItems="center" justifyContent="center">
              { 
                childObj.hasOwnProperty("My_Activities_in_Totals") 
                  ? "No rows"
                  : "No authorization" 
              }
            </Stack>
          )
        }
      }}
      componentsProps={{
        toolbar: {
          title: t("Home.Highlights.my_activities_in_totals"),
          isResize: true,
          current: "ActivitiesTotal",
          onSetDragActive: onSetDragActive,
          toggle: toggle, 
          setToggle: setToggle
        }
      }}
    />
  )

}

export default ActivitiesTotal
