import { GridOverlay } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"


export default function Loading () {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  )
}
