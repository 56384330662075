import React, { useEffect, useState } from 'react'
import { dateTimeFormatter } from "../../../../utils/utils"
import { useUserPrefs } from "../../../UserPreferences/ProvideUserPrefs";

export default function List (props) {

  const {userData, warning} = props;
  const [list, setList] = useState({})
  
  const { prefsData } = useUserPrefs()

  useEffect(()=>{
    setList(userData)
  },[userData])

  const obj = [
    {
      id: "1",
      label: "User ID:",
      value: list.EMAIL,
    },
    {
      id: "2",
      label: "Valid From:",
      value: list.DATE_VALID_FROM,
      renderCell: (value) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
    },
    {
      id: "3",
      label: "First Name:",
      value: list.NAME,
    },
    {
      id: "4",
      label: "Valid To:",
      value: list.DATE_VALID_TO,
      renderCell: (value) => dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone),
    },
    {
      id: "5",
      label: "Last Name:",
      value: list.SURNAME,
    },
    {
      id: "6",
      label: "Status:",
      value: list.isActive,
    },
    {
      id: "7",
      label: "Phone Number:",
      value: list.PHONE_NUM,
    }
  ]


  const displayItems = () => {
    
    return obj.map(({ id, label, value, renderCell }) => {
      const renderValue = renderCell? renderCell(value): value;
      return (
        <div className="bpx-auth-section" key={id}>
          <span className="bpx-auth-left">
            { label }
          </span>
          <span className="bpx-auth-right">
            { renderValue }
          </span>
        </div>
      )
    })

  }

  return (
    <>
      <div className="bpx-auth-list">
          { displayItems() }
      </div>
      { warning &&
        <div className=" toolbarTitle bpx-auth-warning">
          <span><b>Warning:</b> AE Business Unit Basic role is mandatory</span>
        </div>
      }
    </>
  )

}
