import {
  GridPanel,
  useGridApiContext,
  useGridSelector,
  gridPreferencePanelStateSelector,
} from '@mui/x-data-grid-pro'


const CustomPanel = (props) => {
  const apiRef = useGridApiContext()
  const preferencePanelState = useGridSelector(
    apiRef,
    gridPreferencePanelStateSelector
  )

    let anchorEl 

    if (preferencePanelState.openedPanelValue === "columns") {
      anchorEl = document.getElementById("columnAnchor")
    }

    if (preferencePanelState.openedPanelValue === "filters") {
      anchorEl = apiRef.current.columnHeadersElementRef.current
    }

  return <GridPanel {...props} anchorEl={anchorEl} />
}

export default CustomPanel
