import React, { useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { LinearProgress } from "@mui/material"
import { useFetch } from '../../hooks'
import { backend_url } from '../../settings'
import { DataGridPro, useGridApiRef, GridOverlay } from '@mui/x-data-grid-pro'
import { Box } from '@mui/material'
import CustomMultipleFilter from '../../mui-components/CustomMultipleFilter'
import { CustomToolbar } from "../../components"
import CustomPanel from '../../mui-components/CustomPanel'
import { dateTimeFormatter, applyFilters, getParam, checkSize  } from '../../utils/utils'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"


function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}

export const AuthAuditData = () => {

    const apiRef = useGridApiRef();

    const { t } = useTranslation('translation')
    const value_bu = localStorage.getItem('bu_bpx')
    const tokenBpxRequest = localStorage.getItem('token_bpx')

    const params = useParams()
    const request = useFetch()

    const [rows, setRows] = useState([])
    const [page, setPage] = useState(0)
    const [count, setCount] = useState(0)
    const [loading, setLoading] = useState(false)
    const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
    const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.Auth_Audit_Data || [])
    const [exportQuery, setExportQuery] = useState("")
    // const [skip, setSkip] = useState(0)
    // const [limit, setLimit] = useState(10000)

    const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Auth_Audit_Data || 'm');

    const columnVisibility = {}

    const [filter, setFilter] = useState([
      {
        endpoint: backend_url.user_bus,
        queryKeyToTyping: "BUSINESS_UNIT",
        title: t("AuthAuditData.BUSINESS_UNIT"),
        inputText: t("AuthAuditData.BUSINESS_UNIT_INPUT"),
        firstKeyOption: "BUSINESS_UNIT",
        secondKeyOption: "",
        valueFilter: applyFilters("BUSINESS_UNIT", "auth_audit_data"),
        changingArray: [],
        noMultiple: true,
        formatField: "select",
      },
      {
        endpoint: backend_url.auth_audit_data_filters,
        firstKeyOption: "role_valid_from",
        title: t("AuthAuditData.DATE_VALID_FROM_ROLE"),
        valueFilter: applyFilters("role_valid_from", "auth_audit_data"),
        formatField: "date"
      },
      {
        endpoint: backend_url.auth_audit_data_filters,
        firstKeyOption: "role_valid_to",
        title: t("AuthAuditData.DATE_VALID_TO_ROLE"),
        valueFilter: applyFilters("role_valid_to", "auth_audit_data"),
        formatField: "date"
      },
      {
        endpoint: backend_url.auth_audit_data_filters,
        queryKeyToTyping: "STATUS",
        title: t("AuthAuditData.STATUS"),
        inputText:  t("AuthAuditData.STATUS_INPUT"),
        firstKeyOption: "STATUS",
        secondKeyOption: "",
        valueFilter: applyFilters("STATUS", "auth_audit_data"),
        changingArray: [],
        formatField: "select",
        noMultiple: true,
        noObject: true
      },
      {
        endpoint: backend_url.auth_audit_data_filters,
        queryKeyToTyping: "USER_ID",
        title: t("AuthAuditData.USER_ID"),
        inputText: t("AuthAuditData.USER_ID_INPUT"),
        firstKeyOption: "USER_ID",
        secondKeyOption: "",
        valueFilter: applyFilters("USER_ID", "auth_audit_data"),
        changingArray: [],
        formatField: "select",
        atLeastThreeLetter: true,
        noObject: true
      },
      {
        endpoint: backend_url.auth_audit_data_filters,
        queryKeyToTyping: "ROLE",
        title: t("AuthAuditData.ROLE_ID"),
        inputText: t("AuthAuditData.ROLE_ID_INPUT"),
        firstKeyOption: "ROLE",
        secondKeyOption: "",
        valueFilter: applyFilters("ROLE", "auth_audit_data"),
        changingArray: [],
        formatField: "select",
        atLeastThreeLetter: true,
        noObject: true
      },
      {
        endpoint: backend_url.auth_audit_data_filters,
        queryKeyToTyping: "DIMENSION_VALUE",
        title: t("AuthAuditData.DIMENSION_VALUE"),
        inputText: t("AuthAuditData.DIMENSION_VALUE_INPUT"),
        firstKeyOption: "DIMENSION_VALUE",
        secondKeyOption: "DIMENSION_NAME",
        valueFilter: applyFilters("DIMENSION_VALUE", "auth_audit_data"),
        formatField: "select",
        atLeastThreeLetter: true,
      },
    ])

    if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.Auth_Audit_Data){
      prefsData.reports_column_visibilty.Auth_Audit_Data.forEach(it => columnVisibility[it] = false)
    }

  
    const saveColumnVisibility = (data) => {
      saveUserPrefs({
        ...prefsData,
        reports_column_visibilty:{
          ...prefsData.reports_column_visibilty,
          Auth_Audit_Data: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
        }
      })
    }

    const addLoadedRows = useCallback((items) => {
        const changeIdRow = items.map((d) => {
          return {
            ...d,
            id: d.USER_ID +'_'+ d.DATE_VALID_FROM_ROLE +'_'+ d.ROLE_ID + '_' + 
            d.DIMENSION_ID + '_' + d.DIMENSION_VALUE + '_' + d.SEQUENCE + d.DATE_VALID_TO_ROLE + d.DATE_VALID_FROM + d.DATE_VALID_TO,
            DIMENSION_VALUE_NAME: d.BP_NAME ? d.DIMENSION_VALUE + ' - ' + d.BP_NAME : d.DIMENSION_VALUE,
            USER_ACTIVE: d.user_in_validity_period ? "Active": "Inactive",
          }
        })
    
        setRows((old) => {
          return [...old, ...changeIdRow]
        })
    
    }, [])

    useEffect(() => {
        setLoading(true)

        const queryParams = {
          business_unit: value_bu,
          // limit: limit,
          // skip: skip,
        }
    
        filter.map((key) => {
          const { valueFilter, firstKeyOption, formatField, translateJSON } = key
          switch (formatField) {
            case "select":
              if (valueFilter) {
                if (valueFilter.length) {
                  if (firstKeyOption === "BUSINESS_UNIT") {
                      queryParams["business_unit"] = valueFilter
                  } else {
                      if (translateJSON) {
                        
                        const getKey = valueFilter.map(jsonkey => Object.keys(jsonkey))
                        queryParams[firstKeyOption] = getKey
          
                      } else {
                        queryParams[firstKeyOption] = valueFilter
                      }
                  }
                }else if(typeof valueFilter === 'object'){
                  queryParams[firstKeyOption] = valueFilter
                }
                if (valueFilter["BUSINESS_UNIT"] && firstKeyOption === "BUSINESS_UNIT") {
                  queryParams["business_unit"] = valueFilter["BUSINESS_UNIT"]
                }
              }
              break
            case "date":
              if (valueFilter) {
                queryParams[firstKeyOption] = valueFilter
              }
              break
            default:
              return null
          }
        })
        let cachedfilters = localStorage.getItem("filters")
        let filterObj = {
          auth_audit_data: queryParams
        }
        if (cachedfilters && Object.keys(cachedfilters).length) { 
            filterObj = JSON.parse(cachedfilters)
            filterObj["auth_audit_data"] = queryParams
        }
        localStorage.setItem("filters", JSON.stringify(filterObj))
    
        const queryString = getParam(queryParams).toString()
    
        setExportQuery(queryString)

        localStorage.setItem("currentUrl", window.location.pathname)

        request.fetchData(
          `${backend_url.auth_audit_data}?${queryString}`,
          'GET',
          null,
          false,
          tokenBpxRequest
        )
          .then(data => {
              setLoading(false)
              addLoadedRows(data)
              setCount(data.length)
          })

    }, [filter, page])

    useEffect(() => {
        return apiRef.current.subscribeEvent('columnHeaderDragEnd', async (params) => {
            await silentUpdateUserPrefs()
        })
    }, [apiRef])

    const changeFilterValues = (state) => {
        setFilter(state)
        setRows([])
        setPage(0)
    }

    const columns = React.useMemo(() => {
        const allColumns = {
            id: { 
                field: 'id', 
                headerName: 'id' 
            },
            USER_ID: { 
                field: 'USER_ID', 
                headerName: t("AuthAuditData.USER_ID"), 
                flex: 0.12,
                minWidth: 250,
            },
            NAME: { 
                field: 'NAME', 
                headerName: t("AuthAuditData.NAME"), 
                flex: 0.08,
                minWidth: 50,
            },
            SURNAME: { 
                field: 'SURNAME', 
                headerName: t("AuthAuditData.SURNAME"), 
                flex: 0.08,
                minWidth: 50,
            },
            DATE_VALID_FROM: { 
                field: 'DATE_VALID_FROM', 
                headerName: t("AuthAuditData.DATE_VALID_FROM"), 
                flex: 0.08,
                minWidth: 50,
                renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
            },
            DATE_VALID_TO: { 
                field: 'DATE_VALID_TO', 
                headerName: t("AuthAuditData.DATE_VALID_TO"), 
                flex: 0.08,
                minWidth: 50,
                renderCell: ({ value }) => {
                    if (value < new Date())
                        return <div className="MuiDataGrid-cellContent" style={{ color: "#ff0000" }}>{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
                    return <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
                } 
            },
            USER_ACTIVE: { 
                field: 'USER_ACTIVE', 
                headerName: t("AuthAuditData.USER_ACTIVE"), 
                flex: 0.08,
                minWidth: 50,
            },
            LAST_LOGIN: { 
                field: 'LAST_LOGIN', 
                headerName: t("AuthAuditData.LAST_LOGIN"), 
                flex: 0.08,
                minWidth: 130,
                renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
            },
            ROLE_ID: { 
                field: 'ROLE_ID', 
                headerName: t("AuthAuditData.ROLE_ID"), 
                flex: 0.08,
                minWidth: 180,
            },
            DIMENSION_ID: { 
                field: 'DIMENSION_ID', 
                headerName: t("AuthAuditData.DIMENSION_ID"), 
                flex: 0.08,
                minWidth: 200,
            },
            DIMENSION_VALUE_NAME: { 
                field: 'DIMENSION_VALUE_NAME', 
                headerName: t("AuthAuditData.DIMENSION_VALUE"), 
                flex: 0.08,
                minWidth: 250,
            },
            DATE_VALID_FROM_ROLE: { 
                field: 'DATE_VALID_FROM_ROLE', 
                headerName: t("AuthAuditData.DATE_VALID_FROM_ROLE"), 
                flex: 0.08,
                minWidth: 50,
                renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
            },
            DATE_VALID_TO_ROLE: { 
                field: 'DATE_VALID_TO_ROLE', 
                headerName: t("AuthAuditData.DATE_VALID_TO_ROLE"), 
                flex: 0.08,
                minWidth: 50,
                renderCell: ({ value }) =>  {
                    if (value < new Date())
                        return <div className="MuiDataGrid-cellContent" style={{ color: "#ff0000" }}>{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
                    return <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
                }
            } 
        }
        return  orderedColumns.length?orderedColumns.reduce((acc, field) => {
          return [...acc, allColumns[field]];
        }, []):Object.values(allColumns);
      }, [orderedColumns]);


    const handleColumnOrderChange = React.useCallback((params) => {
        setOrderedColumns((prevOrderedColumns) => {
          const newOrderedColumns = [...prevOrderedColumns];
          const oldIndex = params.oldIndex;
          const targetIndex = params.targetIndex;
          const oldColumn = prevOrderedColumns[oldIndex];
          newOrderedColumns.splice(oldIndex, 1);
          newOrderedColumns.splice(targetIndex, 0, oldColumn);
          saveUserPrefsWithoutRefresh({
            ...prefsData,
            reports_column_order: {
              ...prefsData.reports_column_order,
              Auth_Audit_Data: newOrderedColumns
            }
          })
          return newOrderedColumns;
        });
      }, []);

    const onChangeFontSize = (value)=>{
        setFontSize(value)
        saveUserPrefs({
          ...prefsData,
          reports_column_size:{
            ...prefsData.reports_column_size,
            Auth_Audit_Data: value
          }
        })
      }

    return (
        <div className="tableContainer">
        <DataGridPro
            apiRef={apiRef}
            sx={{ 
            '& .MuiDataGrid-row': {
                minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
                height: 'auto !important'
            },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            },
            '& .MuiDataGrid-main': { 
              fontSize: checkSize(fontSize, 'font_size'),
              height: 'calc(100vh - 365px)'
            }
            }} 
            rows={rows}
            columns={columns}
            loading={loading}
            disableSelectionOnClick={true}
            sortingOrder={['desc', 'asc']}
            disableColumnFilter
            getRowHeight={() => 'auto'}
            headerHeight={checkSize(fontSize, 'header_height')}
            onColumnOrderChange={handleColumnOrderChange}
            rowThreshold={0}
            onColumnVisibilityModelChange={saveColumnVisibility}
            onPreferencePanelClose={()=> updateUserPrefsOnClose()}
            localeText={{
              toolbarExportPrint: 'Download as PDF',
              toolbarColumns: t("Buttons.Columns"),
              toolbarExport: t("Buttons.Export"),
              noRowsLabel: t("Common.noRows")

            }}
            initialState={{
              columns: {
                  columnVisibilityModel:columnVisibility
              },
            }}
            components={{
                Toolbar: CustomToolbar,
                LoadingOverlay: CustomLoadingOverlay,
                Panel: CustomPanel
            }}
            componentsProps={{
              toolbar: {
                bread: [],
                title: t("Title.AuthAuditData"),
                displayStandartReportButton: true,
                exportSetting: {
                    endpoint: backend_url.audit_list_excel + '?' + exportQuery,
                    endpointCount: backend_url.audit_list_count + '?' + exportQuery,
                    fileName: `AuditReport_${dateTimeFormatter(new Date().toISOString(), prefsData.date_format, prefsData.time_format, prefsData.time_zone).replace(" ", "_")}`,
                    showFiltersInPDF: true,
                },
                showUserGen: true,
                filterValue: filter,
                pdfExport: true,
                isFontSize: true,
                fontSize: fontSize,
                onChangeFontSize: onChangeFontSize,
                filters:
                  <CustomMultipleFilter
                    onFilter={changeFilterValues}
                    dataFilter={filter}
                    loading={loading}
                  />
              }
            }}
        />
    </div>
    )
}