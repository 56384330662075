
import React, { useState } from 'react'
import { Divider, Menu, MenuItem } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import PowerSettingsNewSharpIcon from '@mui/icons-material/PowerSettingsNewSharp'
import FileDownload from '@mui/icons-material/FileDownload'
import { ReactComponent as Tools } from '../../assets/images/tools.svg'
import { ReactComponent as Head } from '../../assets/images/head.svg'
import LanguageIcon from '@mui/icons-material/Language';
import KeyIcon from '@mui/icons-material/Key';
import DownloadIcon from '@mui/icons-material/Download';

import { NestedMenuItem } from 'mui-nested-menu';

import { useNavigate, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth, useUserPrefs } from '../../pages'
import { usePermissions } from '../../hooks'
import { checkObjectKey } from '../../utils/utils'
import { useOktaAuth } from '@okta/okta-react';


function MenuSandwich (props) {

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  
  const handleClick = (e) => setAnchorEl(e.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const { t, i18n } = useTranslation()
  let navigate = useNavigate()
  let auth = useAuth()
  const { obj, childObj } = usePermissions()
  const checkConfiguration = checkObjectKey(obj, childObj, 'Configuration')
  const checkUserManagement = checkObjectKey(obj, childObj, 'User_Management')
  const checkAuditList = checkObjectKey(obj, childObj, 'Audit_List')
  const b_units = localStorage.getItem('b_units')?JSON.parse(localStorage.getItem('b_units')):[]
  const bu_bpx = localStorage.getItem('bu_bpx')
  const { authState, oktaAuth } = useOktaAuth();
  const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'))

  const changeBUHandle = (e) => {
    localStorage.setItem('bu_bpx', e.target.dataset.bu)
    
    const urlToRedirect = "/my-activities/accrual_object"
    const currentUrl = localStorage.getItem("currentUrl")
    
    if (window.location.pathname === currentUrl) {
      return navigate(urlToRedirect)
    } else {
      return navigate(0)
    }
    
  }

  const changeLanguage = (language) => {
    localStorage.setItem("current_language", language)
    i18n.changeLanguage(language) 
    window.location.reload()
  }


  const redirectToModel = (url) => {
    return navigate(url)
  }

  const handleSignOut = async () => {
      if (oktaToken !== null && oktaToken.length > 0 && oktaToken !== undefined) {
          oktaAuth.signOut()
          localStorage.clear()
      } else
          auth.signout(() => navigate("/login"))
  }


  return (
    <React.Fragment>
      <MenuIcon onClick={handleClick}  sx={{ color: 'rgb(25, 118, 210)', cursor: 'pointer' }} />
      
      <Menu 
        anchorEl={anchorEl} 
        open={open} 
        onClose={handleClose} 
        className="bpx-header-menu-dropdown"
        anchorOrigin={{
          vertical: 30,
          horizontal: 25
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: "right"
        }}
      >

        <NestedMenuItem 
          MenuProps={{
            anchorOrigin: { vertical: 19, horizontal: 0 },
            transformOrigin: { vertical: 20, horizontal: 220 },
            className: "bpx-header-nested-popover"
          }} 
          leftIcon={
            <LocationOnIcon className="bpx-header-svg" />
          } 
          label={
            <span className="bpx-header-link">{t("MenuSandwich.BUSINESS_UNITS")}</span>
          }
          parentMenuOpen={open}
          className="bpx-header-nested"
        >
          {
            b_units.map(item => {
              
              return (
                <MenuItem 
                  key={item.BUSINESS_UNIT}  
                  className={item.BUSINESS_UNIT === bu_bpx ? 'active-bu' : ''}
                  onClick={handleClose}
                >
                  <span 
                    className="bpx-header-link" 
                    data-bu={item.BUSINESS_UNIT} 
                    onClick={changeBUHandle}
                  >
                    {item.BUSINESS_UNIT} {item.DESCRIPTION}
                  </span>
                </MenuItem>
              )
            })
          }
        </NestedMenuItem>

        <Divider className="bpx-header-divider" />
        
        <MenuItem onClick={handleClose}>
          <Head className="bpx-header-svg" />
          <span className="bpx-header-link" onClick={() => props.openUserPrefs(true)}>{t("MenuSandwich.USER_PREFERENCES")}</span>
        </MenuItem>

        {checkConfiguration? (
          <NestedMenuItem
            MenuProps={{
              anchorOrigin: { vertical: 20, horizontal: 0 },
              transformOrigin: { vertical: 20, horizontal: 158 },
              className: "bpx-header-nested-popover",
            }}
            leftIcon={
              <Tools className="bpx-header-svg" />
            } 
            label={
              <span className="bpx-header-link">{t("MenuSandwich.CONFIGURATION")}</span>
            }
            parentMenuOpen={open}
            className="bpx-header-nested"
          >
            <MenuItem onClick={handleClose}>
              <span className="bpx-header-link" onClick={() => redirectToModel("/configuration")}>
              {t("MenuSandwich.CONFIGURATION_PANEL")}
              </span>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <span className="bpx-header-link" onClick={() => redirectToModel("/configuration/error-catalog")}>
              {t("MenuSandwich.ERROR_CATALOG")}
              </span>
            </MenuItem>
          </NestedMenuItem>
        ) : null }

        <MenuItem onClick={handleClose}>
          <DownloadIcon className="bpx-header-svg" />
          <Link to="/download" className="bpx-header-link">{t("MenuSandwich.DOWNLOAD")}</Link>
        </MenuItem>

        {/* <MenuItem onClick={handleClose}>
          <Tools className="bpx-header-svg" />
          <Link to="/configuration" className="bpx-header-link">{t("MenuSandwich.CONFIGURATION")}</Link>
        </MenuItem>

        {/* <NestedMenuItem
          MenuProps={{
            anchorOrigin: { vertical: 18, horizontal: 0 },
            transformOrigin: { vertical: 20, horizontal: 81 },
            className: "bpx-header-nested-popover"
          }}
          leftIcon={
            <LanguageIcon className="bpx-header-svg" />
          }
          label={
            <span className="bpx-header-link">{t("MenuSandwich.LANGUAGE")}</span>
          }
          parentMenuOpen={open}
          className="bpx-header-nested"
        >
          <MenuItem onClick={handleClose}>
            <span className="bpx-header-link" onClick={() => changeLanguage("en")}>{t("MenuSandwich.ENGLISH")}</span>
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <span className="bpx-header-link" onClick={() => changeLanguage("pl")}>{t("MenuSandwich.POLISH")}</span>
          </MenuItem>
        </NestedMenuItem> */}

        {checkUserManagement||checkAuditList?
          <NestedMenuItem
          MenuProps={{
            anchorOrigin: { vertical: 20, horizontal: 0 },
            transformOrigin: { vertical: 20, horizontal: 125 },
            className: "bpx-header-nested-popover"
          }}
          leftIcon={
            <KeyIcon sx={{ transform: 'rotate(45deg)' }} className="bpx-header-svg" />
          } 
          label={
            <span className="bpx-header-link">{t("MenuSandwich.AUTHORIZATION")}</span>
          }
          parentMenuOpen={open}
          className="bpx-header-nested"
          >
            {checkUserManagement&&<MenuItem onClick={handleClose}>
              <span 
                className="bpx-header-link" 
                onClick={() => redirectToModel("/authorization/manage-user")}
              >
               {t("MenuSandwich.MANAGE_USERS")}
              </span>
            </MenuItem>}
            {checkUserManagement&&<MenuItem onClick={handleClose}>
              <span 
                className="bpx-header-link"
                onClick={() => redirectToModel("/authorization/list-of-roles")}
              >
               {t("MenuSandwich.LIST_OF_ROLES")}
              </span>
            </MenuItem>}
           {checkAuditList&& <MenuItem onClick={handleClose}>
              <span 
                className="bpx-header-link"
                onClick={() => redirectToModel("/auth-audit-list")}
              >
               {t("MenuSandwich.AUDIT_LIST")}
              </span>
            </MenuItem>}
          </NestedMenuItem>:
          null
        }

       

        <Divider className="bpx-header-divider" />

        <MenuItem onClick={handleClose}>
          <PowerSettingsNewSharpIcon className="bpx-header-svg" />
          <span className="bpx-header-link" onClick={() => handleSignOut()}>{t("MenuSandwich.SIGN_OUT")}</span>
        </MenuItem>

      </Menu>
      
    </React.Fragment>
  )

}

export default MenuSandwich
