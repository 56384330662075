import React, { useState } from 'react'
import DialogStatus from './index'
import DialogDeleteStatus from './DialogDeleteStatus'
import { Lock, LockOpen, DisabledByDefaultOutlined } from '@mui/icons-material'
import { usePermissions } from '../../hooks'


function DisplayCustomStatus({ data }) {
  const { value, spacer, key, label, name_component } = data
  const { childObj } = usePermissions()

  const [open, setOpen] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const bp_statuses = ["Active", "Closing", "Blocked_Posting"]
  const statuses = key === "BP_STATUS" ? bp_statuses : ["Active", "Closing", "Planned_Closing", "Blocked_General", "Blocked_Sales", "Planned_Block_Sales", "Planned_Block_General"]

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickOpenDelete = () => {
    setDeleteOpen(true)
  }

  const checkPermission = childObj.hasOwnProperty("Permanent_deletion")

  const styleStatus = statuses.includes(value) ? {} : { color: "#f69e80", fontWeight: 600 }
  const trigger = (
    (value !== "Deleted" && value !== "Deleted_in_AE") &&
    (statuses.includes(value)
    ? <Lock onClick={handleClickOpen} className='bpx-edit-icon-lock' />
    : <LockOpen onClick={handleClickOpen} className='bpx-edit-icon-lock' />)
  )

  const deleteIcon = (
    checkPermission &&
    (value !== "Deleted" && value !== "Deleted_in_AE") && 
    name_component === "Product" && 
    <DisabledByDefaultOutlined onClick={handleClickOpenDelete} className='bpx-edit-icon-lock' />
  )

  return (
    <div className={`bpx-field-detail bpx-align ${(spacer) ? 'bpx-field-margin' : ''}`} key={key}>
      <DialogStatus open={open} setOpen={setOpen} sub={data} />
      <DialogDeleteStatus open={deleteOpen} setOpen={setDeleteOpen} sub={data} />

      <div className='bpx-key-detail'>
        <p>{label}</p>
      </div>

      <div className='bpx-value-detail'>
        <div className='bpx-editable-detail'>
          <div className='bpx-input-section'>
            <p style={styleStatus}>{value}</p>
          </div>
          <div className='bpx-icon-section'>
            { trigger }
            { deleteIcon }
          </div>
        </div>
      </div>

    </div>
  )
}

export default DisplayCustomStatus
