import React, { useState, useEffect, useCallback } from "react"
import { DataGridPro, GridOverlay, useGridApiRef } from "@mui/x-data-grid-pro"
import { LinearProgress } from "@mui/material"
import { backend_url } from "../../../settings"
import { getParam, numberFormatter, dateTimeFormatter, checkObjectKey, applyFilters, checkSize } from "../../../utils/utils"
import { useTranslation } from "react-i18next"
import { CustomToolbar } from "../../../components"
import { Link } from "react-router-dom"
import { CustomLinkTable } from "../../../components"
import CustomMultipleFilter from '../../../mui-components/CustomMultipleFilter'
import CustomPanel from '../../../mui-components/CustomPanel'
import { useFetch, usePermissions, useConvertJSON } from "../../../hooks"
import { useUserPrefs } from "../../UserPreferences/ProvideUserPrefs"


function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


export default function PaymentList() {

  const apiRef = useGridApiRef();

  const { t } = useTranslation("translation")
  const value_bu = localStorage.getItem("bu_bpx")
  const tokenBpxRequest = localStorage.getItem("token_bpx")

  const request = useFetch()
  const { json2array } = useConvertJSON()

  const { obj, childObj } = usePermissions()
  const checkPaymentsLines = checkObjectKey(obj, childObj, 'Payments_Lines')
  const checkERPStatus = checkObjectKey(obj, childObj, 'ERP_Statuses')
  const checkActivityID = checkObjectKey(obj, childObj, 'Activity_ID')

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)

  const [exportQuery, setExportQuery] = useState("")
  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.Payments || []);

  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Payments || 'm');

  const columnVisibility = {};
  if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.Payments){
    prefsData.reports_column_visibilty.Payments.forEach(it=>columnVisibility[it]=false);
  }
  

  const saveColumnVisibility = (data)=>{
    saveUserPrefs({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        Payments: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
  }

  const [filter, setFilter] = useState([
    {
      endpoint: backend_url.payment_filter_values,
      queryKeyToTyping: "PAYMENT_ID",
      title: t("Payment.PAYMENT_ID_TITLE"),
      inputText: t("Payment.PAYMENT_ID_INPUT"),
      firstKeyOption: "PAYMENT_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("PAYMENT_ID", "paymentList"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.payment_filter_values,
      queryKeyToTyping: "AO_ID",
      title: t("Payment.AO_ID_TITLE"),
      inputText: t("Payment.AO_ID_INPUT"),
      firstKeyOption: "AO_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("AO_ID", "paymentList"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.payment_filter_values,
      queryKeyToTyping: "CUSTOMER_REF_DOC",
      title: t("Payment.EXT_REFERENCE_TITLE"),
      inputText: t("Payment.EXT_REFERENCE_INPUT"),
      firstKeyOption: "CUSTOMER_REF_DOC",
      secondKeyOption: "",
      valueFilter: applyFilters("CUSTOMER_REF_DOC", "paymentList"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.payment_filter_values,
      queryKeyToTyping: "PAYMENT_TYPE",
      title: t("Payment.PAYMENT_TYPE_TITLE"),
      inputText: t("Payment.PAYMENT_TYPE_INPUT"),
      firstKeyOption: "PAYMENT_TYPE",
      secondKeyOption: "",
      valueFilter: applyFilters("PAYMENT_TYPE", "paymentList"),
      changingArray: [],
      formatField: "select",
    },
    {
      endpoint: backend_url.payment_filter_values,
      queryKeyToTyping: "PLAN_ACCOUNT_BP_NR",
      title: t("Payment.PLAN_ACCOUNT_TITLE"),
      inputText: t("Payment.EXT_REFERENCE_INPUT"),
      firstKeyOption: "PLAN_ACCOUNT_BP_NR",
      secondKeyOption: "NAME1",
      valueFilter: applyFilters("PLAN_ACCOUNT_BP_NR", "paymentList"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.payment_filter_values,
      firstKeyOption: "POSTING_DATE_FROM",
      title: t("Payment.POSTING_DATE_FROM"),
      valueFilter: applyFilters("POSTING_DATE_FROM", "paymentList"),
      formatField: "date"
    },
    {
      endpoint: backend_url.payment_filter_values,
      firstKeyOption: "POSTING_DATE_TO",
      title: t("Payment.POSTING_DATE_TO"),
      valueFilter: applyFilters("POSTING_DATE_TO", "paymentList"),
      formatField: "date"
    },
    {
      endpoint: backend_url.payment_filter_values,
      queryKeyToTyping: "STATUS",
      title: t("Payment.STATUS_TITLE"),
      inputText: t("Payment.STATUS_INPUT"),
      firstKeyOption: "STATUS",
      secondKeyOption: "",
      valueFilter: applyFilters("STATUS", "paymentList"),
      changingArray: [],
      formatField: "select",
    },
    {
      endpoint: backend_url.payment_filter_values,
      queryKeyToTyping: "ACTIVITY_ID",
      title: t("Payment.ACTIVITY_ID"),
      inputText: t("Payment.ACTIVITY_ID_INPUT"),
      firstKeyOption: "ACTIVITY_ID",
      secondKeyOption: "",
      valueFilter: applyFilters("ACTIVITY_ID", "paymentList"),
      changingArray: [],
      atLeastThreeLetter: true,
      formatField: "select",
    },
    {
      endpoint: backend_url.payment_filter_values,
      queryKeyToTyping: "BUSINESS_SEGMENT",
      title: t("MyBalance.BUSINESS_SEGMENT"),
      inputText: t("MyBalance.BUSINESS_SEGMENT_INPUT"),
      firstKeyOption: "BUSINESS_SEGMENT",
      secondKeyOption: "",
      valueFilter: applyFilters("BUSINESS_SEGMENT", "paymentList", [], json2array("BUSINESS_SEGMENT")),
      changingArray: json2array("BUSINESS_SEGMENT"),
      translateJSON: true,
      formatField: "select"
    },
    {
      endpoint: backend_url.payment_filter_values,
      queryKeyToTyping: "AO_TYPE",
      title: t("Payment.AO_TYPE_FILTER"),
      inputText: t("Payment.AO_TYPE_INPUT"),
      firstKeyOption: "AO_TYPE",
      secondKeyOption: "",
      valueFilter: applyFilters("AO_TYPE", "paymentList"),
      changingArray: [],
      formatField: "select",
    },
  ])

  const changeFilterValues = (state) => {
    setFilter(state)
    setRows([])
    setPage(0)
  }

  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map((d) => {
      return {
        id: d.PAYMENT_ID + d.AO_ID,
        ...d,
        PLAN_ACCOUNT_BP_NR: d.PLAN_ACCOUNT_BP_NR + " - " + d.PLAN_ACCOUNT_BP_NAME,
      }
    })

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [])



  useEffect(() => {

    setLoading(true)

    const queryParams = {
      BUSINESS_UNITS: value_bu,
      limit: 100,
      skip: page,
    }

    filter.map((key) => {
      const { valueFilter, firstKeyOption, formatField, translateJSON } = key

      switch (formatField) {
        case "select":
          if (valueFilter.length) {

            if (translateJSON) {
              
              const getKey = valueFilter.map(jsonkey => Object.keys(jsonkey))
              queryParams[firstKeyOption] = getKey

            } else {
              queryParams[firstKeyOption] = valueFilter
            }

          }
          break
        case "date":
          if (valueFilter) {
            queryParams[firstKeyOption] = valueFilter
          }
          break
        default:
          return null
      }
    })

    let cachedfilters = localStorage.getItem("filters")
    let filterObj = {
        paymentList: queryParams
    }
    if (cachedfilters && Object.keys(cachedfilters).length) { 
        filterObj = JSON.parse(cachedfilters)
        filterObj["paymentList"] = queryParams
    }
    localStorage.setItem("filters", JSON.stringify(filterObj))

    const queryString = getParam(queryParams).toString()

    setExportQuery(queryString)

    request.fetchData(
      `${backend_url.payments_list}?${queryString}`,
      "GET",
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setLoading(false)
      addLoadedRows(data)
      setCount(data.length)
    })

  }, [filter, page])

  useEffect(() => {
      return apiRef.current.subscribeEvent('columnHeaderDragEnd', async (params) => {
          await silentUpdateUserPrefs()
      })
  }, [apiRef])

  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const columns = React.useMemo(() => {
    const allColumns = {
      BUSINESS_UNIT: { 
        field: "BUSINESS_UNIT", 
        headerName: t("Payment.BUSINESS_UNIT"), 
        flex: 0.05,
        minWidth: 50,
      },
      actions: {
          field: "actions",
          type: "actions",
          headerName: t("Payment.ACTIONS"),
          flex: 0.05,
          minWidth: 50,
          hideable: false,
          getActions: (params) => {
            const go_to_lines = (
              <Link
                style={{ color: "#f69e80" }}
                to={
                  `/payments/my-payments/${params.row.PAYMENT_ID.replaceAll('/', '%2F')}/${params.row.VERSION}/`
                }
                className="dark-mode-link-color"
              >
                {t("Payment.GO_TO_PAYMENT_LINES")}
              </Link>
            )

            const go_to_erp = (
              <Link
                style={{ color: "#f69e80" }}
                to={`/payments/my-payments/${params.row.PAYMENT_ID.replaceAll('/', '%2F')}/${params.row.VERSION}/status`}
                className="dark-mode-link-color"
              >
                {"ERP Statuses"}
              </Link>
            )

            return [
              <CustomLinkTable
              
                id={params.row.id}
                links={
                  [
                    checkPaymentsLines ? go_to_lines : null,
                    checkERPStatus ? go_to_erp : null
                  ].filter(t => t !== null)
                }
                fontSize={checkSize(fontSize, 'font_size')}
              />
            ]
          },
        },
        PAYMENT_ID: { 
          field: "PAYMENT_ID", 
          headerName: t("Payment.PAYMENT_ID"),
          flex: 0.06,
          minWidth: 50,
        },
        AO_ID: { 
          field: "AO_ID", 
          headerName: t("Payment.AO_ID"), 
          flex: 0.05,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Accrual Object ID" 
                data-mui-internal-clone-element="true">
                    {t("Payment.AO_ID_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Accrual Object ID" 
                data-mui-internal-clone-element="true">
                  {t("Payment.AO_ID")}
              </div>
            )
          },          
        },
        AO_TYPE: { 
          field: "AO_TYPE", 
          headerName: t("Payment.AO_TYPE"),
          flex: 0.06,
          minWidth: 50,
        },
        CUSTOMER_REF_DOC: { 
          field: "CUSTOMER_REF_DOC", 
          headerName: t("Payment.EXT_REFERENCE"), 
          flex: 0.05,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="External Reference" 
                data-mui-internal-clone-element="true">
                    {t("Payment.EXT_REFERENCE_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="External Reference" 
                data-mui-internal-clone-element="true">
                  {t("Payment.EXT_REFERENCE")}
              </div>
            )
          },          
        },
        PAYMENT_TYPE: { 
          field: "PAYMENT_TYPE", 
          headerName: t("Payment.PAYMENT_TYPE"), 
          flex: 0.05,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Payment Type" 
                data-mui-internal-clone-element="true">
                    {t("Payment.PAYMENT_TYPE_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Payment Type" 
                data-mui-internal-clone-element="true">
                  {t("Payment.PAYMENT_TYPE")}
              </div>
            )
          }, 
        },
        SALES_AREA: { 
          field: "SALES_AREA", 
          headerName: t("Payment.SALES_AREA"), 
          flex: 0.05,
          minWidth: 50, 
        },
        ACTIVITY_ID: { 
          field: "ACTIVITY_ID", 
          headerName: t("Payment.ACTIVITY_ID"), 
          flex: 0.07,
          minWidth: 50,
          renderCell: (params) => {
            if (checkActivityID) {
              return (
                <div className="MuiDataGrid-cellContent">
                  <Link style={{ color: '#f69e80' }} to={`/my-activities/accrual_object/${params.row.AO_ID}`} className="dark-mode-link-color">
                    {params.value}
                  </Link>
                </div>
              )
            }
          } 
        },
        PLAN_ACCOUNT_BP_NR: { 
          field: "PLAN_ACCOUNT_BP_NR", 
          headerName: t("Payment.PLAN_ACCOUNT_BP_NR"), 
          flex: 0.10,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Plan account" 
                data-mui-internal-clone-element="true">
                    {t("Payment.PLAN_ACCOUNT_BP_NR_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Plan account" 
                data-mui-internal-clone-element="true">
                  {t("Payment.PLAN_ACCOUNT_BP_NR")}
              </div>
            )
          }, 
        },
        PLAN_ACCOUNT_BP_CAT: { 
          field: "PLAN_ACCOUNT_BP_CAT", 
          headerName: t("Payment.PLAN_ACCOUNT_BP_CAT"), 
          flex: 0.04,
          minWidth: 50, 
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Plan Account Category" 
                data-mui-internal-clone-element="true">
                    {t("Payment.PLAN_ACCOUNT_BP_CAT_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Plan Account Category" 
                data-mui-internal-clone-element="true">
                  {t("Payment.PLAN_ACCOUNT_BP_CAT")}
              </div>
            )
          },
        }, 
        PAYER: { 
          field: "PAYER", 
          headerName: t("Payment.PAYER"), 
          flex: 0.05,
          minWidth: 50,
        }, 
        DOCUMENT_DATE: { 
          field: "DOCUMENT_DATE", 
          headerName: t("Payment.DOCUMENT_DATE"), 
          flex: 0.05,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Document Date" 
                data-mui-internal-clone-element="true">
                    {t("Payment.DOCUMENT_DATE_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Document Date" 
                data-mui-internal-clone-element="true">
                  {t("Payment.DOCUMENT_DATE")}
              </div>
            )
          },
          renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>,
        },
        POSTING_DATE: { 
          field: "POSTING_DATE", 
          headerName: t("Payment.POSTING_DATE"), 
          flex: 0.05,
          minWidth: 50,
          renderCell: ({ value }) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
        },  
        BUSINESS_SEGMENT: {
          field: 'BUSINESS_SEGMENT',
          headerName: t("Payment.BUSINESS_SEGMENT"),
          flex: 0.05,
         // required: true,
          minWidth: 50,
         // type: 'singleSelect',
          renderCell: (params) => {
            return `${params.row.BUSINESS_SEGMENT}: ${t("BUSINESS_SEGMENT", { ns: 'mars_dictionaries' })[params.row.BUSINESS_SEGMENT]}`
          },
          valueOptions: Object.entries(t("BUSINESS_SEGMENT", { ns: 'mars_dictionaries' }))
            .map(it => ({ value: it[0], option: `${it[0]}:  ${it[1]}` })),
        },     
        REFERENCE_DOC: { 
          field: "REFERENCE_DOC", 
          headerName: t("Payment.REFERENCE_DOC"), 
          flex: 0.05,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Reference Document" 
                data-mui-internal-clone-element="true">
                    {t("Payment.REFERENCE_DOC_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Reference Document" 
                data-mui-internal-clone-element="true">
                  {t("Payment.REFERENCE_DOC")}
              </div>
            )
          },
        },
        CANCELLED_PAYMENT: { 
          field: "CANCELLED_PAYMENT", 
          headerName: t("Payment.CANCELLED_PAYMENT"), 
          flex: 0.06,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Payment Cancelled" 
                data-mui-internal-clone-element="true">
                    {t("Payment.CANCELLED_PAYMENT_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Payment Cancelled" 
                data-mui-internal-clone-element="true">
                  {t("Payment.CANCELLED_PAYMENT")}
              </div>
            )
          },
        },
        ORIGINAL_AMOUNT: {
          field: "ORIGINAL_AMOUNT",
          headerName: t("Payment.ORIGINAL_AMOUNT"),
          flex: 0.06,
          minWidth: 50,
          renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>,
          align: "right",
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Original Amount" 
                data-mui-internal-clone-element="true">
                    {t("Payment.ORIGINAL_AMOUNT_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Original Amount" 
                data-mui-internal-clone-element="true">
                  {t("Payment.ORIGINAL_AMOUNT")}
              </div>
            )
          },
        },
        ORIGINAL_CURRENCY: { 
          field: "ORIGINAL_CURRENCY", 
          headerName: t("Payment.ORIGINAL_CURRENCY"), 
          flex: 0.05,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Original Currency" 
                data-mui-internal-clone-element="true">
                    {t("Payment.ORIGINAL_CURRENCY_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Original Currency" 
                data-mui-internal-clone-element="true">
                  {t("Payment.ORIGINAL_CURRENCY")}
              </div>
            )
          },
        },

        CONFIRMED_AMOUNT: {
          field: "CONFIRMED_AMOUNT",
          headerName: t("Payment.CONFIRMED_AMOUNT"),
          flex: 0.06,
          minWidth: 50,
          renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{numberFormatter(prefsData.decimal_notation).format(value)}</div>,
          align: "right",
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Confirmed Amount" 
                data-mui-internal-clone-element="true">
                    {t("Payment.CONFIRMED_AMOUNT_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Confirmed Amount" 
                data-mui-internal-clone-element="true">
                  {t("Payment.CONFIRMED_AMOUNT")}
              </div>
            )
          },
        },
        CONFIRMED_CURRENCY: { 
          field: "CONFIRMED_CURRENCY", 
          headerName: t("Payment.CONFIRMED_CURRENCY"), 
          flex: 0.05,
          minWidth: 50,
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Confirmed Currency" 
                data-mui-internal-clone-element="true">
                    {t("Payment.CONFIRMED_CURRENCY_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Confirmed Currency" 
                data-mui-internal-clone-element="true">
                  {t("Payment.CONFIRMED_CURRENCY")}
              </div>
            )
          },
        },
        CREATED_ON: {
          field: "CREATED_ON",
          headerName: t("Payment.CREATED_ON"),
          flex: 0.05,
          minWidth: 50,
          renderCell: ({ value }) =>  <div className="MuiDataGrid-cellContent">{dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>,
        },
        STATUS: { 
          field: "STATUS",
          headerName: t("Payment.STATUS"), 
          flex: 0.05,
          minWidth: 50,
          renderCell: ({ value }) => {
              switch(value) {
                case "CONFIRMED": case "CONFIRMED_MANUALLY":
                  return (
                    <div className="MuiDataGrid-cellContent" style={{ color: "#00a651" }}>{value}</div>
                  )
                case "CANCELLED": case "CANCELLED_MANUALLY":
                  return (
                    <div className="MuiDataGrid-cellContent" style={{ color: "#468bd8" }}>{value}</div>
                  )
                case "REQUESTED": case "ON_HOLD":
                  return (
                    <div className="MuiDataGrid-cellContent" style={{ color: "#f66d38" }}>{value}</div>
                  )
                case "FAILED_IN_ERP":
                  return (
                    <div className="MuiDataGrid-cellContent" style={{ color: "#a60103" }}>{value}</div>
                  )
                default:
                  return (
                    <div className="MuiDataGrid-cellContent" style={{ color: "#000" }}>{value}</div>
                  )
              }
          } 
        },
    };
  
    return  orderedColumns.length?orderedColumns.reduce((acc, field) => {
      return [...acc, allColumns[field]];
    }, []):Object.values(allColumns);
  }, [orderedColumns]);

  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("Breadcrumb.payments"), link: "" },
    { label: t("Breadcrumb.myPayments"), link: "/payments/my-payments" },
  ]

  const handleColumnOrderChange = React.useCallback((params) => {
    setOrderedColumns((prevOrderedColumns) => {
      const newOrderedColumns = [...prevOrderedColumns];
      const oldIndex = params.oldIndex;
      const targetIndex = params.targetIndex;
      const oldColumn = prevOrderedColumns[oldIndex];
      newOrderedColumns.splice(oldIndex, 1);
      newOrderedColumns.splice(targetIndex, 0, oldColumn);
      saveUserPrefsWithoutRefresh({
        ...prefsData,
        reports_column_order: {
          ...prefsData.reports_column_order,
          Payments: newOrderedColumns
        }
      })
      return newOrderedColumns;
    });
  }, []);

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Payments: value
      }
    })
  }

  return (
    <div className="tableContainer">
      <DataGridPro
        apiRef={apiRef}
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }
        }} 
        rows={rows}
        columns={columns}
        loading={loading}
        disableSelectionOnClick={true}
        sortingOrder={['desc', 'asc']}        
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        disableColumnFilter
        onColumnOrderChange={handleColumnOrderChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        onPreferencePanelClose={()=> updateUserPrefsOnClose()}
        initialState={{
          columns: {
            columnVisibilityModel: {
              ...columnVisibility,
              actions: checkPaymentsLines & checkERPStatus ? true : false,
            },
          },
        }}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        components={{
          Toolbar: CustomToolbar,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.Payment"),
            displayStandartReportButton: true,
            exportSetting: {
              endpoint: backend_url.payment_list_excel_file + '?' + exportQuery,
              endpointCount: backend_url.payment_list_excel_count + '?' + exportQuery,
              fileName: "Payments Report"
            },
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
            filters:
              <CustomMultipleFilter
                onFilter={changeFilterValues}
                dataFilter={filter}
                loading={loading}
              />
          },
        }}
      />
    </div>
  );
}
