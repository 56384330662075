//export const backend_url = process.env.NODE_ENV === 'development' ? 'http://localhost:8001/backend' : `http://${window.location.host.split(':')[0]}:8001/backend`

let base_url 
let excel_url
let scheduler_url

if (window.location.port === "3000") {
  //base_url = "https://test.accrualengine.com/backend"
  // base_url = "https://dev.accrualengine.com/backend" 
  // excel_url = "https://dev.accrualengine.com/dmanager"
  // scheduler_url = "https://dev.accrualengine.com/scheduler"

  base_url = "http://localhost:8001/backend" 
  excel_url = "http://localhost:8001/dmanager"
  scheduler_url = "http://localhost:8002/scheduler"
} else {
  base_url = `${window.location.origin}/backend`
  excel_url = `${window.location.origin}/dmanager`
  scheduler_url = `${window.location.origin}/scheduler`
}

export const backend_url = {
  login: `${base_url}/token`,
  refresh_token: `${base_url}/refresh_token`,

  bp_detail: `${base_url}/bps`,
  bp_list: `${base_url}/bps`,
  bp_excel_file: `${excel_url}/bps-excel-file`,
  bp_excel_count: `${base_url}/bps-count`,
  
  close_ao: `${base_url}/alert-todo-close-ao`,
  check_close_ao: `${base_url}/alert-todo/by_ao_id`,
  recalculate_ao: `${base_url}/alert-todo-recalculate-ao-force-status-update`,
  
  mat_detail: `${base_url}/materials`,
  mat_list: `${base_url}/materials`,
  mat_status: `${base_url}/material`,
  mat_list_excel_file: `${excel_url}/materials-excel-file`,
  mat_list_excel_count: `${base_url}/materials-count`,

  mat_class_list: `${base_url}/material-classification`,
  mat_class_change_log: `${base_url}/material-classification/MARA`,

  cust_hier_roots: `${base_url}/customer-hierarchies-roots`,
  cust_hier: `${base_url}/customer-hierarchies`,
  cust_hier_roots_list: `${base_url}/customer-hierarchies-roots-filters`,

  config_business_units: `${base_url}/config-business-units`,
  config_business_segments: `${base_url}/config-business-segments`,
  config_business_units_logo: `${base_url}/config-business-units-logo`,

  actual_data_internal: `${base_url}/subtotals`,
  actual_data_internal_filter: `${base_url}/subtotals-filter-values`,
  actual_data_internal_excel: `${excel_url}/subtotals-excel-file`,
  actual_data_internal_count: `${base_url}/subtotals-count`,

  config_subtotals: `${base_url}/config-subtotals`,

  accrual_object_header: `${base_url}/accrual-object`,
  accrual_object_excel_file: `${excel_url}/accrual-object-excel-file`,
  accrual_object_excel_count: `${base_url}/accrual-object-count`,
  acrual_object_bp: `${base_url}/accrual-object`,
  accrual_object: `${base_url}/accrual-object`,

  activity_summary_header: `${base_url}/activity-summary`,
  activity_summary_filter_values: `${base_url}/activity-summary-filter-values`,
  activity_summary_excel_file: `${excel_url}/activity-summary-excel-file`,
  activity_summary_excel_count: `${excel_url}/activity-summary-excel-count`,

  get_current_user: `${base_url}/get_current_user`,
  recently_changed: `${base_url}/recent-accrual-object`,
  activities_total: `${base_url}/accrual-object-totals`,
  accrual_filters: `${base_url}/accrual-object-filter-values`,
  
  config_company_posting_rules: `${base_url}/config-company-posting-rules`,
  config_companies: `${base_url}/config_companies`,

  config_calendars: `${base_url}/config_calendars`,
  config_calendars_assignment: `${base_url}/config-calendars-assignment`,
  config_calendar_periods: `${base_url}/config_calendar_periods`,
  config_calendars_events: `${base_url}/config_calendars_events`,

  config_subtotal_assignment: `${base_url}/config-subtotal-assignment`,

  config_accrual_object_type: `${base_url}/config-accrual-object-type`,
  config_accrual_adjustment: `${base_url}/config-accrual-adjustment`,
  config_accrual_object_type_subtotal_assignment: `${base_url}/config-accrual-object-type-subtotal-assignment`,
  config_aotype_determination: `${base_url}/config_aotype_determination`,

  config_algorithms: `${base_url}/config-algorithms`,
  config_algorithms_global_setup: `${base_url}/config-algorithms-global-setup`,
  config_unit_of_measure: `${base_url}/config_uom`,
  config_report_parameters: `${base_url}/config-report-parameters`,
  config_report_unique_report_type: `${base_url}/config-report-unique-report-type`,
  config_integration_parameters: `${base_url}/integration-parameters`,
  config_approval_workflow: `${base_url}/config-approval-workflow`,
  config_gl_assignment: `${base_url}/config_gl_assignment/valid-by`,

  config_payment_processing: `${base_url}/config_payment_processing`,
  config_material_statuses: `${base_url}/config-material-statuses`,
  config_material_attribute: `${base_url}/config-material-attribute`,
  config_customer_hierarchy_ignore_range: `${base_url}/config_customer_hierarchy_ignore_range`,

  my_balance: `${base_url}/accrual-balance`,
  my_balance_filter_values: `${base_url}/accrual-balance-filter-values`,
  my_balance_excel_file: `${excel_url}/accrual-balance-excel-file`,
  my_accrual_excel_count: `${excel_url}/accrual-balance-excel-count`,

  accrual_posting: `${base_url}/accrual-posting-header`,
  accrual_posting_excel_file: `${excel_url}/accrual-posting-header-excel-file`,
  accrual_posting_excel_count: `${base_url}/count-accrual-posting-header`,
  accrual_posting_filter_values: `${base_url}/accrual-posting-header-filter-values`,
  accrual_posting_detial: `${base_url}/accrual-posting-items`,
  accrual_posting_events_header: `${base_url}/accrual_posting_events_header`,
  accrual_posting_events: `${base_url}/accrual_posting_event`,
  accrual_posting_statuses:`${base_url}/accrual-posting-statuses`,

  payments_list: `${base_url}/payments`,
  payment_filter_values: `${base_url}/payment-filter-values`,
  payments_status: `${base_url}/payments_status`,
  payments_erp: `${base_url}/payments_erp`,
  payment_lines: `${base_url}/payments_management`,
  payment_list_excel_file: `${excel_url}/payments-excel-file`,
  payment_list_excel_count: `${base_url}/payments-count`,
  activities_payments: `${base_url}/activities_payments`,

  mydownload_list: `${excel_url}/get-excel-list`,
  get_excel_file_status: `${excel_url}/get-excel-file-status`,
  get_excel_file: `${excel_url}/get-excel-file`,
  mydownload_cancel: `${excel_url}/cancel-excel-file`,

  model_userlist: `${base_url}/user`,
  user_roles: `${base_url}/user_roles`,
  roles: `${base_url}/roles`,
  roles_permissions: `${base_url}/roles_permissions`,
  model_userRoles: `${base_url}/user_role_assignment`,
  get_user_prefs: `${base_url}/user-preferences`,

  password_change: `${base_url}/password_change`,
  password_hash: `${base_url}/password_hash`,

  accrual_adjustment: `${base_url}/accrual-adjustment`,
  accrual_adjustment_excel_file: `${excel_url}/accrual-adjustments-excel-file`,
  accrual_adjustment_excel_count: `${base_url}/accrual-adjustment-count`,
  adjustments_filter_values: `${base_url}/adjustments-filter-values`,
  adjustment_simulation_excel_file: `${excel_url}/accrual-adjustments-simulation-results-excel-file`,
  approval_document_history: `${base_url}/approval-document-history`,

  get_plan_accounts: `${base_url}/get-all-plan-accounts`,
  plan_account_total_accruals: `${base_url}/plan-account-calculations/total-accruals`,
  plan_account_subtotal: `${base_url}/plan-account-calculations/subtotal`,
  plan_account_confirmed_payments: `${base_url}/plan-account-calculations/confirmed-payments`,

  error_catalog_list: `${base_url}/error-catalog`,

  schedulable_jobs: `${scheduler_url}/schedulable_jobs`,
  job: `${scheduler_url}/job`,
  jobs: `${scheduler_url}/jobs`,
  job_one_time: `${scheduler_url}/job_one_time`,  

  email_response: `${base_url}/email-response`,

  migration_workbench_list: `${base_url}/migration-workbench`,
  migration_workbench_file: `${base_url}/migration-workbench-file`,
  migration_workbench_receiver: `${base_url}/migration-workbench-receiver`,
  migration_workbench_templates: `${base_url}/migration-workbench-templates`,
  migration_workbench_status: `${base_url}/migration-workbench-status`,

  migration_workbench_error: `${base_url}/migration-workbench-error`,
  migration_workbench_created_payment: `${base_url}/migration-workbench-created-payment`,

  alert_todo: `${base_url}/alert-todo`,
  alert_reprocess: `${base_url}/create_reprocess_todo`,

  trade_expenditure_monitor: `${base_url}/trade-expenditure-monitor`,
  trade_expenditure_monitor_filter_values: `${base_url}/trade-expenditure-monitor-filter-values`,
  trade_expenditure_monitor_excel: `${excel_url}/trade-expenditure-monitor-excel-file`,

  relative_distribution_rates: `${base_url}/relative_distribution_rates`,
  
  enterprise_identification: `${base_url}/enterprise-identification`,

  staging_accrual_posting_header_status: `${base_url}/staging-accrual-posting-header/status`,  
  accrual_posting_events_header_status: `${base_url}/accrual_posting_events_header/status`,

  auth_audit_data: `${base_url}/user-audit-data`,
  auth_audit_data_filters: `${base_url}/user-audit-data-filter-values`,

  user_bus: `${base_url}/user_available_bu`,
  audit_list_excel: `${excel_url}/audit-list-excel-file`,
  audit_list_count: `${base_url}/audit-list-count`,

  full_reconciliation: `${base_url}/full-reconciliation`,
  full_reconciliation_filter_values: `${base_url}/full-reconciliation-filter-values`
}
