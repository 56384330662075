import React, { useState, useEffect } from 'react'
import { DataGridPro, GridActionsCellItem } from '@mui/x-data-grid-pro'
import Checkbox from '@mui/material/Checkbox';
import EditIcon from '@mui/icons-material/Edit'
import { getParam, checkObjectKey } from "../../../utils/utils"
import { useFetch, usePermissions } from '../../../hooks'
import { ModalAddData } from '../../../components'
import {SelectedComponent} from './index'

function AssignmentComponent({ table, configuration,onHandleClick,business_unit }) {

  const { 
    url, endpoint, titleTable,
    columnsTable, notistack, columnSingleSelect,configurationDepend, value, columnDefined,urlSelected,endpointForGet,selected
  } = table

  const tokenBpxRequest = localStorage.getItem('token_bpx')

  const request = useFetch()

  const { obj, childObj } = usePermissions()
  const checkConfiguration = checkObjectKey(obj, childObj, 'Configuration_Change');

  const [state, setState] = useState({checked: false, data:{}});
  const [tableData, setTableData] = useState(columnsTable.filter(it=>!columnDefined.find(i=>i===it.field)))
  const [modal, showModal] = useState({'addData': false, 'warning':false});
  const [editTable, setEditTable] = useState(null);
  const [defendValue, setDefendValue] = useState(configuration[value]);
  const [rows, setRows] = useState([]);

  const columns = [
    {field: 'TITLE', flex: 1},
    {field: 'VALUE', flex:1} 
  ]

  const getUrl = (skip)=>{
    let param = {
      limit: 100,
      skip
    };
    if(endpointForGet){
      endpointForGet.forEach(it=>{
        if(localStorage.getItem(it.name)){
          param[it.field] = localStorage.getItem(it.name);
        }
      })
    }

    if(configurationDepend){
      const ls = JSON.parse(localStorage.getItem('configuration_setting'));
      configurationDepend.forEach(it=>{
      if(configuration[it]||(ls&&ls[it])){
        param[it] = configuration[it]?configuration[it]:ls[it];
      }
      })
    }
    const queryString = getParam(param).toString();
    return '?'+queryString;
  }
  useEffect(()=>{
    setRows(tableData.map((it, id)=>{
      const value = state.data[it.field]?
        it.renderCell?
        it.renderCell({value: state.data[it.field]}):
        state.data[it.field]:
      ''
    return {'TITLE': it.headerName, 'VALUE':value, id }}))
  },[state])

  useEffect(()=>{
    request.fetchData(
        url + getUrl(0),
        'GET',
        null,
        false,
        tokenBpxRequest,
        false,
        notistack
      )
      .then(data => {

        const check = data.find(it=>{
        return columnDefined.every(i=>configuration[i]===it[i])
       })
        if(check){
          setState({data: {...check}, checked: !!check})
        } else{
         
          const newObj = {
            [value]: defendValue,
            BUSINESS_UNIT: business_unit
          };
          setState({data: newObj, checked: !!check})
        }
      })
  },[business_unit, defendValue])

  useEffect(()=>{setDefendValue(configuration[value])}, [ configuration[value]])

  useEffect(() => {
    if (columnSingleSelect) {
      columnSingleSelect.map(ww => {
        const { url, code, field } = ww
        request.fetchData(
          url,
          'GET',
          null,
          false,
          tokenBpxRequest,
          false,
          false
        )
        .then(data => {
          let valueArray = data.map(tt => tt[code])
          let unique = valueArray.filter((v, i, a) => a.indexOf(v) === i);
          setTableData(tableData.map((it=>{
            if(it.field===field){
              it.valueOptions = unique;
            } return it;
          })))
        })
        return ww
      })
    }
  }, [])


  const getUrlArray = (row) => {
    const getUrl = endpoint.filter(tt=>tt.field).map(tt => tt.field);
    const param = {};
    const getUrlLine = endpoint.filter(tt=>tt.line).map(tt => {
      param[tt.line] =row[tt.line];
      return tt.line
    });

    const filteredUrl = getUrl.map(it=>row[it]);

    let convertUrl = Object.values(filteredUrl).join('/');

    if(getUrlLine.length){
      convertUrl = convertUrl + '?' + getParam(param).toString();
    }
    return convertUrl
  }

  const excludeProperty = (row, exclude) => {
    return Object.keys(row)
      .filter(key => !exclude.includes(key))
      .reduce((obj, key) => Object.assign(obj, { [key]: row[key] }), {})
  }

  const handleEditClick = () => {
    setEditTable(state.data);
    showModal({...modal, addData: true});
  }
  
  const deleteElement = ()=>{
    const oldRow = state.data;
    request.fetchData(
      url + '/' + getUrlArray(oldRow),
      "DELETE",
      null,
      false,
      tokenBpxRequest,
      true,
      notistack
    )
    .then(data => {
      if (data) {
        const newObj = {};
        columnDefined.map(it=>newObj[it]=configuration[it])
        newObj.BUSINESS_UNIT = business_unit;
        setState({checked: false, data: newObj})
        setEditTable(null)
      }
    })
  }

  const handleCheckBox = (e)=>{
    if(e.target.checked){
      if(columnsTable.length>2){
        showModal({...modal, addData: true});
      } else{
        processRowUpdate();
      }
      
    } else {
      deleteElement()
    }
  }

  const closeModal = ()=>{
    setEditTable(null)
    showModal({...modal, addData: false});
  }
  
  const processRowUpdate = (newRow={...state.data, isNew: true}) => {
        const oldRow = state.data;
        const body = [excludeProperty({...oldRow, ...newRow}, ['id', 'isNew'])];
        if (newRow.isNew) {   
          return request.fetchData(
            url + '?business_unit=' + business_unit,
            "POST",
            JSON.stringify(body),
            false,
            tokenBpxRequest,
            true,
            notistack
          )
          .then(data => {
            if (data) {
              setState({checked: true, data: {...oldRow, ...newRow}})
              closeModal();
            }
          })
    
        } else {
          return request.fetchData(
            url + '/' + getUrlArray(oldRow),
            "PUT",
            JSON.stringify(body[0]),
            false,
            tokenBpxRequest,
            notistack,
            notistack
          )
          .then(data => {
            if (data) {
              closeModal();
              setState({checked: true, data: {...oldRow, ...newRow}})
            }
          })
        }   
  }


  return (
    <>
      <div className='bpx-config-datagrid'>
        {
          selected?
            <div className="bpx-config-block">                
              <div className="bpx-config-bu">
                <SelectedComponent onHandleClick={onHandleClick} url={urlSelected} configuration={configuration} value={value}/>
              </div>
            </div>
          :null
        }
        <h3 className='bpx-config-datagrid-title'>
          {
            defendValue?
              <Checkbox 
                sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                color="default"
                checked={state.checked}
                disabled={!checkConfiguration}
                onChange={handleCheckBox}
              />:
              null
          }
          {titleTable} {business_unit}
        </h3>
        
          {
            state.checked&&tableData.length?
              <div className='bpx-config-datagrid-assigment'>
                <DataGridPro
                  autoHeight
                  rows={rows}
                  columns={columns}
                  hideFooter={true}
                />
                {
                  checkConfiguration ? 
                    (<div className="bpx-config-datagrid-bottom">
                      <GridActionsCellItem
                        icon={<EditIcon />}
                        label="Edit"
                        className="textPrimary"
                        onClick={handleEditClick}
                        color="inherit"
                      />
                  </div>)
                  : null
                }
              </div>
            :null
          }
            

  
        {/* {content.length&&state.checked?<GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick}
            color="inherit"
          />:null} */}
          {/* {content&&state.checked?<div className="bpx-config-datagrid-bottom">
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick}
            color="inherit"
          /> */}
        {/* </div>:null} */}
        <ModalAddData data={tableData} titleTable={titleTable} open={modal.addData} onClose={closeModal} handleSubmit={processRowUpdate} editData={editTable} />
      </div>
    </>
  )
}

export default AssignmentComponent
