import React, { useState, useEffect, useCallback } from 'react';
import { DataGridPro, GridOverlay, useGridApiRef, getGridStringOperators } from '@mui/x-data-grid-pro'
import { LinearProgress } from "@mui/material"
import { backend_url } from '../../settings'
import { Link } from 'react-router-dom'
import { getParam, searchFromJSONFile, checkObjectKey, checkInFilterCache, dateTimeFormatter, checkSize } from '../../utils/utils' 
import { useTranslation } from 'react-i18next'
import { CustomToolbar, CustomColumnMenu, ModalFindProduct,CustomActionList, DialogStatus, DialogDeleteStatus, DialogChangeLogs } from '../../components'
import { useFetch, useConvertJSON, usePermissions } from '../../hooks'
import CustomPanel from '../../mui-components/CustomPanel'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import FindProductIcon from '../../assets/images/find_product.png'

function CustomLoadingOverlay() {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
}


export default function MaterialsList() {

  const apiRef = useGridApiRef()

  const { t } = useTranslation()
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  const bu_bpx = localStorage.getItem('bu_bpx')

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains'].includes(value),
  );

  const request = useFetch()
  const { json2array } = useConvertJSON()
  const { obj, childObj } = usePermissions()
  const checkProducts = checkObjectKey(obj, childObj, 'Product_detail')

  const [rows, setRows] = useState([])
  const [page, setPage] = useState(0)
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState({findProduct: false, changeStatus: false, deleteProduct: false, changeLogs: false})
  const [ data, setData ] = useState({});

  const [exportQuery, setExportQuery] = useState("")

  const { prefsData, saveUserPrefs, updateUserPrefsOnClose, saveUserPrefsWithoutRefresh, silentUpdateUserPrefs } = useUserPrefs();
  const [orderedColumns, setOrderedColumns] = useState(prefsData.reports_column_order && prefsData.reports_column_order.Products || []);
  const [fontSize, setFontSize] = useState(prefsData.reports_column_size&&prefsData.reports_column_size.Products || 'm');
 
  const dataChangeLogs = {  
      title: t("CHANGE_LOGS.TitleMat"),
      value: 'status_id',
      url: backend_url.mat_status,
      endpoint: [{ field: 'MATERIAL_NR', name: 'status_id' }],
      endpointName: '/change_log',
      checkToHide: ['AE_USER_ID', 'EXT_USER_ID', 'DOC_NUMBER'],
      isTreeChar: false,
      skip: 100,
      limit: 100,
      prevHide: {
        "EXT_USER_ID": false,
        "DOC_NUMBER": false
      },
      columns: [
        {
          field: 'TIMESTAMP',
          headerName: t("CHANGE_LOGS.ChangeTime"),
          flex: 1,
          minWidth: 150,
          renderCell: ({ value }) => {
            if (value) {
              return dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone)
            }
            return value
          },
        },
        { field: 'AREA', headerName: t("CHANGE_LOGS.Area"), flex: 1 },
        { field: 'FIELD_OF_CHANGE', headerName: t("CHANGE_LOGS.Attribute"), flex: 1 },
        {
          field: 'BUSINESS_AREA_PRIMARY_KEY',
          headerName: t("CHANGE_LOGS.PrimaryKey"),
          minWidth: 220,
          renderCell: ({ value }) => {

            const lines = Object.entries(value).map(it => `${it[0]}: ${it[1]}`);
            return (
              <div style={{ whiteSpace: 'pre-line' }}>
                {lines.map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </div>
            );
          },
        },
        { field: 'FROM', headerName: t("CHANGE_LOGS.OldValue"), flex: 1 },
        { field: 'TO', headerName: t("CHANGE_LOGS.NewValue"), flex: 1 },
        { field: 'AE_USER_ID', headerName: t("CHANGE_LOGS.AeUserId"), flex: 1 },
        {
          field: 'EXT_USER_ID',
          headerName: t("CHANGE_LOGS.ExtUserId"),
          flex: 1,
        },
        { field: 'DOC_NUMBER', headerName: t("CHANGE_LOGS.MessageId"), flex: 1 },
      ]
    }

  const columnVisibility = {};
  
  if(prefsData.reports_column_visibilty&&prefsData.reports_column_visibilty.Products){
    prefsData.reports_column_visibilty.Products.forEach(it=>columnVisibility[it]=false);
  }
 

  const saveColumnVisibility = (data)=>{
    saveUserPrefs({
      ...prefsData,
      reports_column_visibilty:{
        ...prefsData.reports_column_visibilty,
        Products: Object.entries(data).filter(it=>!it[1]).map(it=>it[0])
      }
    })
  }

  const cachedfilters = localStorage.getItem("filters")
  var filterObj = {
      materialList: []
  }

  if (cachedfilters && Object.keys(cachedfilters).length) { 
      filterObj = JSON.parse(cachedfilters)
      if (!('materialList' in filterObj)) {
          filterObj["materialList"] = []
      }
  }
  const [queryOptions, setQueryOptions] = useState(filterObj["materialList"])

  useEffect(() => {

    setLoading(true)
    
    const queryParams = {
      limit: 100,
      BUSINESS_UNIT: bu_bpx,
      skip: page,
      ...queryOptions.map(cc => ({ [cc.columnField]: cc.value }))
    }
    const searchParam = getParam(queryParams).toString()
    
    setExportQuery(searchParam)

    request.fetchData(
      `${backend_url.mat_list}?${searchParam}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
    .then(data => {
      setLoading(false)
      addLoadedRows(data)
      setCount(data.length)
    })

  }, [queryOptions, page])

  useEffect(() => {
      return apiRef.current.subscribeEvent('columnHeaderDragEnd', (params) => {
          silentUpdateUserPrefs()
      })
  }, [apiRef])
 
  const applyFilter = useCallback((item) => {
    const itemConvert = item 

    if (item.value) {
      let convertObject

      switch (itemConvert.columnField) {
        case "BUSINESS_SEGMENT":
          convertObject = searchFromJSONFile(itemConvert, json2array)
          break

        case "MARKET_SEGMENT":
          convertObject = searchFromJSONFile(itemConvert, json2array)
          break

        case "BRAND_FLAG":
          convertObject = searchFromJSONFile(itemConvert, json2array)
          break

        case "BRAND_SUB_FLAG":
          convertObject = searchFromJSONFile(itemConvert, json2array)
          break

        default:
          convertObject = item
      }
      
      setQueryOptions((prev) => {
        const uniqueKey = prev.filter(cc => cc.id !== item.id)
        return [
          ...uniqueKey, 
          convertObject
        ]
      })

      checkInFilterCache(item, filterObj.materialList)
      localStorage.setItem("filters", JSON.stringify(filterObj))

      setRows([])
      setPage(0)
  
    }

    apiRef.current.upsertFilterItem(item)
  }, [apiRef, queryOptions])


  const deleteFilter = useCallback((item) => {
    
      if (loading) {
        return
      }
    
      let deleteItem = queryOptions.filter(cc => cc.id !== item.id)
      setQueryOptions(deleteItem)

      filterObj.materialList = filterObj.materialList.filter(fl => fl.id !== item.id)
      localStorage.setItem("filters", JSON.stringify(filterObj))

      setRows([])
      setPage(0)

      const shouldCloseFilterPanel = queryOptions.length === 0
      apiRef.current.deleteFilterItem(item)

      if (shouldCloseFilterPanel) {
        apiRef.current.hideFilterPanel()
      }
    
    
  }, [apiRef, queryOptions, loading])


  const addLoadedRows = useCallback((items) => {
    const changeIdRow = items.map(d => ({
      id: d.MATERIAL_NR,
      ...d,
      BASE_UOM: t('PRODUCT_UOM.MSEHT.' + d.BASE_UOM, { ns: 'uom' }),
      BUSINESS_SEGMENT: d.BUSINESS_SEGMENT.padStart(2, '0') + " - "+ t('BUSINESS_SEGMENT.' + d.BUSINESS_SEGMENT.padStart(2, '0'), { ns: 'mars_dictionaries'}),
      BRAND_FLAG: d.BRAND_FLAG.padStart(3, '0') + " - "+ t('BRAND_FLAG.' + d.BRAND_FLAG.padStart(3, '0'), { ns: 'mars_dictionaries'}),
      BRAND_SUB_FLAG: d.BRAND_SUB_FLAG.padStart(3, '0') + " - "+ t('BRAND_SUB_FLAG.' + d.BRAND_SUB_FLAG.padStart(3, '0'), { ns: 'mars_dictionaries'}),
      MARKET_SEGMENT: d.MARKET_SEGMENT.padStart(2, '0') + " - "+ t('MARKET_SEGMENT.' + d.MARKET_SEGMENT.padStart(2, '0'), { ns: 'mars_dictionaries'}),
    }))

    setRows((old) => {
      return [...old, ...changeIdRow]
    })

  }, [queryOptions])

  
  const handleOnRowsScrollEnd = (params) => {
    if (params.viewportPageSize&&!loading) {
      if (count >= 100) {
        return setPage(page + 100)
      } else {
        return null
      }
    }
  }

  const columns = React.useMemo(() => {
    const allColumns = {
      MATERIAL_NR:{ 
          field: 'MATERIAL_NR', 
          headerName: t('MaterialsList.MATERIAL_NR'),
          filterOperators, 
          flex: 0.07,
          minWidth: 50,
          renderCell: (params) => {
            if (checkProducts) {
              return (
                <div className="MuiDataGrid-cellContent">
                  <Link style={{ color: '#f69e80' }} to={`/master-data/products/${params.value}/detail`} className="dark-mode-link-color">
                    {params.value}
                  </Link>
                </div>
              )
            }
          } 
      },
      MATERIAL_TYPE: { 
          field: 'MATERIAL_TYPE', 
          headerName: t('MaterialsList.MATERIAL_TYPE'), 
          flex: 0.07,
          minWidth: 50,
          filterOperators 
      },
      MATERIAL_GROUP: { 
          field: 'MATERIAL_GROUP', 
          headerName: t('MaterialsList.MATERIAL_GROUP'), 
          flex: 0.04,
          minWidth: 50,
          filterOperators,          
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Material Group" 
                data-mui-internal-clone-element="true">
                    {t("MaterialsList.MATERIAL_GROUP_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Material Group" 
                data-mui-internal-clone-element="true">
                  {t("MaterialsList.MATERIAL_GROUP")}
              </div>
            )
          },
      },
      BASE_UOM: { 
          field: 'BASE_UOM', 
          headerName: t('MaterialsList.BASE_UOM'), 
          flex: 0.05,
          minWidth: 50,
          filterable: false,
          renderHeader: ({colDef})=>{
            if(colDef.width <= 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Base Unit of Measure" 
                data-mui-internal-clone-element="true">
                    {t("MaterialsList.BASE_UOM_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Base Unit of Measure" 
                data-mui-internal-clone-element="true">
                  {t("MaterialsList.BASE_UOM")}
              </div>
            )
          },
      },
      MATERIAL_DESCRIPTION: { 
          field: 'MATERIAL_DESCRIPTION', 
          headerName: t('MaterialsList.MATERIAL_DESCRIPTION'), 
          flex: 0.14,
          minWidth: 50,
          filterOperators,
          renderHeader: ({colDef})=>{
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Material Description" 
                data-mui-internal-clone-element="true">
                    {t("MaterialsList.MATERIAL_DESCRIPTION_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Material Description" 
                data-mui-internal-clone-element="true">
                  {t("MaterialsList.MATERIAL_DESCRIPTION")}
              </div>
            )
          },
      },
      EAN_UPC: { 
          field: 'EAN_UPC', 
          headerName: t('MaterialsList.EAN_UPC'), 
          flex: 0.09,
          minWidth: 50,
          filterOperators 
      },
      SALES_UNIT: { 
          field: 'SALES_UNIT', 
          headerName: t('MaterialsList.SALES_UNIT'), 
          flex: 0.05,
          minWidth: 50,
          filterOperators
      },
      PRICING_REFERENCE_MATERIAL: { 
          field: 'PRICING_REFERENCE_MATERIAL', 
          headerName: t('MaterialsList.PRICING_REFERENCE_MATERIAL'), 
          flex: 0.07,
          minWidth: 50,
          filterOperators,          
          renderHeader: ({colDef})=>{
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Pricing Ref. Material" 
                data-mui-internal-clone-element="true">
                    {t("MaterialsList.PRICING_REFERENCE_MATERIAL_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Pricing Ref. Material" 
                data-mui-internal-clone-element="true">
                  {t("MaterialsList.PRICING_REFERENCE_MATERIAL")}
              </div>
            )
          },
      },
      BUSINESS_SEGMENT: { 
          field: 'BUSINESS_SEGMENT', 
          headerName: t('MaterialsList.BUSINESS_SEGMENT'), 
          flex: 0.07,
          minWidth: 50,
          filterOperators,          
          renderHeader: ({colDef})=>{
            if(colDef.width < 100){
              return (
                <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Segment" 
                data-mui-internal-clone-element="true">
                    {t("MaterialsList.BUSINESS_SEGMENT_SHORT")}
                </div>
              )
            }
            return (
              <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
                aria-label="Business Segment" 
                data-mui-internal-clone-element="true">
                  {t("MaterialsList.BUSINESS_SEGMENT")}
              </div>
            )
          },
      },
      MARKET_SEGMENT: { 
          field: 'MARKET_SEGMENT', 
          headerName: t('MaterialsList.MARKET_SEGMENT'), 
          flex: 0.07,
          minWidth: 50, 
          filterOperators 
      },
      BRAND_FLAG: { 
          field: 'BRAND_FLAG', 
          headerName: t('MaterialsList.BRAND_FLAG'), 
          flex: 0.10,
          minWidth: 50,
          filterOperators 
      },
      BRAND_SUB_FLAG: { 
          field: 'BRAND_SUB_FLAG', 
          headerName: t('MaterialsList.BRAND_SUB_FLAG'), 
          flex: 0.10,
          minWidth: 50,
          filterOperators 
      },
      AE_MATERIAL_STATUS: { 
          field: 'AE_MATERIAL_STATUS', 
          headerName: t("MaterialsList.AE_MATERIAL_STATUS"), 
          flex: 0.07,
          minWidth: 50, 
          filterOperators,
          renderCell: ({value})=>{
            const statuses = ["Deleted", "Deleted_in_AE", "Manual_Block", "Blocked_General", "Blocked_Sales", "Planned_Block_Sales", "Planned_Block_General", "Planned_Closing", "Closing", "Active"]
            const status = statuses.includes(value)? t(`MaterialsList.STATUSES.${value}`): value;
            return ( <div className="MuiDataGrid-cellContent">
              {status}
            </div>)
          }
      },
      actions: {
          field: 'actions',
          type: 'actions',
          width: 65,
          minWidth: 50,
          headerName: t("BPList.ACTIONS"),
          headerAlign: 'left',
          getActions: (params) => {
            const data = {
              "label": "Material Status",
              "key": "AE_MATERIAL_STATUS",
              "value": params.row.AE_MATERIAL_STATUS,
              "tooltip": "Message Status",
              "editable": false,
              "splitWord": false,
              "status_id": params.row.id,
              "endpoint": backend_url.mat_status,
              "name_component": "Product",
          }
          return [ 
            <CustomActionList 
              id={params.id} 
              data={data} 
              handleBlock={()=>{
                setData(data);
                setOpen(prev=>({...prev, changeStatus: true}));
              }} 
              handleDelete={()=>{
                setData(data)
                setOpen(prev=>({...prev, deleteProduct: true}))
              }}
              handleLog={()=>{
                setData(data)
                setOpen(prev=>({...prev, changeLogs: true}))
              }}
            />
            ]
          }
      }
    }

    return orderedColumns.length?orderedColumns.reduce((acc, field) => {
      return [...acc, allColumns[field]];
    }, []):Object.values(allColumns);
  }, [orderedColumns]);

  const objBreadcrumb = [
    { label: t('Breadcrumb.home'), link: '/' },
    { label: t('Breadcrumb.masterData'), link: '' },
    { label: t('Breadcrumb.products'), link: '/master-data/products' },
  ]

  const handleColumnOrderChange = React.useCallback((params) => {
    setOrderedColumns((prevOrderedColumns) => {
      const newOrderedColumns = [...prevOrderedColumns];
      const oldIndex = params.oldIndex;
      const targetIndex = params.targetIndex;
      const oldColumn = prevOrderedColumns[oldIndex];
      newOrderedColumns.splice(oldIndex, 1);
      newOrderedColumns.splice(targetIndex, 0, oldColumn);
      saveUserPrefsWithoutRefresh({
        ...prefsData,
        reports_column_order: {
          ...prefsData.reports_column_order,
          Products: newOrderedColumns
        }
      })
      return newOrderedColumns;
    });
  }, []);

  const onChangeFontSize = (value)=>{
    setFontSize(value)
    saveUserPrefs({
      ...prefsData,
      reports_column_size:{
        ...prefsData.reports_column_size,
        Products: value
      }
    })
  }

  return (
    <div className='tableContainer'>
      <DataGridPro
        rows={rows}
        hideFooterRowCount={true}
        sx={{ 
          '& .MuiDataGrid-row': {
            minHeight: `${checkSize(fontSize, 'row_height')}px !important`,
            height: 'auto !important'
          },
          '& .MuiDataGrid-cell': {
            whiteSpace: 'nowrap',
            overflow: 'hidden'
          },
          '& .MuiDataGrid-main':{
            fontSize: checkSize(fontSize, 'font_size')
          }
        }} 
        apiRef={apiRef}
        columns={columns}
        loading={loading}
        sortingOrder={['desc', 'asc']}
        filterMode="server"
        getRowHeight={() => 'auto'}
        headerHeight={checkSize(fontSize, 'header_height')}
        onColumnOrderChange={handleColumnOrderChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnVisibilityModelChange={saveColumnVisibility}
        onPreferencePanelClose={()=> updateUserPrefsOnClose()}
        localeText={{
          toolbarColumns: t("Buttons.Columns"),
          toolbarExport: t("Buttons.Export"),
          noRowsLabel: t("Common.noRows")
        }}
        initialState={{
          columns: {
            columnVisibilityModel:columnVisibility
          },
          filter: {
              filterModel: {
                  items: filterObj && filterObj.materialList,
              },
          }
        }}
        components={{
          Toolbar: CustomToolbar,
          ColumnMenu: CustomColumnMenu,
          LoadingOverlay: CustomLoadingOverlay,
          Panel: CustomPanel,
        }}
        componentsProps={{
          toolbar: {
            bread: objBreadcrumb,
            title: t("Title.materialList"),
            displayStandartReportButton: true,
            exportSetting: {
              endpoint: backend_url.mat_list_excel_file + '?' + exportQuery,
              endpointCount: backend_url.mat_list_excel_count + '?' + exportQuery,
              fileName: "Material List Report"
            },
            extraButton: [
              {
                headerName: t("Buttons.Find_Product"),
                onClick:  ()=> setOpen(pr=>({...pr, findProduct: true})),
                icon: <img src={FindProductIcon} alt='icon find product' height={14} style={{paddingTop: 2}}/> ,
                style: {},
                disabled:false,
                extraClass: ' MuiButton-startIcon-find-product'
              },
            ],
            isFontSize: true,
            fontSize: fontSize,
            onChangeFontSize: onChangeFontSize,
          },
          filterPanel: {
            filterFormProps: {
              applyFilterChanges: applyFilter,
              deleteFilter: deleteFilter,
              valueInputProps: {
                InputComponentProps: {
                  disabled: loading ? true : false,
                  inputRef: (input) => input && input.focus()
                }
              }
            },
            linkOperators: ['and']
          } 
        }}
      />
      <ModalFindProduct open={open.findProduct} onClose={()=>setOpen(pr=>({...pr, findProduct: false}))} onSubmit={()=>{}}/>
      <DialogStatus open={open.changeStatus} setOpen={(state)=>setOpen(prev=>({...prev, changeStatus: state}))} sub={data} />
      <DialogDeleteStatus open={open.deleteProduct} setOpen={(state)=>setOpen(prev=>({...prev, deleteProduct: state}))} sub={data} />
      <DialogChangeLogs open={open.changeLogs} onCloseHandle={()=>setOpen(prev=>({...prev, changeLogs: false}))} data={data} dataLogs={dataChangeLogs}/>
    </div>
  )

}
