import React, { useState } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import { useTranslation } from 'react-i18next'


const AccordionTable = ({ id, data, columns, dataKey, label, collapse, fetching, url, idField }) => {

  const [expanded, setExpanded] = useState(collapse)
  const { t } = useTranslation('translation')
  
  if (!data || !data[dataKey] || !data[dataKey].length) {
    console.log("No data found in structure! dataKey = " + dataKey + ", exiting...")
    return null
  }
  
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
  }
  
  const rows = data[dataKey]

  return (
    <Accordion expanded={expanded === dataKey} onChange={handleChange(dataKey)}>

      <AccordionSummary expandIcon={<ArrowRightIcon className='bpx-arrow-icon' />} className='bpx-accordion-header'>
        <p>{label}</p>
      </AccordionSummary>

      <AccordionDetails className='bpx-accordion-detail'>
        <div style={{ height: 'auto', width: '100%' }}>
          <DataGridPro
            rows={rows}
            sx={{ '& .MuiDataGrid-main': { height: rows.length < 8 ? '100%' : '380px' } }}
            autoHeight={Boolean(rows.length < 8)}
            columns={columns}
            pageSize={50}
            rowsPerPageOptions={[20, 50, 100]}
            density="compact"
            localeText={{
              noRowsLabel: t("Common.noRows")
            }}
            getRowId={row => idField ? row[idField] : (Math.random() + 1).toString(36).substring(2)}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: !Boolean(idField),
                  CONSECUTIVE_NUMBER: false,
                },
              },
            }}
          /> 
        </div>
      </AccordionDetails>

    </Accordion>
  )
}

export default AccordionTable
