import React, { useState } from 'react'
import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs"
import { Typography, FormControlLabel, Switch } from "@mui/material"
import { useTranslation } from 'react-i18next'

export default function DarkMode() {

  const { prefsData, saveUserPrefs, silentUpdateUserPrefs } = useUserPrefs()
  const [theme, setTheme] = useState(prefsData.theme)
  const { t } = useTranslation('translation')

  const toggleTheme = () => {
    
    if (theme === 'light') {
      setTheme('dark')
      saveUserPrefs({
        ...prefsData,    
        theme: "dark" 
      })
      document.body.className = "dark"
      silentUpdateUserPrefs()
    } else {
      setTheme('light')
      saveUserPrefs({
        ...prefsData,
        theme: "light" 
      })
      document.body.className = "light"
      silentUpdateUserPrefs()
    }  
  }

  
  return ( 
    <div className="section-look-feel second">
      <Typography variant="h6">{t("preferences.Additionals")}</Typography>
      <FormControlLabel 
        control={
          <Switch 
            checked={theme === "dark" ? true : false} 
            onChange={toggleTheme} 
            name="theme"
          />
        } 
        label={t("preferences.Enable_Dark_Mode")} 
      />
    </div>
  )
}
