import React, { useState, useEffect, useCallback } from 'react'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { CustomToolbarHome, CustomActionTable } from '../../components/index'
import { backend_url } from '../../settings'
import { Link } from 'react-router-dom'
import { getParam, checkObjectKey } from '../../utils/utils'
import { useTranslation } from 'react-i18next'
import { useFetch, usePermissions } from '../../hooks'
import { useUserPrefs } from "../UserPreferences/ProvideUserPrefs"
import { dateTimeFormatter } from '../../utils/utils'
import moment from 'moment'
import Stack from '@mui/material/Stack'


function RecentlyChanged({ onSetDragActive, toggle, setToggle }) {

  const { t } = useTranslation('translation')
  const value_bu = localStorage.getItem('bu_bpx')
  const tokenBpxRequest = localStorage.getItem('token_bpx')
  // const tableHeight = JSON.parse(localStorage.getItem('tableHeight'))

  const request = useFetch()
  const { obj, childObj } = usePermissions()
  const checkActivityID = checkObjectKey(obj, childObj, "Activity_ID")

  const [rows, setRows] = useState([])
  const [queryOptions, setQueryOptions] = useState({})
  const [grid_loading, setGridLoading] = useState(false);
  const { prefsData } = useUserPrefs()

  const columns = [
    { 
      field: 'id', 
      headerName: 'id', 
      filterable: false, 
      hide: true 
    },
    { 
      field: 'ACTIVITY_ID', 
      headerName: t("Home.Recently.ACTIVITY_ID"), 
      hideable: false, 
      flex: 0.21,
      minWidth: 50,
      renderCell: (params) => {
        
        if (checkActivityID) {
          return (
            <div className="MuiDataGrid-cellContent">
              <Link 
                style={{ color: '#f69e80' }} 
                to={`/my-activities/accrual_object/${params.row.id}`}
                className="dark-mode-link-color"
              >
                {params.value}
              </Link>
            </div>
          )
        }

      } 
    },
    { 
      field: 'BUSINESS_PARTNER', 
      headerName: t("Home.Recently.BUSINESS_PARTNER"), 
      flex: 0.28,
      minWidth: 50,
      renderHeader: ({colDef})=>{
        if(colDef.width < 100){
          return (
            <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
            aria-label="Plan Account" 
            data-mui-internal-clone-element="true">
                {t("Home.Recently.BUSINESS_PARTNER_SHORT")}
            </div>
          )
        }
        return (
          <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
            aria-label="Plan Account" 
            data-mui-internal-clone-element="true">
              {t("Home.Recently.BUSINESS_PARTNER")}
          </div>
        )
      },
    },
    { 
      field: 'EXT_CLASS', 
      headerName: t("Home.Recently.EXT_CLASS"), 
      hideable: false, 
      flex: 0.26,
      minWidth: 50,
      renderHeader: ({colDef})=>{
        if(colDef.width < 100){
          return (
            <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
            aria-label="Investment description" 
            data-mui-internal-clone-element="true">
                {t("Home.Recently.EXT_CLASS_SHORT")}
            </div>
          )
        }
        return (
          <div className="MuiDataGrid-columnHeaderTitle wrap-header" 
            aria-label="Investment description" 
            data-mui-internal-clone-element="true">
              {t("Home.Recently.EXT_CLASS")}
          </div>
        )
      },
    },
    { 
      field: 'ACTION', 
      headerName: t("Home.Recently.ACTION"), 
      hideable: false, 
      flex: 0.10,
      minWidth: 50,
      renderCell: (params)=>{
        const statuses = ["Deleted", "Closed", "Created", "Changed"];
        const status = statuses.includes(params.value)?t(`Home.Recently.STATUS.${params.value}`):params.value
        return( 
          <div className="MuiDataGrid-cellContent">{status}</div>
        )
      }
    },
    { 
      field: 'AO_LAST_CHANGED_ON', 
      headerName: t("Home.Recently.AO_LAST_CHANGED_ON"), 
      flex: 0.14,
      minWidth: 50,
      renderCell: (params) => <div className="MuiDataGrid-cellContent">{dateTimeFormatter(params.row.AO_LAST_CHANGED_ON, prefsData.date_format, prefsData.time_format, prefsData.time_zone)}</div>
    },
  ]


  useEffect(() => {

    const queryParams = {
      limit: 100,
      business_unit: value_bu,
      ...queryOptions
    }
    const searchParam = getParam(queryParams).toString()

    request.fetchData(
      `${backend_url.recently_changed}?${searchParam}`,
      'GET',
      null,
      false,
      tokenBpxRequest,
      false
    )
      .then(data => setRows(
        data.map(d => ({
          id: d.AO_ID,
          BUSINESS_PARTNER: d.PLAN_ACCOUNT_BP_CAT + d.PLAN_ACCOUNT_BP_NR + ' - ' + d.NAME1,
          ...d
        }))
      ))

  }, [queryOptions])


  const onFilterChange = useCallback((filterModel) => {

    filterModel.items.map(cc => {

      if (cc.value) {
        setQueryOptions({ [cc.columnField]: cc.value })
      } else {
        setQueryOptions()
      }

    })

  }, [])


  return (
    <DataGridPro
      sx={{ 
        '& .MuiDataGrid-main': {
          height: (prefsData.tableHeight && prefsData.tableHeight.RecentlyChanged) && prefsData.tableHeight.RecentlyChanged + "px"
        },
        '& .MuiDataGrid-cell': {
          whiteSpace: 'nowrap',
          overflow: 'hidden'
        }
      }} 
      rows={rows}
      columns={columns}
      pageSize={50}
      hideFooter={true}
      loading={grid_loading}
      filterMode="server"
      density="compact"
      disableColumnFilter={true}
      onFilterModelChange={onFilterChange}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
          },
        },
      }}
      localeText={{
        noRowsLabel: t("Common.noRows")
      }}
      components={{
        Toolbar: CustomToolbarHome,
        NoRowsOverlay: () => {
          return (
            <Stack height="100%" alignItems="center" justifyContent="center">
              { 
                childObj.hasOwnProperty("Recently_Created_and_Changed") 
                  ? "No rows"
                  : "No authorization" 
              }
            </Stack>
          )
        }
      }}
      componentsProps={{
        toolbar: {
          title: t("Home.Highlights.recently_created_and_changed"),
          isResize: true,
          current: "RecentlyChanged",
          onSetDragActive: onSetDragActive,
          toggle: toggle, 
          setToggle: setToggle
        }
      }}
    />
  )

}

export default RecentlyChanged
