import React, { useEffect, useState, useRef } from 'react'
import { LinearProgress } from "@mui/material"

export default function StaticLoaderLinear({ display }) {

  const [progress, setProgress] = useState(0)
  const [buffer, setBuffer] = useState(10)

  const progressRef = useRef(() => {})

  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0)
        setBuffer(10)
      } else {
        const diff = Math.random() * 10
        const diff2 = Math.random() * 10
        setProgress(progress + diff)
        setBuffer(progress + diff + diff2)
      }
    }
  })

  React.useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current()
    }, 500)

    return () => {
      clearInterval(timer)
    };
  }, [])

  
  if (display) {
    return (
      <div style={{ position: "static" }}>
        <div style={{ position: "static", width: "100%" }}>
          <LinearProgress variant="buffer" value={progress} valueBuffer={buffer}  />
        </div>
      </div>
    )
  } else {
    return null
  }

}
