import { FormControl, InputLabel, 
    Select, MenuItem  } from "@mui/material"

export const SelectPref = ({label, id, value, onChange, options, widthProp}) => {
    return (
        <FormControl size="small" sx={{ width: widthProp}}>
            <InputLabel id={`${id}-label`}>{label}</InputLabel>
            <Select
                labelId={`${id}-label`}
                id={`${id}-select`}
                value={value}
                label={label}
                onChange={onChange}
            >
                {options && options.map((option, ind) => {
                    return <MenuItem key={`${option}-${ind}`} value={option}>{option}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
}
