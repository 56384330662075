import { useEffect, useState } from "react"
import { useFetch } from '../../hooks'
import { backend_url } from '../../settings'
import { validatePassword } from '../../utils/validation';

import { Typography, Grid, Button, Stack, Alert } from "@mui/material"
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen'
import LockIcon from '@mui/icons-material/Lock';
import SendIcon from '@mui/icons-material/Send';

import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { useTranslation } from 'react-i18next'

export const SecurityTab = () => {

    const [passDisabled, setPassDisabled] = useState(true)
    const [userRoles, setUserRoles] = useState()
    const [state, setState] = useState({'current_password': '', 'new_password': '', 'confirm_password': ''});
    const [error, setError] = useState({'current_password': '', 'new_password': '', 'confirm_password': ''});
    const [changeDone, setChangeDone] = useState(false)
    const [loading, setLoading] = useState(false)
    const [changeError, setChangeError] = useState({isError: false, errorText: ''})
    const [showPassword, setShowPassword] = useState({'current_password': false, 'new_password': false, 'confirm_password': false});

    const tokenBpxRequest = localStorage.getItem('token_bpx')
    const currentUser = JSON.parse(localStorage.getItem('current_user'))
    const request = useFetch()
    const oktaToken = JSON.parse(localStorage.getItem('okta-token-storage'))
    const ifOkta = (Object.keys(oktaToken).length === 0 || oktaToken !== null) ? false : true
    const { t } = useTranslation('translation')

    const fields  = [
        {
            name: 'current_password',
            label: t("preferences.current_password")
        },
        {
            name: 'new_password',
            label: t("preferences.new_password")
        },
        {
            name: 'confirm_password',
            label: t("preferences.confirm_password")
        },
    ]
    
    useEffect(() => {
        request.fetchData(
            `${backend_url.user_roles}/${currentUser.username}`, 
            'GET',
            null,
            false,
            tokenBpxRequest,
            false,
            false
        )
        .then(response => setUserRoles(response.map(res => res)))
        .catch(e => console.log(e))
    }, [])

    const handleClickShowPassword = (name) =>{
       setShowPassword((prev) => ({...prev, [name]: !prev[name]})); 
    } 
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleSubmitChangePassword = async (e) => {
        e.preventDefault()
        if(state.current_password===state.new_password){
            setError(prev=>({...prev, 'new_password': t("preferences.error_diffrent_password")}));
            return;
        }
        setLoading(true)
        let userData = {
            username: currentUser.username,
            password: state.current_password,
            grant_type: 'password',
        }
        await request.fetchData(
            backend_url.login, 
            'POST', 
            userData, 
            true,
            '',
            false
        )
        .then(response => {
            if (response) {        
                request.fetchData(
                    `${backend_url.password_change}/${currentUser.username}`, 
                    'PUT', 
                    JSON.stringify({password: state.new_password}), 
                    false,
                    tokenBpxRequest,
                    true,
                    true
                )
                .then(response => {
                    setLoading(false)
                    setChangeDone(true)
                    setChangeError({isError: false, errorText: ''})
                }).catch(error => {
                    setLoading(false)
                    setChangeDone(true)
                    setChangeError({isError: true, errorText: error})
                })
            }
            
            if (!response) {
              setLoading(false)
              setChangeDone(true)
              setChangeError({isError: true, errorText: t("preferences.try_again")})
            }
          })
        .catch(error => {
            setError(prev=>({...prev, 'current_password': t("preferences.incorrect_password") }))
            setLoading(false)
        })
    }

    const handleEnableButton = () => {
        setPassDisabled(!passDisabled)
    }

    const validatePass = (e) => {
        const {name, value} = e.target;
        if(name === 'current_password'){
            return value.length?
                setError(prev=>({...prev, [name]: ''})):
                setError(prev=>({...prev, [name]: t("preferences.required")}));
        }
        if(name==='confirm_password'&&value!==state.new_password){
            return  setError(prev=>({...prev, [name]: t("preferences.error_do_not_match")}));
        }
        if(name==='new_password'&&state.confirm_password.length){
            value!==state.confirm_password?
                setError(prev=>({...prev, 'confirm_password': t("preferences.error_do_not_match")})):
                setError(prev=>({...prev, 'confirm_password': ""}));
        }
        setError(prev=>({...prev, [name]: validatePassword(value)}))
    }

    const handleChange = (e) => {
        const {name, value} = e.target;
        validatePass(e)
        setState(prev=>({...prev, [name]: value}))
    }


    const validateDisabled = () => {
        return (!Object.values(state).every(it=>it.length))||Object.values(error).some(it=>it.length)//(currentPassError && true) || (newPassError && true) || (confirmPassError && true) || !currentPass || !newPass || !confirmPass
    }

    const createField = (data)=>{
        const {name, label} = data;
        return (
            <Grid item xs={12} key={name}> 
            <FormControl variant="outlined" size='small'>
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword[name] ? 'text' : 'password'}
                name={name}
                error={error[name] ? true : false}
                disabled={passDisabled}
                required
                size='small'
                sx={{width: '250px'}}
                autoComplete={'new_password'}
                value={state[name]}
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={()=>handleClickShowPassword(name)}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                        {showPassword[name] ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    </InputAdornment>
                }
                onChange={handleChange}
                onBlur={validatePass}
                label={label}
            />
             {!!error[name].length && (
                <FormHelperText error id="accountId-error">
                {error[name]}
                </FormHelperText>
            )}
        </FormControl>
        </Grid>
        )
    }

    
    return (
        <> 
            {ifOkta && <Alert sx={{marginBottom: "10px"}} severity="warning">{t("preferences.sso_users")}</Alert>}
            <Stack direction="row" spacing={2} paddingBottom='20px'>
                <Typography variant="subtitle1" sx={{fontWeight: 'bold'}}>{t("preferences.Change_Password")}</Typography> 
                {passDisabled ?
                    <Button disabled={ifOkta} size="small" variant="outlined" color="success" onClick={() => handleEnableButton()} startIcon={<LockOpenIcon />}>
                        {t("preferences.Enable")}
                    </Button> :
                    <Button size="small" variant="outlined" color="error" onClick={() => handleEnableButton()} startIcon={<LockIcon />}>
                        {t("preferences.Disable")}
                    </Button>
                }
            </Stack>
            {loading ? <CircularProgress sx={{marginLeft: '45%'}}/> :
            <form noValidate onSubmit={handleSubmitChangePassword} >
                {changeDone && 
                    (changeError.isError ? <Alert onClose={() => setChangeDone(false)} sx={{marginBottom: "10px"}} severity="error">{changeError.errorText.toString()}</Alert> :
                                <Alert onClose={() => setChangeDone(false)} sx={{marginBottom: "10px"}} severity="success">{t("preferences.success")}</Alert>)
                    }
                <Grid container rowSpacing={2}>  
                        {fields.map(it=>createField(it))}
                        <Grid item>
                            <Button disabled={validateDisabled()} type="submit" variant="contained" size="small" endIcon={<SendIcon />}>{t("preferences.Change")}</Button>
                        </Grid> 
                </Grid>
            </form> }
            <hr/>
            <Typography variant="subtitle1" sx={{paddingBottom: '10px', fontWeight: 'bold'}}>{t("preferences.user_roles")}</Typography>
            <Stack>
                {userRoles && userRoles.map(role => {
                    return <Typography variant="subtitle2" key={role}>{role}</Typography>
                })}
            </Stack>
        </>
    )
}
