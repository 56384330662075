import { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { numberFormatter, dateTimeFormatter} from "../../utils/utils"
import { useUserPrefs } from '../UserPreferences/ProvideUserPrefs';
import { useTranslation } from "react-i18next"

export const AdjustmentsLogsTable = ({adjustmentLogs}) => {

    const [rows, setRows] = useState([])
    const { prefsData } = useUserPrefs()
    const { t } = useTranslation("translation")

    useEffect(() => {
        if (adjustmentLogs) {
            var rows = adjustmentLogs.map(lg => ({
                id: lg.AC_AD_ID + '_' + lg.AO_ID + '_' + lg.AC_AD_VERSION,
                version: lg.AC_AD_VERSION,
                pp1Date: lg.PP1_DATE,
                pp1Amount: lg.PP1_AMOUNT,
                pp2Date: lg.PP2_DATE, 
                pp2Amount: lg.PP2_AMOUNT,
                status: lg.AC_AD_STATUS,
                userId: lg.USER_ID,
                dateTime: lg.TIMESTAMP,
                statusMessage: lg.STATUS_MESSAGE
            }))
            setRows(rows)
        }  
    }, [adjustmentLogs])


    const columns = [
      { id: 'version', label: t("Adjustments.MODAL.version"), minWidth: 80 },
      { id: 'pp1Date', 
        label:  t("Adjustments.MODAL.pp1Date"), 
        // minWidth: 160,
        align: 'left',
        format: (value) => value && dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
      },
      {
        id: 'pp1Amount',
        label:  t("Adjustments.MODAL.pp1Amount"),
        // minWidth: 155,
        align: 'right',
        format: (value) => value && numberFormatter(prefsData.decimal_notation).format(value),
      },
      {
        id: 'pp2Date',
        label:  t("Adjustments.MODAL.pp2Date"),
        // minWidth: 150,
        format: (value) => value && dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
      },
      {
        id: 'pp2Amount',
        label:  t("Adjustments.MODAL.pp2Amount"),
        // minWidth: 155,
        align: 'right',
        format: (value) => value && numberFormatter(prefsData.decimal_notation).format(value),
      },
      { id: 'status', label:  t("Adjustments.MODAL.status"), minWidth: 155 },
      { id: 'userId', label:  t("Adjustments.MODAL.userId"), minWidth: 100 },
      {
        id: 'dateTime',
        label:  t("Adjustments.MODAL.dateTime"),
        // minWidth: 165,
        format: (value) => value && dateTimeFormatter(value, prefsData.date_format, prefsData.time_format, prefsData.time_zone, true),
      },
      {
        id: 'statusMessage',
        label:  t("Adjustments.MODAL.statusMessage"),
        // minWidth: 170
      },
    ];


    return (
      <Paper sx={{ width: '100%' }}>
          <TableContainer >
          <Table sx={{ width: "auto" }} size="small">
              <TableHead sx={{backgroundColor: '#e0e0e0'}}>
              <TableRow>
                  {columns.map((column) => (
                      <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ top: 57, minWidth: column.minWidth }}
                      >
                          {column.label}
                      </TableCell>
                  ))}
              </TableRow>
              </TableHead>
              <TableBody>
              {rows.map((row) => {
                  return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                          const value = row[column.id];
                          return (
                          <TableCell key={column.id} align={column.align} size="small">
                              {column.format
                              ? column.format(value)
                              : value}
                          </TableCell>
                          );
                      })}
                      </TableRow>
                  );
              })}
              </TableBody>
          </Table>
          </TableContainer>
      </Paper>
    );
}
