import React from 'react'
import { useTranslation } from 'react-i18next'
import { backend_url } from '../../../../settings'
import { HookCRUDTable } from '../../../../hooks'
import { List } from '@mui/material';
import { ListItem } from '@mui/material';

function UserRoles () {

  const { t } = useTranslation()

  const filter = [
    {
      endpoint: backend_url.roles,
      endpointLS: [{field:'current_user', value: 'username'}],
      queryKeyToTyping: "ROLE",
      title: "Role",
      inputText: "Select Role",
      firstKeyOption: "ROLE",
      secondKeyOption: "",
      valueFilter: [],
      changingArray: [],
      formatField: "select",
      noMultiple: true,
      noObject: true
    }
  ]
  
  const obj = {
    title: t("Title.ListOfRoles"),
    url: backend_url.roles_permissions,
    action: true,
    weirdResponse: true
  }


  const columns = [
    // { 
    //   field: "ROLE_ID", 
    //   headerName: "Role", 
    //   flex: 1, 
    //   editable: true,
    //   renderCell: (params) => {
    //     return (
    //       <div key={params.value} style={{ alignSelf: "baseline"}}>
    //         {params.value}
    //       </div>
    //     )
    //   } 
    // },
    { 
      field: "DIMENSION_ID", 
      headerName: "Permission", 
      flex: 1, 
      editable: true,
      renderCell: (params) => {
        const content = params.value.map((item, id) => {
          return (
            <ListItem key={id} sx={{ display: 'list-item' }}>
              {item}
            </ListItem>
          )
        })
        return (
          <List sx={{ listStyleType: 'disc' , pl: 5}}>
            {content}
          </List>
        )
      } 
    },
  ]


  const objBreadcrumb = [
    { label: t("Breadcrumb.home"), link: "/" },
    { label: t("Breadcrumb.authorization"), link: "" },
    { label: t("Breadcrumb.list_of_roles"), link: "/authorization/list-of-roles" },
  ]


  return (
    <HookCRUDTable 
      initialColumn={columns}
      initialFilter={filter}
      initialBreadcrumb={objBreadcrumb}
      url={obj.url}
      title={obj.title}
      action={obj.action}
      weirdResponse={obj.weirdResponse}
    />
  )

  
 
}

export default UserRoles
