import { useState, useMemo } from "react"
import { SelectPref } from "./SelectPref";
import { Grid, RadioGroup, Radio, 
            FormControl, FormControlLabel, 
                FormLabel } from "@mui/material"
import { AutoCompleteSelect } from "./AutoCompleteSelect"
import countryList from 'react-select-country-list'
import { useTranslation } from 'react-i18next'
import { useUserPrefs } from "./ProvideUserPrefs";

export const PreferencesTab = () => {

    const { prefsData, saveUserPrefs } = useUserPrefs()
    const [defaultBUnit, setDefaultBUnit] = useState(prefsData.default_b_unit);
    const [country, setCountry] = useState(prefsData.country)
    const [timeZone, setTimeZone] = useState(prefsData.time_zone)
    const [lang, setLang] = useState(prefsData.lang)
    const [decimalNotation, setDecimalNotation] = useState(prefsData.decimal_notation)
    const [dateFormat, setDateFormat] = useState(prefsData.date_format)
    const [timeFormat, setTimeFormat] = useState(prefsData.time_format)
    const { i18n, t } = useTranslation()

    const countries = useMemo(() => countryList().getData(), [])
    const businessUnits = localStorage.getItem('b_units')?JSON.parse(localStorage.getItem('b_units')).map(bu => bu.BUSINESS_UNIT):[]
    const timeZones = Intl.supportedValuesOf('timeZone')
    const langOptions = ["en", "pl"]
    const notationOptions = ["1.234,56", "1 234,56", "1,234.56"]
    const dateFormatOptions = ['dd-MM-yyyy', 'dd-MM-yy', 'MM-dd-yyyy', 'MM-dd-yy', 'yyyy-MM-dd']

    const handleChange = (event) => {
        setDefaultBUnit(event.target.value);
        saveUserPrefs({
            ...prefsData,
            default_b_unit: event.target.value
        })
    };

    const handleLangChange = (event) => {
        setLang(event.target.value);
        i18n.changeLanguage(event.target.value)
        saveUserPrefs({
            ...prefsData,
            lang: event.target.value
        })
    };

    const handleDecimalNotationChange = (event) => {
        setDecimalNotation(event.target.value);
        saveUserPrefs({
            ...prefsData,
            decimal_notation: event.target.value
        })
    };

    const handleDateFormatChange = (event) => {
        setDateFormat(event.target.value);
        saveUserPrefs({
            ...prefsData,
            date_format: event.target.value
        })
    };

    const handleTimeFormatChange = (event) => {
        setTimeFormat(event.target.value);
        saveUserPrefs({
            ...prefsData,
            time_format: event.target.value
        })
    };

    const handleCountry = (value) => {
        setCountry(value);
        saveUserPrefs({
            ...prefsData,
            country: value
        })
    };

    const handleTimeZone = (value) => {
        setTimeZone(value);
        saveUserPrefs({
            ...prefsData,
            time_zone: value
        })
    };

    return (
        <>
            <Grid spacing={5} container rowSpacing={4} sx={{paddingTop: '20px'}}>
                <Grid item xs={6}>
                    <SelectPref label={t("preferences.default_b_u")} id="business_unit" 
                        onChange={handleChange} 
                        value={defaultBUnit} 
                        options={businessUnits}
                        widthProp="232px"/>
                </Grid>
                <Grid item xs={6}>
                    <SelectPref label={t("preferences.Date_Format")} id="date-format" 
                        onChange={handleDateFormatChange} 
                        value={dateFormat} 
                        options={dateFormatOptions}
                        widthProp="230px"/>
                </Grid>
                <Grid item xs={6}>
                    <AutoCompleteSelect 
                        value={country} 
                        label={t("preferences.Country")}
                        options={countries} 
                        handleChange={handleCountry}/>
                </Grid>
                <Grid item xs={6}>  
                    <SelectPref label={t("preferences.Decimal_notation")} id="decimal-notation" 
                        onChange={handleDecimalNotationChange} 
                        value={decimalNotation} 
                        options={notationOptions}
                        widthProp="230px"/>
                </Grid>
                <Grid item xs={6}>
                    <AutoCompleteSelect 
                        value={timeZone} 
                        label={t("preferences.Timezone")} 
                        options={timeZones}
                        handleChange={handleTimeZone}/>
                </Grid>
                <Grid item xs={6}>
                    <FormControl size="small">
                        <FormLabel id="time-format-label">{t("preferences.Time_format")}</FormLabel>
                        <RadioGroup
                            row
                            name="time-format-group"
                            value={timeFormat}
                            onChange={handleTimeFormatChange}
                        >
                            <FormControlLabel value="12H" control={<Radio />} label="12h" />
                            <FormControlLabel value="24H" control={<Radio />} label="24h" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{paddingTop: '1px !important'}}>
                    <SelectPref label={t("preferences.Language")}id="lang-label" 
                        onChange={handleLangChange} 
                        value={lang} 
                        options={langOptions}
                        widthProp="230px"/>
                </Grid>
            </Grid>
        </> 
    )
}
