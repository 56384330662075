import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { TextField } from '@mui/material'
import moment from 'moment'

export const formatFunc = (value) => {
  return moment(new Date(value)).format("YYYY-MM-DD")
}

function DatePicker (props) {

  const { id, field, value, api } = props

  const onChangeDate = (typing) => {

    if (typing === formatFunc(typing)) {
      return api.setEditCellValue({ id, field, value: typing })
    } else {
      return null
    }
  
  }

  
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} >
      <DesktopDatePicker
        inputFormat="yyyy-MM-dd"
        mask="____-__-__"
        onChange={(format, typing) => onChangeDate(typing)}
        value={value}
        onAccept={(value) => {
          const convert = formatFunc(value)
          onChangeDate(convert)
        }}
        renderInput={(params) => 
          <TextField
            {...params}
            size="small"
            inputProps={{
              ...params.inputProps,
              placeholder: "YYYY-MM-DD",
            }}
          /> 
        }
      />
    </LocalizationProvider>
  )

}

export default DatePicker
