const validatePassword = (value)=>{
  if (!value) {
      return "This field is required"
  }

  const errors = []

  if (!/[!@#$%^&*()_+{}\[\]:;<>,.?~`]/.test(value)) errors.push('special character')
  if(!/\d/.test(value)) errors.push('number')
  if(!/[A-Z]/.test(value)) errors.push('large letter')
  if(!/[a-z]/.test(value)) errors.push("small letter")  
  if(value.length<8) errors.push('at least 8 characters long')
  
  if(errors.length){
      return `Your password must contain ${errors.join(', ')}`
  }

  return '';
}

export { validatePassword }