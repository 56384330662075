import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export const AutoCompleteSelect = ({options, label, value, handleChange}) => {
    return (
        <Autocomplete
            id={`${label.toLowerCase()}-select-id`}
            sx={{ width: 230}}
            size="small"
            value={value}
            options={options}
            onChange={(event, newValue) => {
                if (newValue) {
                    handleChange(newValue.label ? newValue.label : newValue)
                }
            }}
            autoHighlight
            isOptionEqualToValue={(option, value) => (option.label ? option.label : option) === value}
            getOptionLabel={(option) => option.label ? option.label : option}
            renderOption={(props, option) => (
                <Box component="li" {...props}>
                    {option.label ? option.label : option}
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    sx={{paddingBottom: '0px'}}
                    {...params}
                    label={label}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                }}/>
        )}/>
    )
}
