import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useFetch } from '../../../hooks'

function SelectedComponent ({onHandleClick, url, configuration, value}) {
    const [selected, setSelected] = useState(configuration[value]);
    const [rows, setRows] = useState([])
    const request = useFetch();
    const tokenBpxRequest = localStorage.getItem('token_bpx');
    const handleChange = (event) => {
      setSelected(event.target.value);
      onHandleClick(value, {checked: true, data: event.target.value});
    };
    
    useEffect(()=>{
        request.fetchData(
            url,
            'GET',
            null,
            false,
            tokenBpxRequest,
            false,
            false
          ).then(data=>{
            setRows(data.map(it=>it[value]));
          })
    },[])
    
    useEffect(()=>{
      if(rows.find(it=>it===configuration[value])){
        setSelected(configuration[value])
      }
   
    },[configuration, rows])
    
    return( 
      <Box sx={{ minWidth: 200 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{value}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selected||""}
              defaultValue={''}
              label={value}
              onChange={handleChange}
            >
              <MenuItem disabled value={""}></MenuItem>
              {rows.map((it, id)=><MenuItem  key={id} value={it}>{it}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
      )

}

export default SelectedComponent
