
const OKTA_TESTING_DISABLEHTTPSCHECK = process.env.OKTA_TESTING_DISABLEHTTPSCHECK || false;
// BASENAME includes trailing slash
const REDIRECT_URI = `${window.location.origin}/ssologin`;;

let CLIENT_ID, ISSUER

if (window.location.origin.indexOf('test.')>=0) {
  CLIENT_ID = '0oavu2ug7rV029VF4357';
  ISSUER = 'https://mars-group.okta.com/oauth2/default';  
} else if (window.location.origin.indexOf('eu.mars.accrualengine.com')>=0) {
  CLIENT_ID = '0oawfgyofi4UGyD2y357';
  ISSUER = 'https://mars-group.okta.com/oauth2/default';  
} else {
  CLIENT_ID = '0oab5ytd2dwedrp6f5d7';
  ISSUER = 'https://dev-9647423.okta.com/oauth2/default';  
}

export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
    pkce: true,
    disableHttpsCheck: OKTA_TESTING_DISABLEHTTPSCHECK,
  },
};
