  import React, { useEffect, useState } from 'react'
  import Menu from '@mui/material/Menu'
  import MenuItem from '@mui/material/MenuItem'
  import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined'
  import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
  import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
  import { GridToolbarColumnsButton } from '@mui/x-data-grid-pro'
  import { useUserPrefs } from "../../pages/UserPreferences/ProvideUserPrefs"
  import { useNavigate } from "react-router-dom";
  import { useTranslation } from 'react-i18next'

function CustomToolbarHome (props) {

  let { title, isResize = true, current, onSetDragActive, toggle, setToggle, showColumns = false } = props
  

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const { prefsData, saveUserPrefs, silentUpdateUserPrefs } = useUserPrefs()
  const navigate = useNavigate();
    const { t } = useTranslation('translation')

  useEffect(() => {}, [anchorEl])

  const handleClick = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
    
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleGoToManager = () => {
    navigate('/support/alerts-todos-manager')
  }
   
  const handleResize = (e) => {
    let element = document.getElementById(current)
    let table = element.querySelector('.MuiDataGrid-main')
    let outline = element.querySelector('.bpx-home-inner')
    outline.style.border = "3px solid #2f5597"
  
    let isResizing = false
    let canResize = true
    const minHeight = 200
  
    let startY = 0
    let startHeight = 0
    let certianHeight
  
    const onMouseDown = (e) => {
      if (!canResize) return
      const rect = outline.getBoundingClientRect()
      const isNearBottomBorder = e.clientY >= rect.bottom - 5 && e.clientY <= rect.bottom + 5
      
      if (isNearBottomBorder) {
        isResizing = true
        startY = e.clientY
        startHeight = outline.clientHeight
        document.addEventListener('mousemove', onMouseMove)
        document.addEventListener('mouseup', onMouseUp)
      }

      
    };
  
    const onMouseMove = (e) => {
      if (!isResizing) return
  
      const deltaY = e.clientY - startY
      let newHeight = startHeight + deltaY
      
      if (newHeight < minHeight) {
        newHeight = minHeight
      }
  
      certianHeight = newHeight

      outline.style.height = `${newHeight}px`
      table.style.height = `${newHeight - 6}px`
    };
  
    const onMouseUp = () => {
      isResizing = false
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      
      outline.style.border = "none"
      outline.style.cursor = "initial"     

      if (certianHeight) {
        saveUserPrefs({
          ...prefsData,
          tableHeight: {
            ...prefsData.tableHeight,
            [current]: certianHeight 
          }
        })
        silentUpdateUserPrefs()
      }
    }
  
    outline.addEventListener('mousedown', onMouseDown)
    
    outline.addEventListener('mousemove', (e) => {
      const rect = outline.getBoundingClientRect()
      const isNearBottomBorder = e.clientY >= rect.bottom - 5 && e.clientY <= rect.bottom + 5
      outline.style.cursor = isNearBottomBorder ? 'ns-resize' : 'initial'
    })
  
    
    // outline.addEventListener('dblclick', () => {
    //   canResize = !canResize      
    // })
  
    setAnchorEl(null)
  }
  
  
  function handleDragandDrop () {
    onSetDragActive(current)
    
    setAnchorEl(null)
  }

  const move = (
    <MenuItem onClick={handleDragandDrop}>
      <div className='section-menu-toolbar'>
        <span className='icon-menu-toolbar'>
          <RoomOutlinedIcon />
        </span>
        <span className='text-menu-toolbar'>{t("Buttons.Move")}</span>
      </div>
    </MenuItem>
  )
  
  const resize = isResize && (
    <MenuItem onClick={handleResize}>
      <div className='section-menu-toolbar'>
        <span className='icon-menu-toolbar'>
          <AspectRatioOutlinedIcon />
        </span>
        <span className='text-menu-toolbar'>{t("Buttons.Resize")}</span>
      </div>
    </MenuItem>
  )

  const columns = (
    <MenuItem>
      <div className='section-menu-toolbar'>
      <span className='text-menu-toolbar'>
        <GridToolbarColumnsButton sx={{color: 'rgba(0, 0, 0, 0.87)', textTransform: 'capitalize !important', fontSize: '14px', padding: '0px'}}/>
      </span>
      </div>
    </MenuItem>
  )

  const manager = (
    <MenuItem onClick={handleGoToManager}>
      <div className='section-menu-toolbar'>
      <span className='icon-menu-toolbar'>
          <ArrowOutwardIcon />
      </span>
      <span className='text-menu-toolbar'>
          {t("Buttons.Manager")}
      </span>
      </div>
    </MenuItem>
  )

    
  return (
    <div className="bpx-home-toolbar">
      <div className='bpx-home-toolbar-inner'>
        <div className="bpx-home-toolbar-title">
          <p>{title}</p>
        </div>
        <div className="bpx-home-toolbar-dots">
          <p onClick={handleClick}>...</p>
        </div>
      </div>

      <Menu
        id="basic-menu"
        className='block-menu-toolbar'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        disableAutoFocusItem={true}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        { move }
        { resize }
        {showColumns && columns }
        {showColumns && manager }
      </Menu>
    </div>
  )

    
}


export default CustomToolbarHome
