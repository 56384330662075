import {
  Home, BPDetail, BPList, MaterialsDetail, MaterialsList, DownloadManager,
  CustomerHierarchy, ActualDataInternal, AOHeaderList, AOHeaderDetail,
  Configuration, MyBalance, ActivitySummary, AccrualPostingList, AccrualPostingDetail,
  PaymentList, PaymentLines, ERPStatuses, UserList, UserRoles, UserDetail, AccrualAdjustmentsList, 
  SupportChangeLogs,
  ErrorCatalog, Scheduler, ProvideResponse, NoAccessPage, MigrationList, TradeExpeditiureMonitor,
  Reconciliation, AccrualPostingERPStatuses
} from '../index'
import { usePermissions } from '../../hooks'
import { checkObjectKey } from '../../utils/utils'
import { AccessInfo } from '../index'
import { AuthAuditData } from '../AuthAuditData'
import { AlertsTodosManager } from '../AlertsTodosManager'
 
export default function useRouter () {

  const { obj, childObj } = usePermissions()

  const checkBP = checkObjectKey(obj, childObj, 'Business_Partners_Details')
  const checkProducts = checkObjectKey(obj, childObj, 'Product_detail')
  const checkActivityID = checkObjectKey(obj, childObj, "Activity_ID")
  const checkActivitySummary = checkObjectKey(obj, childObj, 'Activity_Summary')
  const checkPostingLines = checkObjectKey(obj, childObj, 'Posting_Lines')
  const checkPaymentsLines = checkObjectKey(obj, childObj, 'Payments_Lines')
  const checkERPStatus = checkObjectKey(obj, childObj, 'ERP_Statuses')
  const checkConfiguration = checkObjectKey(obj, childObj, 'Configuration')
  const checkUserManagement = checkObjectKey(obj, childObj, 'User_Management')
  const checkAdjustments = checkObjectKey(obj, childObj, 'Adjustments')
  const checkChangeLogs = checkObjectKey(obj, childObj, 'Change_logs')
  const checkTradeExpeditiureMonitor = checkObjectKey(obj, childObj, 'Trade_Expenditure_Monitor')
  const checkAuditList = checkObjectKey(obj, childObj, 'Audit_List')

  function conditionPage (check, Component) {
    if (check) {
      return Component
    } else {
      return <AccessInfo />
    }
  }

  
  const arrRouter = [
    {
      id: "1",
      path: "/",
      Component: () => <Home />,
    },
    {
      id: "2",
      path: "/master-data/business_partners",
      Component: () => <BPList />,
    },
    {
      id: "3",
      path: "/master-data/business_partners/:role/:id/detail",
      Component: () => conditionPage(checkBP, <BPDetail />) 
    },
    {
      id: "4",
      path: "/master-data/products",
      Component: () => <MaterialsList />,
    },
    {
      id: "5",
      path: "/master-data/products/:id/detail",
      Component: () => conditionPage(checkProducts, <MaterialsDetail />),
    },
    {
      id: "6",
      path: "/master-data/customer_hierarchy",
      Component: () => <CustomerHierarchy />,
    },
    {
      id: "7",
      path: "/volumes/actual-data/internal",
      Component: () => <ActualDataInternal />,
    },
    {
      id: "8",
      path: "/my-activities/accrual_object",
      Component: () => <AOHeaderList />,
    },
    {
      id: "9",
      path: "/my-activities/accrual_object/:id",
      Component: () => conditionPage(checkActivityID, <AOHeaderDetail />),
    },
    {
      id: "10",
      path: "/configuration/*",
      Component: () => conditionPage(checkConfiguration, <Configuration />),
    }, 
    {
      id: "11",
      path: "/accrual-balance/my-balance",
      Component: () => <MyBalance />,
    },
    {
      id: "12",
      path: "/my-activities/activity_summary", 
      Component: () => conditionPage(checkActivitySummary, <ActivitySummary />),
    },
    {
      id: "13",
      path: "/accrual-balance/accrual_posting",
      Component: () => <AccrualPostingList />,
    },
    {
      id: "14",
      path: "/accrual-balance/accrual_posting/:id",
      Component: () => conditionPage(checkPostingLines, <AccrualPostingDetail />),
    },
    {
      id: "15",
      path: "/payments/my-payments",
      Component: () => <PaymentList />,
    },
    {
      id: "16",
      path: "/payments/my-payments/:id/:path/",
      Component: () => conditionPage(checkPaymentsLines, <PaymentLines />),
    },
    {
      id: "17",
      path: "/payments/my-payments/:id/:path/status",
      Component: () => conditionPage(checkERPStatus, <ERPStatuses />),
    },
    {
      id: "18",
      path: "/authorization/manage-user",
      Component: () => conditionPage(checkUserManagement, <UserList />),
    },
    {
      id: "19",
      path: "/authorization/manage-user/:id",
      Component: () => conditionPage(checkUserManagement, <UserDetail />),
    },
    {
      id: "20",
      path: "/authorization/list-of-roles",
      Component: () => conditionPage(checkUserManagement, <UserRoles />),
    },
    {
      id: "21",
      path: "/download",
      Component: () => <DownloadManager />,
    },
    {
      id: "22",
      path: "/accrual-balance/accrual-adjustments",
      Component: () => conditionPage(checkAdjustments, <AccrualAdjustmentsList />),
    },
    {
      id: "23",
      path: "/configuration/error-catalog",
      Component: () => <ErrorCatalog />,
    },
    ,
    {
      id: "24",
      path: "/support/changelogs",
      Component: () => conditionPage(checkChangeLogs, <SupportChangeLogs />),
    },
    {
      id: "25",
      path: "/support/scheduler",
      Component: () => <Scheduler />,
    },
    {
      id: "26",
      path: "/support/migration-workbench",
      Component: () => <MigrationList />
    },
    {
      id: "27",
      path: "/provide_response/:hash",
      Component: () => <ProvideResponse />
    },
    {
      id: "28",
      path: "/my-activities/activity_summary/overpayments",
      Component: () => <ActivitySummary />,
    },
    {
      id: "29",
      path: "/my-activities/activity_summary/without_payments",
      Component: () => <ActivitySummary />,
    },
    {
      id: "30",
      path: "/no-access",
      Component: () => <NoAccessPage />
    },
    {
      id: "31",
      path: "/my-activities/trade_expenditure_monitor",
      Component: () => conditionPage(checkTradeExpeditiureMonitor, <TradeExpeditiureMonitor />)
    },
    {
      id: "32",
      path: "/auth-audit-list",
      Component: () => conditionPage(checkAuditList, <AuthAuditData />)
    },
    {
      id: "33",
      path: "/accrual-balance/reconciliation",
      Component: () => <Reconciliation />
    },
    {
      id: "34",
      path: "/support/alerts-todos-manager",
      Component: () => <AlertsTodosManager />
    },
    {
      id: "35",
      path: "/support/alerts-todos-manager",
      Component: () => <AlertsTodosManager />
    },
    {
      id: "36",
      path: "/accrual-balance/accrual_posting/erp_statuses/:id",
      Component: () => conditionPage(checkPostingLines, <AccrualPostingERPStatuses />),
    },
  ]

  return [ arrRouter ]
  
}
